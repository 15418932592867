import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import { ClrDatagridStateInterface, ClrDatagridSortOrder } from '@clr/angular';
import { Observable } from 'rxjs';
import { UsersService } from 'src/app/services/users.service';

import { FormGroup, FormControl, Validators } from '@angular/forms';

interface AspDatagridConfig {
  title: string;
  isLoading: boolean;
  preserveSelection: boolean;
  service: any;
  customRefreshFunction?: (event: any) => void;
  placeholderMessage: string;
  //defaultSort: "ASC" | "DESC";
  columns: AspDatagridColumn[];
  rows: AspDatagridRow[];
  data: AspDatagridData;
  selected: any[];
  pagination: {
    queryName?: string;
    isActive: boolean;
    pageSize: number;
    options: number[];
  };
  rowExtendConfig: {
    isActive: boolean;
    replaceRow: boolean;
  };
  generalActions: AspDatagridGeneralAction[];
  rowActions: AspDatagridRowAction[];
  //defaultActions: AspDatagridAction[],
  //customActions: any[]
}

interface AspDatagridGeneralAction {
  displayName: string;
  isAvailable: boolean;
  minRows: number | 'any'; // all
  maxRows: number | 'any';
  icon?: string;
  subActions?: AspDatagridGeneralSubAction[];
  action: (selectedRows: any[]) => void;
}

interface AspDatagridGeneralSubAction {
  displayName: string;
  isAvailable: boolean;
  minRows: number | 'any';
  maxRows: number | 'any';
  action: (selectedRows: any[]) => void;
}

interface AspDatagridRowAction {
  displayName: string;
  isAvailable: boolean;
  action: (row: any) => void;
}

interface AspDatagridColumn {
  name: string;
  displayName: string;
  allowFilter: boolean;
  dataType: string; // default string
  initialyHidden: boolean; //default false
  comparator?: any;
  filter?: any;
}

interface AspDatagridRow {
  attrb: string;
  cellType?: string;
  cellTemplate?: TemplateRef<any>;
  selectOptions?: any[]; // arrya de json id, value
  ngModelDefaultValue?: any;
  ngModelValue?: any; 
  updateService? : any;
}

interface AspDatagridData {
  dataObservable: Observable<any> | null;
  completeData?: any[];
  data: any[];
  filteredData?: any[];
  hasDetail: boolean;
  parser: (data: any[]) => any[];
  filterFunction?: (data: any) => any;
  isUpdateDetailWithSelect: string;
  dataDetailUpdateWithSelect: any[];
  functionUpdateDetail(): any;
  modelDataDetail: any;
}

@Component({
  selector: 'app-asp-datagrid',
  templateUrl: './asp-datagrid.component.html',
  styleUrls: ['./asp-datagrid.component.scss'],
})
export class AspDatagridComponent implements OnInit {
  formDetail = new FormGroup({
    dataDetail: new FormControl('', [Validators.required]),
    id: new FormControl(''),
  });
  @Output()
  propagar = new EventEmitter<string>();
  @Input('realConfig') realConfig: AspDatagridConfig = {
    title: 'Loading...',
    isLoading: false,
    preserveSelection: true,
    //customRefreshFunction: this.refreshFunction,
    service: null,
    placeholderMessage: 'No hay documentos de prueba',
    //defaultSort: "DESC",
    columns: [] as AspDatagridColumn[],
    rows: [] as AspDatagridRow[],
    data: {
      dataObservable: null,
      data: [],
      //filteredData: [],
      hasDetail: true,
      parser: (data: any[]) => {
        return data.map((item: any) => {
          return { ...item, locked: false };
        });
      },
      //filterFunction: ( data: any ) => { },
      isUpdateDetailWithSelect: '',
      dataDetailUpdateWithSelect: [],
      modelDataDetail: new FormControl(''),
      functionUpdateDetail: () => {},
    },
    rowExtendConfig: {
      isActive: true,
      replaceRow: true,
    },
    selected: [] as any[],
    pagination: {
      isActive: true,
      pageSize: 10,
      options: [10, 20, 50, 100],
    },
    generalActions: [],
    rowActions: [] as AspDatagridRowAction[],
  };

  defaultPlaceholderMessage: string =
    'No hemos encontrado datos con respecto a esta consulta';

  data: any[] = [];
  refresh(state: ClrDatagridStateInterface) {
    //this.loading = true;
    if (
      state.page &&
      state.page.current &&
      state.page.size &&
      this.lastTotal != state.page.current * state.page.size
    ) {
      //this.currentPage = state.page.current
      if (state.page.current * state.page.size > this.lastTotal) {
        console.log(this.lastTotal, state.page.current, state.page.size);
        this.lastTotal = state.page.current * state.page.size;
        this.realConfig.service.getNextPage(
          state.page.current * state.page.size,
          this.realConfig.pagination.queryName? this.realConfig.pagination.queryName: "default"
        );
      }
      //this.userService.getNextPage(state.page.current * state.page.size)
    }
    console.log('Estado: ', state);
    //this.loading = false;
  }

  lastTotal = -1;

  constructor() {}

  ngOnInit(): void {
    //this.descSort = ClrDatagridSortOrder.DESC
    if (this.realConfig.data.dataObservable) {
      this.realConfig.data.dataObservable.subscribe((data) => {
        console.log('DATA: ', data);
        this.realConfig.isLoading = false;
        this.realConfig.data.filteredData = data
        if(this.realConfig.data.filterFunction){
          this.data = this.realConfig.data.parser(data).filter(this.realConfig.data.filterFunction);
        }else{
          this.data = this.realConfig.data.parser(data)
        }
      });
    } else {
      this.realConfig.isLoading = false;
      console.log("1): ", this.realConfig.data.data)
      this.realConfig.data.filteredData = this.realConfig.data.data
      if(this.realConfig.data.filterFunction){
        this.data = this.realConfig.data.parser(this.realConfig.data.data).filter(this.realConfig.data.filterFunction);
      }else{
        this.data = this.realConfig.data.parser(this.realConfig.data.data);
      }
    }
  }

  onSelectCellChange(value: any, rowConfig: any, item: any ){
    let payload = { } as any
    payload[rowConfig.attrb] = value
    rowConfig.updateService.update(item.id, payload)
    //rowConfig.onChangeFunction(item.id, payload )
  }
  /**
   * Función para emitir data al componente padre
   * @param {any} formDetail
   * @param {any} id
   */
  propagare(formDetail: any, id: any) {
    formDetail.id = id;
    this.propagar.emit(formDetail);
  }
}

// EXAMPLE
// config = {
//   title: "Test Documents",
//   isLoading: true,
//   preserveSelection: true,
//   //customRefreshFunction: this.refreshFunction,
//   service: this.usersService,
//   placeholderMessage: "No hay documentos de prueba",
//   //defaultSort: "DESC",
//   columns: [
//     { allowFilter: true, displayName: "User ID", name: "userId", initialyHidden: false, dataType: "number"},
//     { allowFilter: false, displayName: "Name", name: "name",initialyHidden: false, dataType: "string" },
//     { allowFilter: false, displayName: "Creation Date", name: "date", initialyHidden: true, dataType: "string"},
//     { allowFilter: true, displayName: "Color", name: "fav", initialyHidden: false, dataType: "string"},
//   ],
//   rows: [
//     { attrb: "userId", cellType: "normal"},
//     { attrb: "name", cellType: "normal"},
//     { attrb: "date", cellType: "normal"},
//     { attrb: "fav", cellType: "custom"},
//   ],
//   data: {
//     dataObservable: this.usersService.getUsersAlt(),
//     data: [],
//     hasDetail: true,
//     parser: ( data:any[] ) => { return data.map( (item: any) => {return {...item, locked: false}}) }
//   },
//   rowExtendConfig: {
//     isActive: true,
//     replaceRow: true
//   },
//   selected: [] as any[],
//   pagination: {
//     isActive: true,
//     pageSize: 10,
//   },
//   generalActions: [
//     {minRows: 1, maxRows: "any", displayName: "Export", subActions: [
//       {minRows: 1, maxRows: "any", displayName: "Export SOME", isAvailable: true, action: (rows: any[]) => { console.log(rows)}},
//       {minRows: 1, maxRows: "any", displayName: "Export ALL", isAvailable: true, action: (rows: any[]) => { console.log(rows)}},
//     ], isAvailable: true, action: (rows: any[]) => { console.log("Action")}},
//   ],
//   rowActions: [
//     { displayName: "Create", isAvailable: true, action: (row: any) => { console.log(row)}},
//     { displayName: "Read", isAvailable: true, action: (row: any) => { console.log(row)}},
//     { displayName: "Update", isAvailable: true, action: (row: any) => { console.log(row)}},
//     { displayName: "Delete", isAvailable: true, action: (row: any) => { console.log(row)}},
//   ]
// }
