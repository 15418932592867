import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { EmployeesService } from '../services/employees.service';
import { filter, switchMap } from 'rxjs/operators';
import { EmployeeRank, ShiftType } from '../models/employee';
import { RANK_GUARD_ENABLED } from '../constants/nav.constant';

@Injectable({
  providedIn: 'root'
})
export class RankGuard implements CanActivate {

  constructor (
    private empService: EmployeesService,
    private router: Router,
  ) {}

  private veredict(condition: boolean): Observable<boolean> {
    if (!condition) {
      this.router.navigate(['/']);
      return of(false);
    }

    return of(true);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree 
  {
    const routeRanks = route.data.ranks as EmployeeRank[];
    return this.empService.currentEmployee$
      .pipe(
        filter((emp) => !!emp),
        switchMap((emp) => {

          if (!RANK_GUARD_ENABLED) return this.veredict(true);

          if (!emp) return this.veredict(false);

          if (emp.rank === EmployeeRank.superadmin) return this.veredict(true);

          const employeeRankPass = routeRanks.includes(emp.rank);

          if (!employeeRankPass) return this.veredict(false);

          const freelancerIsDefined = 
            (route.data.freelance as boolean) !== null &&
            (route.data.freelance as boolean) !== undefined;

          if (freelancerIsDefined) {
              const empIsFreelancer = (emp.shiftType === ShiftType.freelance);
              const routeIsForFreelancers = route.data.freelance as boolean;
              
              if (routeIsForFreelancers) return this.veredict(empIsFreelancer);
    
              return this.veredict(!empIsFreelancer);

          }
            
          return this.veredict(true);

        })
      );

  }
  
}
