//Angular imports
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';

import { Router } from '@angular/router';
import { result } from 'lodash';
//RXJS imports
import { BehaviorSubject, Observable, of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
//Local imports
import { FirebaseCallResults } from '../../models/shared';
import { User } from '../../models/user';
import { DatabaseService } from '../database.service';
import { RolService } from '../rol.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  /**Observable for user */
  private _authUser$!: Observable<User | null>;
  private currentUserData: User | null = null;
  private _customClaims$!: Observable<any | null>;
  private uid: string = '';
  _currentUser$: BehaviorSubject<User | null> =
    new BehaviorSubject<User | null>(null);

  /**Authentication state */
  private _authState: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  private _role: BehaviorSubject<string> = new BehaviorSubject<string>('');

  /**
   * Get current authenticated user
   */
  get currentUser$(): Observable<User | null> {
    return this._authUser$;
  }

  /**
   * Get current authenticated user
   */
  get authUser$(): Observable<User | null> {
    return this._authUser$;
  }

  /**
   * Get current authenticated user
   */
  get customClaims$(): Observable<User | null> {
    return this._customClaims$;
  }

  /**
   * Get the current authentication state
   */
  get authState$(): Observable<boolean> {
    return this._authState.asObservable();
  }

  get authRole$(): Observable<string | null> {
    return this._role;
  }

  constructor(
    private afAuth: AngularFireAuth,
    private fns: AngularFireFunctions,
    private afFirestore: AngularFirestore,
    private firestore: AngularFirestore,
    private router: Router,
    private rolService: RolService
  ) {
    //Initialization
    this.initAuth();
  }
  /**
   * Get user id
   */
  public getUID() {
    return this.uid;
  }

  /**
   * Get user id
   */
  public getCurrentUser() {
    return this.currentUserData;
  }

  /**
   * Initialize the authentication
   */
  private initAuth() {
    //Listen to the auth user changes
    this._customClaims$ = this.afAuth.idTokenResult;
    this._authUser$ = this.afAuth.authState.pipe(
      switchMap((user) => {
        // console.log('initAuth User',user);
        if (user) {
          //User exists
          this._authState.next(true); //State of auth becomes true
          this.uid = user.uid;
          this.firestore
            .collection('environments/asp-it-demo/users')
            .doc(this.uid)
            .get()
            .subscribe((userDoc: any) => {
              let userData = userDoc.data();
              // console.log("Init")
              if (userData) {
                this._currentUser$.next(userData);
                this.currentUserData = userData;
                this._role.next(userData.roleId);
                // console.log('CurrentUserData',this.currentUserData)
              }
            });
          return of({
            id: user.uid,
            email: user.email,
          } as User); //Generate user data
        } else {
          //User doesn't exist
          this._authState.next(false); //State of auth becomes false
          return of(null); //Return no user
        }
      })
    );
  }

  /**
   * Sign in an user with email and password
   * @param {string} email Input email
   * @param {string} password Input password
   * @returns
   */
  async emailSignin(
    email: string,
    password: string
  ): Promise<FirebaseCallResults> {
    try {
      /**Login credentials */
      const credentials = await this.afAuth.signInWithEmailAndPassword(
        email,
        password
      );

      if (credentials.user) {
        //Validate user exists
        this.updateUserData(credentials.user);

        return {
          status: 'SUCCESS',
        };
      } else {
        throw Error('User not found');
      }
    } catch (err) {
      console.log(err);
      return {
        error: err as Error,
        status: 'FAILURE',
      };
    }
  }

  /**
   * Update data of logged in user with firebase credentials
   */
  private async updateUserData(user: firebase.default.User) {
    /**User document ref */
    const userRef = this.afFirestore
      .collection('environments/asp-it-demo/users')
      .doc(user.uid);

    /**Date for update */
    const dataForUpdate: Partial<User> = {
      email: user.email,
    };

    await userRef.set(dataForUpdate, {
      merge: true,
    });
  }

  /**
   * Signs out the current user, clears auth data and navigates to login
   */
  async singOut() {
    //Do signout routine
    await this.afAuth.signOut();

    //Clear the auth state
    this.clearAuthState();

    //Navigate to login
    this.router.navigate(['/login']);
  }

  /**Clears the auth data to false */
  private clearAuthState() {
    this._authState.next(false);
  }

  async register(registerData: any): Promise<any> {
    try {
      const callable = this.fns.httpsCallable('createEmployeeAuthUser');
      const result = await callable(registerData).pipe(take(1)).toPromise();

      if (result.status === 500) {
        console.log('has to be error');
        throw result;
      }

      return Promise.resolve(result);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async sendEmailEmployeeCreateAccount(data: {
    name: string;
    email: string;
    token: string;
  }): Promise<any> {
    try {
      const callable = this.fns.httpsCallable('sendEmailEmployeeCreateAccount');
      const result = await callable(data).pipe(take(1)).toPromise();

      if (result.status === 500) {
        throw result;
      }

      return Promise.resolve(result);
    } catch (error) {
      return result;
    }
  }
}
