import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Rol } from '../models/rol';

@Injectable({
  providedIn: 'root',
})
export class RolService {
  query: Subscription;
  totalItems: number = 20;
  Roles$: Observable<any[]>;
  pageSize: number = 10;
  rolesAlt: BehaviorSubject<Rol[]> = new BehaviorSubject<Rol[]>([]);

  constructor(
    private firestore: AngularFirestore,
    private fns: AngularFireFunctions
  ) {
    this.query = this.firestore
      .collection('environments/asp-it-demo/roles', (ref) => ref.limit(15))
      .valueChanges({ idField: 'id' })
      .subscribe((data: any) => {
        this.totalItems = 0;
        const roles = [];
        for (let doc of data) {
          let rol = {
            ...doc,
            createdAt: doc.createdAt ? doc.createdAt.toDate() : 'N/E',
            updatedAt: doc.updatedAt ? doc.updatedAt.toDate() : 'N/E',
            deletedAt: doc.deletedAt ? doc.deletedAt.toDate() : 'N/E',
          };
          roles.push(rol);
        }
        this.totalItems += roles.length;
        this.rolesAlt.next(roles);
      });
    this.Roles$ = this.firestore
      .collection('environments/asp-it-demo/roles')
      .valueChanges({ idField: 'id' });
  }

  /**
   * Listar roles en el sistema
   * @param {number} newTotal
   */
  listenRoles(newTotal: number) {
    this.query.unsubscribe();
    console.log('Limit ', newTotal);
    this.query = this.firestore
      .collection('environments/asp-it-demo/roles', (ref) =>
        ref.limit(newTotal)
      )
      .valueChanges({ idField: 'id' })
      .subscribe((data: any) => {
        const roles = [];
        for (let doc of data) {
          let rol = {
            ...doc,
            createdAt: doc.createdAt ? doc.createdAt.toDate() : 'N/E',
            updatedAt: doc.updatedAt ? doc.updatedAt.toDate() : 'N/E',
            deletedAt: doc.deletedAt ? doc.deletedAt.toDate() : 'N/E',
          };
          roles.push(rol);
        }
        // console.log("Roles", roles)
        this.totalItems = roles.length;
        this.rolesAlt.next(roles);
      });
  }

  /**
   * obtener siguiente pagina de roles del sistema
   * @param {number} total
   */
  getNextPage(total: number) {
    console.log('total: ', total);
    console.log('newTotal: ', this.totalItems);
    if (total >= this.totalItems && total + 10 > 0) {
      console.log('Query', total + 10);
      this.listenRoles(total + 10);
    }
  }

  /**
   * Cambiar numero de pagina de roles del sistema
   * @param {number} newPageSize
   * @returns {number}
   */
  changePageSize(newPageSize: number) {
    return (this.pageSize = newPageSize);
  }

  /**
   * Obtener rol properties
   * @returns {string []} array de string
   */
  getRolProperties(): string[] {
    return [
      'id',
      'name',
      'description',
      'deleted',
      'createdAt',
      'updatedAt',
      'deletedAt',
    ];
  }

  /**
   * Obtener todos los roles del sistema
   * @returns {Observable []} array de observables de tipo rol
   */
  getRoles(): Observable<Rol[]> {
    return this.Roles$;
  }

  getRolesAlt(): Observable<Rol[]> {
    return this.rolesAlt;
  }

  /**
   * Obtener rol por id
   * @param {number} id
   * @returns {rol} objeto de tipo rol
   */
  getRol(id: string): any {
    return this.firestore
      .collection('environments/asp-it-demo/roles')
      .doc(id)
      .valueChanges();
  }

  async getRolM(id: string): Promise<any> {
    return this.firestore
      .collection('environments/asp-it-demo/roles')
      .doc(id)
      .snapshotChanges();
  }

  getRolesM() {
    // return this.firestore.collection('environments/asp-it-demo/roles').snapshotChanges().subscribe(r=>r.map(w=>w.payload.doc.id))
    return this.firestore
      .collection('environments/asp-it-demo/roles')
      .snapshotChanges();
  }

  /**
   * Actualizar data de un rol por id
   * @param {number} id
   * @param {any} data objeto de tipo rol
   */
  setRol(id: string, data: any) {
    this.firestore
      .collection('environments/asp-it-demo/roles')
      .doc(id)
      .set(data);
  }

  /**
   * Agregar un rol al sistema
   * @param {any} payload
   * @returns {Promise} Objeto de tipo promesa
   */
  async addRol(payload: any): Promise<any> {
    return this.firestore.collection('environments/asp-it-demo/roles').add({
      ...payload,
      createdAt: new Date(),
      updatedAt: new Date(),
      deleted: false,
    }); //.valueChanges()
  }

  /**
   *
   * @param payloads
   * @returns
   */
  async addManyRoles(payloads: any): Promise<any> {
    const batch = this.firestore.firestore.batch();
    for (const payload of payloads) {
      batch.set(
        this.firestore.collection('environments/asp-it-demo/roles').doc().ref,
        {
          ...payload,
          createdAt: new Date(),
          updatedAt: new Date(),
          deleted: false,
        }
      );
    }
    return batch.commit();
  }

  createRol(rol: any) {
    this.firestore.collection('environments/asp-it-demo/roles').add(rol);
  }

  getRolesName(rolName: any) {
    // Create a reference to the roles collection
    var rolesRef = this.firestore
      .collection('environments/asp-it-demo/roles', (ref) =>
        ref.where('name', '==', rolName)
      )
      .valueChanges();
    return rolesRef;
  }

  deleteRol(RolId: string, data: Rol) {
    this.firestore
      .collection('environments/asp-it-demo/roles')
      .doc(RolId)
      .update({ ...data, deleted: true });
  }

  updateRol(RolId: string, data: Rol) {
    this.firestore
      .collection('environments/asp-it-demo/roles')
      .doc(RolId)
      .update({ ...data });
  }
}
