import { Directive, HostListener, Self, OnDestroy } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MoneySafeOperationsService } from '../services/money-safe-operations.service';
import { Currency } from '../models/currency';
import { currencyToNumber } from '../helpers/inventory/currencyToNumber';

@Directive({
  selector: '[currencyFormatter]',
})
export class CurrencyFormatterDirective {
  private formatter: Intl.NumberFormat;
  private destroy$ = new Subject();
  private currentMoney: Currency;

  constructor(
    @Self() private ngControl: NgControl,
    private moneySafeOperationsService: MoneySafeOperationsService
  ) {
    this.moneySafeOperationsService.currentCurrency$.subscribe(
      (newCurrency) => {
        this.formatter = new Intl.NumberFormat(newCurrency.codeIntl);
        if (this.currentMoney) {
          const value = this.changeCurrency(
            this.ngControl.value,
            this.currentMoney,
            newCurrency
          );
          this.setValue(this.formatter.format(Number(value)));
        }

        this.currentMoney = newCurrency;
      }
    );
  }

  ngAfterViewInit() {
    this.setValue(this.formatPrice(this.ngControl.value));
    this.ngControl?.control?.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(this.updateValue.bind(this));
  }

  updateValue(value: any) {
    let inputVal = value || '';

    if (this.currentMoney.thousandSeparator === ',') {
      inputVal = inputVal.replace(/[^0-9.-]+/g, '');
    } else if (this.currentMoney.thousandSeparator === '.') {
      inputVal = inputVal.replace(/[^0-9,-]+/g, '');
    }

    this.setValue(!!inputVal ? this.validateDecimalValue(inputVal) : '');
  }

  @HostListener('focus') onFocus() {
    this.setValue(this.unformatValue(this.ngControl.value));
  }

  @HostListener('blur') onBlur() {
    let value = this.ngControl.value || '';
    !!value && this.setValue(this.formatPrice(value));
  }

  changeCurrency(value: string, oldCurrency: Currency, newCurrency: Currency) {
    if (
      oldCurrency.decimalSeparator === ',' &&
      newCurrency.decimalSeparator === '.'
    ) {
      return value.replace('.', '').replace(',', '.');
    } else if (
      oldCurrency.decimalSeparator === '.' &&
      newCurrency.decimalSeparator === ','
    ) {
      return value.replace(',', '');
    } else if (oldCurrency.decimalSeparator === newCurrency.decimalSeparator) {
      return value;
    } else {
      return value.replace(/[^0-9]+/g, '');
    }
  }

  formatPrice(v: any) {
    console.log(v, 'VALOR');
    if (typeof v === 'string') {
      v = currencyToNumber(this.currentMoney.name, v);
      console.log(v);
    }
    return this.formatter.format(v);
  }

  unformatValue(v: string) {
    if (this.currentMoney.thousandSeparator === ',') {
      return v.replace(/[^0-9.-]+/g, '');
    } else if (this.currentMoney.thousandSeparator === '.') {
      return v.replace(/[^0-9,-]+/g, '');
    }
    return v;
  }

  validateDecimalValue(v: any) {
    if (this.currentMoney.decimalSeparator === ',') {
      v = v.replace(',', '.');
    }
    // Check to see if the value is a valid number or not
    if (Number.isNaN(Number(v))) {
      // strip out last char as this would have made the value invalid
      let strippedValue = v.slice(0, v.length - 1);

      // if value is still invalid, then this would be copy/paste scenario
      // and in such case we simply set the value to empty
      strippedValue = Number.isNaN(Number(strippedValue)) ? '' : strippedValue;

      if (this.currentMoney.decimalSeparator === ',') {
        strippedValue = strippedValue.replace('.', ',');
      }

      return strippedValue;
    }

    if (this.currentMoney.decimalSeparator === ',') {
      v = v.replace('.', ',');
    }
    return v;
  }

  setValue(v: any) {
    this.ngControl?.control?.setValue(v, { emitEvent: false });
  }

  ngOnDestroy() {
    this.setValue(this.unformatValue(this.ngControl.value));
    this.destroy$.next();
    this.destroy$.complete();
  }
}
