export enum currencyTypes {
  'DOLLAR' = 'Dollar',
  'EURO' = 'Euro',
  'POUND' = 'Pound',
  'YEN' = 'Yen',
}

export interface Currency {
  name: currencyTypes;
  symbol: string;
  thousandSeparator: string;
  decimalSeparator: string;
  codeIntl: string;
  currency: string;
}
