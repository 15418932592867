import { Component, OnInit, ChangeDetectorRef} from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/authentication/auth.service'
import { UsersService } from '../../services/users.service'
import { User } from '../../models/user'
import { RolService } from '../../services/rol.service'
import { Path } from '../../models/path'
import { map, mergeMap, take } from 'rxjs/operators';



@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit {
  routes!:Path[] ;
  role!: string;
  collapsed:boolean=true

  // paths: [] ;
  constructor(private router: Router, 
              private authService : AuthService, 
              private usersService: UsersService,
              private rolService: RolService,
              private cdr:ChangeDetectorRef
              ) {}

  ngOnInit(): void {
    
  }
  ngAfterViewInit():void{
    this.getRol(this.router)
  }

  createRoutes(router:Router){
    const r:any = router.config[0].children
    
    const tree= r.filter((el:any) =>  {
      if(el.data) return el
    }) 
    let routes = tree
                  .map((el:any) => 
                  (
                    { 
                      name:el.data.breadcrumb,
                      path: el.path, 
                      roles: el.data.roles,
                      children: el.data.children !== [] && typeof el.data.children !== undefined ? el.data.children : [],
                    }
                  ))  
    routes=this.filterByRole(routes, this.role)
    routes=this.filterChildren(routes)
    this.routes=routes
    this.cdr.detectChanges()
  }

  getRol(router:Router){
    
    this.usersService.getUser(this.authService.getUID()).pipe(
      map( (user:User) => user),
      mergeMap((user:User) => this.rolService.getRol(user.roleId)),
      take(1)
    ).subscribe((data:any)=> {
      this.role=data.name
      this.createRoutes(router);
    })
  }

  filterByRole(routes:Path [], role:string){
    return routes.filter((el:Path)=>{
      let match = false;
      if(!el.roles)return match;

      el.roles.forEach((el:string) => {
        if(el===role)
          match=true;
      });
      return match;
    })
  }

  filterChildren(routes:Path[]){
    return routes.map((el:Path) => {
      if(!el.children)return el;
      el.children = el.children.map((child:Path)=>{
        const path = child.path;
        child.path =`${el.path}/${path}`;
        return child;
      })
      return el;
    })

  }
  
}
