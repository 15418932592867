import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { CthDataGridConfiguration } from '../../../../components/cth-data-grid/cth-data-grid.component';
import { ClrDatagridStateInterface } from '@clr/angular';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent implements OnInit {
  //Atributes

  //Array for Rows of Data
  _rows: any[] = [];

  //Getter for Rows
  get rows(): any {
    return this._rows;
  }

  //Setter for Rows
  @Input()
  set rows(rows: any) {
    this._rows = rows;
    this.selectedRows = [];
  }

  //Input for Modal Title
  @Input() title: string = '';

  //Input for Modal Subtitle
  @Input() subtitle: string = '';

  //Input for Boolean to Show the Modal
  @Input() showModal: boolean = false;

  //Input for the Button Text
  @Input() buttonText: string = '';

  //Input for the Data Name For Display
  @Input() dataNameForDisplay: string = '';

  //Input for the Data Elements
  @Input() dataElements: any[] = [];

  //Input for the Actions
  @Input() actions: any[] = [];

  //Output for the Button Function
  @Output() buttonFunction = new EventEmitter<Boolean>();

  //Output for the Toggle Modal Function
  @Output() toggleModal = new EventEmitter<Boolean>();

  //Output for the Toggle Alert Function
  @Output() toggleAlert = new EventEmitter<Boolean>();

  //Loading boolean
  loading: boolean = false;

  //Selected Rows of CthDataGrid
  selectedRows: any[] = [];

  //Data Grid Configuration Variable
  dataGridConfig: CthDataGridConfiguration<any> =
    {} as CthDataGridConfiguration<any>;

  //CthDataGrid parameters

  loadingRowDetails = true;

  /**
   * Function to Handle Details Toggle
   * @function onDetailsToggle
   * @param row information of CthDataGrid Rows
   */
  onDetailsToggle(row: any) {
    if (!row) {
      // null
      !this.loadingRowDetails && (this.loadingRowDetails = true);
      return;
    }
    setTimeout(() => {
      this.loadingRowDetails = false;
    }, 1000);
  }

  /**
   * Function to Handle Refresh
   * @function handleRefresh
   * @param state
   */
  handleRefresh(state: ClrDatagridStateInterface) {
    console.log(state);
  }

  /**
   * Function to Handle Selected Rows
   * @function onSelectedRowsChange
   * @param rows Selected Rows
   */
  onSelectedRowsChange(rows: any[]) {
    // console.log(this.selectedRows);
  }

  //Constructor
  constructor() {}

  /**
   * Function to Create CthDataGrid Configuration
   * @function createDataGridConfig
   */
  createDataGridConfig = (): CthDataGridConfiguration<any> => {
    return {
      dataNameForDisplay: this.dataNameForDisplay,
      dataElements: this.dataElements,
      preserveSelection: true,
      actions: this.actions,
    };
  };

  /**
   * Function for Toggle Confirmation Modal
   * @function toggleConfirmationModal
   */
  toggleConfirmationModal = () => {
    this.toggleModal.emit(true);
  };

  /**
   * Fucntion for Do the Button Function
   * @function doButtonFunction
   */
  doButtonFunction = () => {
    this.buttonFunction.emit(true);
  };

  ngOnInit(): void {
    //Function to Create CthDataGrid Configuration
    this.dataGridConfig = this.createDataGridConfig();
  }
}
