//Imports
import { ClrDatagridStateInterface } from '@clr/angular';
import { CthDataGridConfiguration } from '../../../../components/cth-data-grid/cth-data-grid.component';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-simple-confirmation-modal',
  templateUrl: './simple-confirmation-modal.component.html',
  styleUrls: ['./simple-confirmation-modal.component.scss'],
})
export class SimpleConfirmationModalComponent implements OnInit {
  //Atributes

  //Input for Rows of Data
  @Input() rows: any[] = [];

  //Input for Rows Name for Display
  @Input() rowsNames: any[] = [];

  //Input for Modal Title
  @Input() title: string = '';

  //Input for Modal Subtitle
  @Input() subtitle: string = '';

  //Input for Boolean to Show the Modal
  @Input() showModal: boolean = false;

  //Input for the Button Text
  @Input() buttonText: string = '';

  //Output for the Button Function
  @Output() buttonFunction = new EventEmitter<Boolean>();

  //Output for the Toggle Modal Function
  @Output() toggleModal = new EventEmitter<Boolean>();

  //Output for the Toggle Alert Function
  @Output() toggleAlert = new EventEmitter<Boolean>();

  constructor() {}

  /**
   * Function for Toggle Confirmation Modal
   * @function toggleConfirmationModal
   */
  toggleConfirmationModal = () => {
    this.toggleModal.emit(true);
  };

  /**
   * Fucntion for Do the Button Function
   * @function doButtonFunction
   */
  doButtonFunction = () => {
    this.buttonFunction.emit(true);
  };

  ngOnInit(): void {}
}
