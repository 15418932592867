import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentReference } from '@angular/fire/firestore';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { Organization, OrganizationAddress } from '../models/organization';
import { OrganizationOwner } from '../models/organizationOwner';
import firebase from 'firebase/app';
import { switchMap, take } from 'rxjs/operators';
import { AuthService } from 'src/app/services/authentication/auth.service';

@Injectable({
  providedIn: 'root',
})
export class OrganizationsService {
  query: Subscription;
  totalItems: number = 20;
  organizations$: Observable<any[]>;
  pageSize: number = 10;
  organizationsAlt: BehaviorSubject<Organization[]> = new BehaviorSubject<
    Organization[]
  >([]);
  currentOrganization$: BehaviorSubject<Organization | null> =
    new BehaviorSubject<Organization | null>(null);

  constructor(private db: AngularFirestore, private authService: AuthService) {
    this.query = this.db
      .collection('environments/asp-it-demo/organizations', (ref) =>
        ref.limit(20).orderBy('createdAt', 'desc')
      )
      .valueChanges({ idField: 'id' })
      .subscribe((data: any) => {
        this.totalItems = 0;
        const organizations = [];
        for (let doc of data) {
          let organization = {
            ...doc,
            createdAt: doc.createdAt ? doc.createdAt.toDate() : 'N/E',
            updatedAt: doc.updatedAt ? doc.updatedAt.toDate() : 'N/E',
            deletedAt: doc.deletedAt ? doc.deletedAt.toDate() : 'N/E',
          };
          organizations.push(organization);
        }
        this.totalItems += organizations.length;
        this.organizationsAlt.next(organizations);
      });

    this.organizations$ = this.db
      .collection<Organization>('environments/asp-it-demo/organizations')
      .valueChanges({ idField: 'id' })
      .pipe(
        switchMap((orgs) => {
          this.authService.authUser$.subscribe(() => {
            const currentUser = authService.getCurrentUser();
            if (currentUser) {
              const organization = orgs.find(
                (org) => org.id === currentUser.organizationId
              );
              if (organization) {
                this.setCurrentOrganization(organization);
              }
            }
          });
          return of(orgs);
        })
      );
  }

  setCurrentOrganization(organization: Organization | null) {
    this.currentOrganization$.next(organization || null);
  }

  listenOrganizations(newTotal: number) {
    this.query.unsubscribe();
    this.query = this.db
      .collection('environments/asp-it-demo/organizations', (ref) =>
        ref.limit(newTotal).orderBy('createdAt', 'desc')
      )
      .valueChanges({ idField: 'id' })
      .subscribe((data: any) => {
        const organizations = [];
        for (let doc of data) {
          let organization = {
            ...doc,
            createdAt: doc.createdAt ? doc.createdAt.toDate() : 'N/E',
            updatedAt: doc.updatedAt ? doc.updatedAt.toDate() : 'N/E',
            deletedAt: doc.deletedAt ? doc.deletedAt.toDate() : 'N/E',
          };
          organizations.push(organization);
        }
        this.totalItems = organizations.length;
        this.organizationsAlt.next(organizations);
      });
  }

  buildOrganizationStrAddress(orgAddr: OrganizationAddress): string {
    return (
      orgAddr.city +
      ', ' +
      orgAddr.country +
      ', ' +
      orgAddr.state +
      ', ' +
      orgAddr.street +
      ', ' +
      orgAddr.zip
    );
  }

  getNextPage(total: number) {
    console.log('total: ', total);
    console.log('newTotal: ', this.totalItems);
    if (total >= this.totalItems) {
      console.log('Query');
      this.listenOrganizations(total + 10);
    }
  }

  changePageSize(newPageSize: number) {
    return (this.pageSize = newPageSize);
  }

  getOrganizationProperties(): string[] {
    return [
      'id',
      'contextId',
      'publicName',
      'fiscalName',
      'fiscalId',
      'ownerId',
      'planId',
      'contractStart',
      'contractEnd',
      'availableEntities',
      'consumedEntities',
      'deleted',
      'createdAt',
      'updatedAt',
      'deletedAt',
    ];
  }

  getOrganizations(): Observable<Organization[]> {
    return this.organizations$;
  }

  getOrganizationsAlt(): Observable<Organization[]> {
    return this.organizationsAlt;
  }

  getOrganization(id: string): any {
    return this.db
      .collection('environments/asp-it-demo/organizations')
      .doc(id)
      .valueChanges();
  }

  getOrganizationOwner(id: string): any {
    return this.db
      .collection('environments/asp-it-demo/organizationsOwners')
      .doc(id)
      .valueChanges();
  }

  getOrganizationsM() {
    // return this.db.collection('environments/asp-it-demo/roles').snapshotChanges().subscribe(r=>r.map(w=>w.payload.doc.id))
    return this.db
      .collection('environments/asp-it-demo/organizations')
      .snapshotChanges();
  }

  setOrganization(id: string, data: any) {
    this.db
      .collection('environments/asp-it-demo/organizations')
      .doc(id)
      .update(data);
  }

  setOwner(id: string, data: any) {
    return this.db
      .collection('environments/asp-it-demo/organizationsOwners')
      .doc(id)
      .update(data);
  }

  createOwner(data: any) {
    return this.db
      .collection('environments/asp-it-demo/organizationsOwners')
      .add(data);
  }

  async addOrganization(payload: any): Promise<any> {
    let ownerId = '';
    if (payload.owner) {
      let ownerDoc = await this.db
        .collection('environments/asp-it-demo/organizationsOwners')
        .add(payload.owner); //.valueChanges()
      ownerId = ownerDoc.id;
    }
    delete payload.owner;
    payload.ownerId = ownerId;
    //return this.db.collection('environments/asp-it-demo/testDocuments').add({...payload, createdAt: new Date(), updatedAt: new Date(), deleted: false})//.valueChanges()
    return this.db.collection('environments/asp-it-demo/organizations').add({
      ...payload,
      createdAt: new Date(),
      updatedAt: new Date(),
      deleted: false,
    }); //.valueChanges()
  }

  getOrganizationById(id: string) {
    return this.db
      .collection<Organization>('environments/asp-it-demo/organizations')
      .doc(id)
      .valueChanges({ idField: 'id' });
  }

  getOrganizationOwners(): Observable<OrganizationOwner[]> {
    return this.db
      .collection<OrganizationOwner>(
        'environments/asp-it-demo/organizationsOwners'
      )
      .valueChanges({ idField: 'id' });
  }
  updateOrganization(id: string, data: any): Promise<any> {
    return this.db
      .collection('environments/asp-it-demo/organizations')
      .doc(id)
      .update(data);
  }
  createOrganization(data: Organization): Promise<DocumentReference<unknown>> {
    return this.db.collection('environments/asp-it-demo/organizations').add({
      ...data,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
  }
}
