<div class="confirmation-modal">
  <clr-modal [(clrModalOpen)]="showModal">
    <div class="modal-title">
      <h3>{{ title }}</h3>
      <h6>{{ subtitle }}</h6>
    </div>
    <div class="modal-body" *ngIf="loading">
      <app-loader></app-loader>
    </div>
    <div class="modal-body" *ngIf="!loading">
      <app-cth-data-grid
        [config]="dataGridConfig"
        [rows]="rows"
        [loadingDetails]="loadingRowDetails"
        [(selectedRows)]="selectedRows"
        (selectedRowsChange)="onSelectedRowsChange($event)"
        (detailsToggle)="onDetailsToggle($event)"
        (refresh)="handleRefresh($event)"
      >
      </app-cth-data-grid>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-outline"
          (click)="toggleConfirmationModal()"
        >
          Cancel
        </button>
        <button
          type="button"
          class="btn btn-primary"
          (click)="doButtonFunction()"
          [disabled]="rows.length === 0"
        >
          {{ buttonText }}
        </button>
      </div>
    </div>
  </clr-modal>
</div>
