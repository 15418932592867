import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import {
  ClrDatagrid,
  ClrDatagridComparatorInterface,
  ClrDatagridFilterInterface,
  ClrDatagridStateInterface,
} from '@clr/angular';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Currency } from 'src/app/models/currency';
import { MoneySafeOperationsService } from 'src/app/services/money-safe-operations.service';
import { DataIndex } from '../../models/general';

export type CthDataGridActionSelection =
  | 'one'
  | 'some'
  | 'none'
  | 'all'
  | 'any';

export type FnExecutionMode = 'pipe' | 'direct';

export interface CthDataGridDetailPaneConfig<T> {
  detailPaneTitleKey: string;
  detailPaneContentFn: (data: T) => string;
  detailPaneFallback?: string;
  isHtml?: boolean;
}

export interface CthDataGridExpandableRowConfig<T> {
  expandableRowContentFn: (data: T) => string;
  expandableRowContentFallback?: string;
  isHtml?: boolean;
}

export interface CthDataGridAction<T> {
  callback: (rows: T[]) => void;
  icon?: string;
  text: string;
  selection: CthDataGridActionSelection;
}

export interface CthDataGridFooterConfig {
  pageSizeOptions?: number[];
  hideItemsPerPageText?: boolean;
  hideQuantityOutOfTotalText?: boolean;
}

export interface CthDataGridRowAction<T> {
  text: string;
  callback: (row: T, rowIndex: number) => void;
  conditionFn?: (row: T, rowIndex: number) => boolean;
  conditionFnExecutionMode?: FnExecutionMode;
}

export interface CtgDataGridDataElement<T> {
  headerText: string;
  dataKey: string;
  type: 'string' | 'number' | 'link' | 'button' | 'currency';
  linkConfig?: {
    text: string;
    type: 'internal' | 'external';
    route: string;
    hideConditionFn?: (row: T, rowIndex: number) => boolean;
    hideCondExeMode?: FnExecutionMode;
    class?: string;
  };
  buttonConfig?: {
    text: string;
    fn: (row: T, rowIndex: number) => void;
    hideConditionFn?: (row: T, rowIndex: number) => boolean;
    hideCondExeMode?: FnExecutionMode;
    class?: string;
  };
  filterType?: 'simple' | 'custom' | undefined;
  fn?: (data: T) => string | number;
  fnExecutionMode?: FnExecutionMode;
  isHtml?: boolean;
  customFilter?: ClrDatagridFilterInterface<T>;
  customSorter?: ClrDatagridComparatorInterface<any>;
  fallback?: string;
  maxWidth?: number;
  avoidLineBreaks?: boolean;
}

export interface CthDataGridConfiguration<T> {
  dataElements: CtgDataGridDataElement<T>[];
  rowActions?: CthDataGridRowAction<T>[];
  placeholder?: string;
  compact?: boolean;
  preserveSelection?: boolean;
  dataNameForDisplay?: string;
  actions?: CthDataGridAction<T>[];
  detailPaneConfig?: CthDataGridDetailPaneConfig<T>;
  expandableRowConfig?: CthDataGridExpandableRowConfig<T>;
  footerConfig?: CthDataGridFooterConfig;
  selectableRowConditionFn?: (data: T) => boolean;
  selectionField?: string;
}

interface DefaultValues {
  pageSizeOptions: number[];
  startingPageSize: number;
  detailPaneTitle: string;
  cellValue: string;
  headerText: string;
  dataNameForDisplay: string;
  detailPaneLoadingText: string;
  fnExecutionMode: FnExecutionMode;
}

type UpdateValuesNames = 'selectedRows';

@Component({
  selector: 'app-cth-data-grid',
  templateUrl: './cth-data-grid.component.html',
  styleUrls: ['./cth-data-grid.component.scss'],
})
export class CthDataGridComponent<T>
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild(ClrDatagrid) clrDataGrid: ClrDatagrid<T>;

  constructor(private moneySafeOperationsService: MoneySafeOperationsService) {}

  private destroyed$ = new Subject<boolean>();
  //currency formatter
  private formatter: Intl.NumberFormat;

  @Input() config: CthDataGridConfiguration<T> = {
    dataElements: [],
    preserveSelection: true,
    actions: [],
  };
  @Input() rows: T[] = [];
  @Input() loading = false;
  @Input() loadingDetails = false;
  @Input() visible = true;

  @Input() selectedRows: T[] = [];
  @Output() selectedRowsChange: EventEmitter<T[]> = new EventEmitter<T[]>();

  @Output() detailsToggle: EventEmitter<T> = new EventEmitter<T>();

  @Output() refresh: EventEmitter<ClrDatagridStateInterface> =
    new EventEmitter<ClrDatagridStateInterface>();

  @Output() clrDataGridObj = new EventEmitter<ClrDatagrid<T>>();

  actionsAvailabilityConditionsBySelection: {
    [key in CthDataGridActionSelection]: boolean;
  } = {
    one: false,
    some: false,
    none: true,
    all: false,
    any: true,
  };

  defaultValues: DefaultValues = {
    cellValue: '---',
    dataNameForDisplay: 'Items',
    detailPaneLoadingText: 'Cargando detalles...',
    detailPaneTitle: '',
    headerText: '---',
    pageSizeOptions: [10, 20, 50, 100],
    startingPageSize: 10,
    fnExecutionMode: 'pipe',
  };

  columnStyle = {};

  handleRowSelected(isSelected: boolean, row: T) {
    if (this.config.selectionField) {
      (row as any)[this.config.selectionField] = isSelected;
    }
  }

  private updateActionsAvailability(selectedRowsNumber: number) {
    this.actionsAvailabilityConditionsBySelection = {
      one: selectedRowsNumber === 1,
      some: selectedRowsNumber > 0,
      none: selectedRowsNumber === 0,
      all: selectedRowsNumber === this.rows.length,
      any: true,
    };
  }

  private subscribeToCurrency() {
    this.moneySafeOperationsService.currentCurrency$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((val) => {
        this.formatter = new Intl.NumberFormat(val.codeIntl, {
          style: 'currency',
          currency: val.currency,
        });
      });
  }

  handleValueUpdate(event: any, type: UpdateValuesNames) {
    switch (type) {
      case 'selectedRows':
        if (this.config.selectionField) {
          this.rows.forEach(
            (row) => ((row as any)[this.config.selectionField!] = false)
          );
          (event as T[]).forEach(
            (row) => ((row as any)[this.config.selectionField!] = true)
          );
        }
        this.selectedRows = event as T[];
        this.selectedRowsChange.emit(event as T[]);
        this.updateActionsAvailability(this.selectedRows.length);
        break;
      default:
        console.error(
          `Unhandled value type "${type}" was passed to value update handler`
        );
        break;
    }
  }

  onRefresh(state: ClrDatagridStateInterface) {
    this.refresh.emit(state);
  }

  onDetailChange(row: T) {
    this.detailsToggle.emit(row);
  }

  ngAfterViewInit(): void {
    this.clrDataGridObj.emit(this.clrDataGrid);
  }

  ngOnInit(): void {
    this.subscribeToCurrency();
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
  }
}
