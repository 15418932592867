//Angular imports
import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
//RXJS imports
import { Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
//Local imports
import { AuthService } from '../services/authentication/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  //Constructor
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.checkLogin();
  }

  /**Checks for the user login */
  checkLogin(): Observable<boolean> {
    return this.authService.authUser$?.pipe(
      take(1),
      map((user) => {
        return !!user; //Transform to boolean
      }),
      tap((loggedIn) => {
        if (!loggedIn) {
          console.log('Access denied, not logged in');
          this.router.navigate(['/login']); //Navigate to login route
        } else {
          // console.log('User logged in');
        }
      })
    );
  }
}
