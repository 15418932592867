import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-pop-up-alert',
  templateUrl: './pop-up-alert.component.html',
  styleUrls: ['./pop-up-alert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopUpAlertComponent implements OnInit {
  //Atributes

  //Show Alert Boolean
  _showAlert: boolean = false;

  //Getter for ShowAlert
  get showAlert(): boolean {
    return this._showAlert;
  }

  //Setter for show Alert
  @Input()
  set showAlert(value: boolean) {
    this._showAlert = value;
    if (this.showAlert) {
      this.increaseProgressBar();
    }
  }

  //Input for Alert Type Variable (danger, info, succes, warning)
  @Input() type: string = 'alert-success';

  //Input for Alert Text
  @Input() text: string = '';

  //Input for Alert Duration
  @Input() duration: number = 5;

  //Output for the Toggle Alert Function
  @Output() toggleAlert = new EventEmitter<Boolean>();

  //Progress Bar Variable
  progress: number = 0;

  //Table of AlertTypes
  alertType = {
    danger: 'alert-danger',
    info: 'alert-info',
    success: 'alert-success',
    warning: 'alert-warning',
  };

  //Constructor
  constructor() {}

  /**
   * Function to Get the Alert Type
   * @returns Alert Type string
   */
  getAlertType = (): string => {
    return (
      (this.alertType as { [index: string]: string })[this.type] ||
      'alert-success'
    );
  };

  /**
   * Function to Increase Progess Bar
   */
  increaseProgressBar = () => {
    var interval = setInterval(() => {
      this.progress += 1;
      if (this.progress == 100) {
        //If Progrees Bar Completed
        //Clear Interval
        clearInterval(interval);
        //Hide Alert
        this.toggleAlert.emit(true);
        //Reset Progress Bar
        this.progress = 0;
      }
    }, this.duration * 10);
  };

  ngOnInit(): void {}
}
