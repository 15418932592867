//Angular imports
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { permissionsMap } from './constants/permissions.constant';
//Local imports
import { AuthGuard } from './guards/auth.guard';
import { RankGuard } from './guards/rank.guard';
import { FullNavigationComponent } from './navigation/full-navigation/full-navigation.component';

/**Routes for the application */
const routes: Routes = [
  {
    //Guarded routes
    path: '', //App base
    component: FullNavigationComponent, //Navigation componente
    children: [
      {
        path: '', //Empty redirection
        redirectTo: 'home',
        pathMatch: 'full',
      },
      {
        path: 'home',
        loadChildren: () =>
          import('./modules/home/home.module').then((mod) => mod.HomeModule),
        data: { breadcrumb: 'Home', roles: ['Admin', 'User'] },
      },
      {
        path: 'users',
        data: {
          breadcrumb: 'Users',
          roles: ['Admin'],
          children: [{ path: 'add', name: 'Add' }],
        },
        loadChildren: () =>
          import('./modules/users/users.module').then((mod) => mod.UsersModule),
      },
      {
        path: 'organizations',
        data: {
          breadcrumb: 'Organizations',
          roles: ['Admin'],
          children: [{ path: 'create', name: 'Create' }],
        },
        loadChildren: () =>
          import('./modules/organizations/organizations.module').then(
            (mod) => mod.OrganizationsModule
          ),
      },
      {
        path: 'suborganizations',
        data: {
          breadcrumb: 'Suborganizations',
          roles: ['Admin'],
          children: [{ path: 'create', name: 'Create' }],
        },
        loadChildren: () =>
          import('./modules/suborganizations/suborganizations.module').then(
            (mod) => mod.SuborganizationsModule
          ),
      },
      {
        path: 'entities',
        data: {
          breadcrumb: 'Entities',
          roles: ['User', 'Admin'],
          children: [{ path: 'add', name: 'Add' }],
        },
        loadChildren: () =>
          import('./modules/entities/entities.module').then(
            (mod) => mod.EntitiesModule
          ),
      },
      {
        path: 'not-found',
        redirectTo: 'home',
      },
      {
        path: 'roles',
        data: {
          breadcrumb: 'Roles',
          roles: ['Admin'],
          children: [{ path: 'create', name: 'Create' }],
        },
        loadChildren: () =>
          import('./modules/roles/roles.module').then((mod) => mod.RolesModule),
      },
      {
        path: 'notifications',
        data: {
          breadcrumb: 'Notifications',
          roles: ['Admin'],
          children: [{ path: 'create', name: 'Create' }],
        },
        loadChildren: () =>
          import('./modules/notifications/notifications.module').then(
            (mod) => mod.NotificationsModule
          ),
      },
      {
        path: 'hr',
        data: {
          breadcrumb: 'Human resources',
          ranks: permissionsMap.hr.ranks,
          freelance: permissionsMap.hr.freelance,
        },
        canActivate: [RankGuard],
        loadChildren: () =>
          import('./modules/hr/hr.module').then((mod) => mod.HrModule),
      },
      {
        path: 'inventory',
        data: {
          breadcrumb: 'Inventory',
          ranks: permissionsMap.inventory.ranks,
          freelance: permissionsMap.inventory.freelance,
        },
        loadChildren: () =>
          import('./modules/inventory/inventory.module').then(
            (mod) => mod.InventoryModule
          ),
      },
    ], //Childs of this route
    canActivate: [AuthGuard],
  },
  {
    //Unguarded routes
    path: 'login', //Auth module
    loadChildren: () =>
      //Lazy load auth module
      import('./modules/authentication/authentication.module').then(
        (m) => m.AuthenticationModule
      ),
    data: { breadcrumb: null },
  },
  {
    path: 'not-found',
    redirectTo: 'login',
  },
  {
    path: '**',
    redirectTo: 'not-found',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
