import { Component, OnInit } from '@angular/core';
import { AlertsService } from 'src/app/services/alerts.service';
import { AuthService } from 'src/app/services/authentication/auth.service';
import { ASPNavigationTree } from 'src/app/constants/nav.constant';
import { OrganizationsService } from 'src/app/services/organizations.service';
import { Organization } from '../../models/organization';
import { generateNavigation } from '../../helpers/utils/nav-operations';
import { Employee, EmployeeRank, ShiftType } from 'src/app/models/employee';
import { EmployeesService } from 'src/app/services/employees.service';
import { ASPNavigationNode } from 'src/app/models/general';
import { FREE_NAVIGATION } from '../../constants/nav.constant';
import { User } from '../../models/user';
import {
  badgeClassByRank,
  headerClassByRank,
  rankAbbs,
} from 'src/app/constants/employee.constant';
import { filter, take } from 'rxjs/operators';

import { currencyData } from '../../constants/currency.constant';
import { MoneySafeOperationsService } from '../../services/money-safe-operations.service';
import { Currency } from '../../models/currency';

@Component({
  selector: 'app-full-navigation',
  templateUrl: './full-navigation.component.html',
  styleUrls: ['./full-navigation.component.scss'],
})
export class FullNavigationComponent implements OnInit {
  /**Has to login flag */
  login: boolean = true; //Initialize as false before construction
  collapsed: boolean = true;

  /**
   * variable que maneja el tipo de alerta a renderizar en el front
   */
  alertType = 'navLevel';
  show = false;
  /**
   * variable que maneja el mensaje con el caso de alerta
   */
  alertCase =
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sagittis orci sed felis scelerisque, ac malesuada quam facilisis. Aliquam magna tellus, efficitur eu vulputate sit amet, pretium eget velit. Curabitur convallis vitae ligula ut elementum. Integer eget risus odio. Curabitur vitae ex consequat, elementum nunc nec, aliquet libero. Aenean aliquam leo non lectus elementum, nec efficitur ante faucibus. Curabitur arcu ipsum, ultricies at aliquet non, dapibus sit amet augue. Pellentesque sagittis nisi vitae enim egestas, at gravida nibh pulvinar. Phasellus justo nisl, scelerisque gravida fringilla in, aliquam a risus. Nulla quis dui porttitor risus varius tempor. Morbi ac ultricies felis. Phasellus vel felis in dolor feugiat sodales et id est.';

  // left it as superadmin to get all sidebar options while still developing
  navigation: ASPNavigationNode[];
  // navigation = ASPNavigationTree;

  organizations: Organization[];
  currentOrganization: Organization;

  userEmail: string | null = null;

  currentEmployee: Employee;

  badgeClassByRank = badgeClassByRank;
  headerClassByRank = headerClassByRank;
  rankAbbs = rankAbbs;
  userFullName: string;
  employeeRank: EmployeeRank;
  isFreelancer: boolean;

  backgroundColor: string = 'red';
  fontColor: string = 'white';
  tagText: string = 'OUT';
  isLoading: boolean = false;

  //currency options
  currencyOptions = currencyData;
  currentMoney: Currency;

  setShow(status: boolean) {
    console.log('Estatus:', status);
    this.show = status;
  }

  constructor(
    private authService: AuthService,
    public alertService: AlertsService,
    private organizationsService: OrganizationsService,
    private empService: EmployeesService,
    private moneySafeOperationsService: MoneySafeOperationsService
  ) {
    alertService.getAlertSubscribe().subscribe((data: any) => {
      // console.log("ALERTA: ", data)
      this.alertType = data.alertType;
      this.show = data.show;
      this.alertCase = data.alertCase;
      // console.log(this.show)
    });
  }

  private fetchCurrentUser(): Promise<User> {
    return this.authService._currentUser$
      .pipe(
        filter((user) => !!user),
        take(1)
      )
      .toPromise() as Promise<User>;
  }

  logout = () => {
    this.authService.singOut();
  };

  changeCurrency(newCurrency: Currency) {
    this.moneySafeOperationsService.changeCurrency(newCurrency);
  }

  handleOrganizationSelection(org: Organization) {
    this.organizationsService.setCurrentOrganization(org);
    console.log(org);
  }

  private subscribeToCurrentOrganization() {
    this.organizationsService.currentOrganization$.subscribe((org) => {
      this.currentOrganization = org!;
    });
  }

  private setOrganizations() {
    this.organizationsService.organizations$.subscribe((orgs) => {
      this.organizations = orgs;
    });
  }

  private subscribeToCurrentUser() {
    this.authService.currentUser$.subscribe((user) => {
      this.userEmail = user?.email || null;
    });
  }

  private setUpNavigation(rank: EmployeeRank, freelance: boolean) {
    if (FREE_NAVIGATION) {
      this.navigation = generateNavigation(EmployeeRank.superadmin, false);
      return;
    }
    this.navigation = generateNavigation(rank, freelance);
  }

  private handleCurrentEmployeeChange(employeeNew: Employee | null) {
    if (!employeeNew) return;

    if (this.currentEmployee) {
      const employeePermissionsChanged =
        this.currentEmployee.rank !== employeeNew.rank ||
        this.currentEmployee.shiftType !== employeeNew.shiftType;

      if (employeePermissionsChanged) {
        this.setUpNavigation(
          employeeNew.rank,
          employeeNew.shiftType === ShiftType.freelance
        );
      }
    } else {
      this.setUpNavigation(
        employeeNew.rank,
        employeeNew.shiftType === ShiftType.freelance
      );
    }
    this.currentEmployee = employeeNew;
    if (!this.userFullName) {
      this.userFullName =
        this.currentEmployee.fullName ||
        `${this.currentEmployee.firstName} ${this.currentEmployee.lastName}`;
    }
    this.employeeRank = this.currentEmployee.rank;
    this.isFreelancer = this.currentEmployee.shiftType === ShiftType.freelance;
  }

  private subscribeToCurrentEmployee() {
    this.empService.currentEmployee$.subscribe(
      this.handleCurrentEmployeeChange.bind(this)
    );
  }

  private subscribeToCurrency() {
    this.moneySafeOperationsService.currentCurrency$.subscribe((val) => {
      this.currentMoney = val;
    });
  }

  ngOnInit(): void {
    this.subscribeToCurrency();
    this.subscribeToCurrentEmployee();
    this.setOrganizations();
    this.subscribeToCurrentOrganization();
    this.subscribeToCurrentUser();
    this.login = location.pathname === '/login';
  }
}
