<div class="clr-row">
    <div class="clr-col-4"></div>
    <div class="clr-col-4">
      <div class="card">
        <div class="card-header">
          <h2 style="margin: 0; text-align: center;">Register</h2>
        </div>
        <div class="card-block">
            <form
              *ngIf="registerForm && !success"
              [formGroup]="registerForm"
              (ngSubmit)="registerHandler()"
              class="clr-form clr-form-vertical"
            >
              <div class="clr-form-control">
                <label for="email" class="clr-control-label">Email</label>
                <div class="clr-control-container">
                  <div class="clr-input-wrapper">
                    <input
                      id="email"
                      name="email"
                      formControlName="email"
                      type="text"
                      class="clr-input"
                      placeholder="user@example.com"
                    />
                  </div>
                </div>
              </div>
              <div class="clr-form-control">
                <label for="token" class="clr-control-label">Token (sent to your email)</label>
                <div class="clr-control-container">
                  <div class="clr-input-wrapper">
                    <input
                      id="token"
                      name="token"
                      formControlName="token"
                      type="password"
                      class="clr-input"
                      placeholder="******"
                    />
                  </div>
                </div>
              </div>
              <div class="clr-form-control">
                <label for="password" class="clr-control-label">Password</label>
                <div class="clr-control-container">
                  <div class="clr-input-wrapper">
                    <input
                      id="password"
                      name="password"
                      formControlName="password"
                      type="password"
                      class="clr-input"
                      placeholder="******"
                    />
                  </div>
                  <span
                    class="clr-subtext"
                    *ngIf="registerForm.controls['password'].touched && registerForm.controls['password'].errors?.minlength"
                  >
                    Password must be at least {{ PASSWORD_MIN_LENGTH }} characters long
                  </span>
                </div>
              </div>
              <div class="clr-form-control">
                <label for="password" class="clr-control-label">Confirm password</label>
                <div class="clr-control-container">
                  <div class="clr-input-wrapper">
                    <input
                      id="passwordRepeat"
                      name="passwordRepeat"
                      formControlName="passwordRepeat"
                      type="password"
                      class="clr-input"
                      placeholder="******"
                    />
                  </div>
                  <span
                    class="clr-subtext"
                    *ngIf="registerForm.controls['passwordRepeat'].touched && registerForm.errors?.passwordsDontMatch"
                  >
                    Passwords don't match
                  </span>
                </div>
              </div>

              <br>

              <div class="clr-row clr-justify-content-center">
                <button
                  class="btn btn-primary"
                  type="submit"
                  [disabled]="!registerForm.valid || registering"
                >
                  Register
                </button>
              </div>
            </form>

            <ng-container *ngIf="success">
              <p style="text-align: center;">
                Your account has been created! You may now login.
              </p>
            </ng-container>

            <br>

            <div class="clr-row clr-justify-content-center">
              <button
                class="btn btn-link"
                type="submit"
                [routerLink]="['/', 'login']"
              >
                Login
              </button>
            </div>
        </div>
      </div>
    </div>
  </div>
  
