import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { CreateUserPayload } from '../models/createUserPayload';
import { User } from '../models/user';
import { AuthService } from './authentication/auth.service';
import firestore from 'firebase/app';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  query: Subscription;
  totalItems: number = 20;
  users$: Observable<any[]>;
  pageSize: number = 10;
  usersAlt: BehaviorSubject<User[]> = new BehaviorSubject<User[]>([]);

  constructor(
    private firestore: AngularFirestore,
    private fns: AngularFireFunctions
  ) {
    this.query = this.firestore
      .collection('environments/asp-it-demo/users', (ref) =>
        ref.limit(20).orderBy('createdAt', 'desc')
      )
      .valueChanges({ idField: 'id' })
      .subscribe((data: any) => {
        this.totalItems = 0;
        const users = [];
        for (let doc of data) {
          let user = {
            ...doc,
            createdAt: doc.createdAt ? doc.createdAt.toDate() : 'N/E',
            updatedAt: doc.updatedAt ? doc.updatedAt.toDate() : 'N/E',
            deletedAt: doc.deletedAt ? doc.deletedAt.toDate() : 'N/E',
          };
          users.push(user);
        }
        this.totalItems += users.length;
        this.usersAlt.next(users);
      });
    this.users$ = this.firestore
      .collection('environments/asp-it-demo/users')
      .valueChanges({ idField: 'id' });
  }

  listenUsers(newTotal: number) {
    firestore.firestore.FieldValue;
    this.query.unsubscribe();
    this.query = this.firestore
      .collection('environments/asp-it-demo/users', (ref) =>
        ref.limit(newTotal).orderBy('createdAt', 'desc')
      )
      .valueChanges({ idField: 'id' })
      .subscribe((data: any) => {
        const users = [];
        for (let doc of data) {
          let user = {
            ...doc,
            createdAt: doc.createdAt ? doc.createdAt.toDate() : 'N/E',
            updatedAt: doc.updatedAt ? doc.updatedAt.toDate() : 'N/E',
            deletedAt: doc.deletedAt ? doc.deletedAt.toDate() : 'N/E',
          };
          users.push(user);
        }
        this.totalItems = users.length;
        this.usersAlt.next(users);
      });
  }

  getNextPage(total: number) {
    console.log('total: ', total);
    console.log('newTotal: ', this.totalItems);
    if (total >= this.totalItems) {
      console.log('Query');
      this.listenUsers(total + 10);
    }
  }

  changePageSize(newPageSize: number) {
    return (this.pageSize = newPageSize);
  }

  getUserProperties(): string[] {
    return [
      'id',
      'email',
      'contextId',
      'firstName',
      'secondName',
      'lastName',
      'secondLastName',
      'fullName',
      'employeeId',
      'phoneNumbers',
      'deleted',
      'createdAt',
      'updatedAt',
      'deletedAt',
    ];
  }

  getUsers(): Observable<User[]> {
    return this.users$;
  }

  getUsersAlt(): Observable<User[]> {
    return this.usersAlt;
  }

  getUser(id: string): any {
    return this.firestore
      .collection('environments/asp-it-demo/users')
      .doc(id)
      .valueChanges();
  }

  getUserM(id: string): any {
    return this.firestore
      .collection('environments/asp-it-demo/users')
      .doc(id)
      .valueChanges();
  }
  getUserSnap(id: string) {
    return this.firestore
      .collection<User>('environments/asp-it-demo/users')
      .doc(id)
      .snapshotChanges();
  }

  /**Get user once */
  async getUserOnce(userId: string): Promise<User> {
    return await this.firestore
      .collection<User>('environments/asp-it-demo/users')
      .doc(userId)
      .ref.get()
      .then((user) => {
        return { id: userId, ...user.data() } as User;
      });
  }

  getUsersM() {
    // return this.firestore.collection('environments/asp-it-demo/roles').snapshotChanges().subscribe(r=>r.map(w=>w.payload.doc.id))
    return this.firestore
      .collection('environments/asp-it-demo/users')
      .snapshotChanges();
  }

  setUser(id: string, data: any) {
    this.firestore
      .collection('environments/asp-it-demo/users')
      .doc(id)
      .set(data);
  }

  update(id: string, data: any) {
    this.firestore
      .collection('environments/asp-it-demo/users')
      .doc(id)
      .update(data);
  }

  addUser(payload: CreateUserPayload): Observable<any> {
    const callable = this.fns.httpsCallable('createSystemUser');
    const result = callable(payload);
    return result;
  }

  getAllowedUsers(context: string) {
    return this.firestore
      .collection('environments/asp-it-demo/users', (ref) =>
        ref.where('allowedContexts', 'array-contains', context)
      )
      .valueChanges({ idField: 'id' });
  }
}
