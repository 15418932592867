
<clr-vertical-nav-group *ngIf="path.children; else elseBlock"  routerLinkActive>
    <a  clrVerticalNavLink [routerLink]="path.path" routerLinkActive="active">{{path.name}}</a>
    <clr-vertical-nav-group-children *ngFor="let children of path.children">
        <a  clrVerticalNavLink [routerLink]="children.path" routerLinkActive="active">{{children.name}}</a>
    </clr-vertical-nav-group-children>
</clr-vertical-nav-group>

<ng-template #elseBlock >
    <a clrVerticalNavLink [routerLink]="path.path" routerLinkActive="active">{{path.name}}</a>
</ng-template>

