//Angular imports
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../../services/authentication/auth.service';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent implements OnInit {
  //Attributes
  /**Login form */
  loginForm: FormGroup | undefined;

  /**Password recovery form */
  recoveryForm: FormGroup | undefined;

  /**Is loading flag */
  isLoading = false;

  /**Password recovery form flag */
  passwordRecovery = false;

  //Constructor
  constructor(
    private authService: AuthService,
    private fBuilder: FormBuilder,
    private router: Router
  ) {}

  //Initialization
  ngOnInit(): void {
    //Set loading flag
    this.isLoading = true;

    //Clear the auth service
    this.authService.singOut().then(() => {
      this.isLoading = false; //Turn of loading flag
    });

    //Instanciate login and recovery forms
    this.createLoginForm();
    this.createRecoveryForm();
  }

  /**
   *  Creates an instance for the login form
   */
  createLoginForm() {
    this.loginForm = this.fBuilder.group({
      email: ['', [Validators.required, Validators.email]], //Email with validations
      password: ['', [Validators.required]], //Password with validations
    });
  }

  /**
   *  Creates an instance for the recovery form
   */
  createRecoveryForm() {
    this.recoveryForm = this.fBuilder.group({
      email: ['', [Validators.required, Validators.email]], //Email with validations
    });
  }

  /**
   * Handle a submit of the login form
   */
  loginHandler() {
    if (this.loginForm && !this.isLoading) {
      //Login for not undefined and not loading a task already
      /**Input email */
      const email = this.loginForm.value.email;

      /**Input password */
      const password = this.loginForm.value.password;

      //Set loading flag
      this.isLoading = true;

      this.authService.emailSignin(email, password).then((data) => {
        if (data.error && data.status === 'FAILURE') {
          //TODO: Notification of error
          console.log('Error loging in', data.error);
        } else if (data.status === 'SUCCESS') {
          //TODO: Notification of success
          this.router.navigate(['/home']);
        }
        this.isLoading = false;
      });
    }
  }

  /**
   * Handle a submit of the recovery form
   */
  recoveryHandler() {
    console.log('( ͡°( ͡° ͜ʖ( ͡° ͜ʖ ͡°)ʖ ͡°) ͡°)');
  }

  /**
   * Toggle the show password recovery form flag
   */
  togglePasswordRecovery() {
    this.passwordRecovery = !this.passwordRecovery;
  }
}
