import { Pipe, PipeTransform } from '@angular/core';

interface HoursDetailed {
  hours: number;
  minutes: number | null;
  seconds: number | null;
  milliseconds: number | null;
}

const getHoursDetailed = (hoursAmount: number, showMinutes = true, showSeconds = false, showMilliseconds = false): HoursDetailed => {

  hoursAmount = Math.abs(hoursAmount);

  const returnable: HoursDetailed = {
    hours: 0,
    minutes: null,
    seconds: null,
    milliseconds: null,
  };
  
  const hoursInteger = Math.floor(hoursAmount);
  returnable.hours = hoursInteger;

  if (!showMinutes) {
    return returnable;
  }

  const minutesAsDecimals = hoursAmount - hoursInteger;
  const minutesAmount = minutesAsDecimals * 60;
  const minutesInteger = Math.floor(minutesAmount);
  returnable.minutes = minutesInteger;

  if (!showSeconds) {
    return returnable;
  }

  const secondsAsDecimals = minutesAmount - minutesInteger;
  const secondsAmount = secondsAsDecimals * 60;
  const secondsInteger = Math.floor(secondsAmount);
  returnable.seconds = secondsInteger;

  if (!showMilliseconds) {
    return returnable;  
  }

  const millsAsDecimals = secondsAmount - secondsInteger;
  const millsAmount = millsAsDecimals * 1000;
  const millsInteger = Math.floor(millsAmount);
  returnable.milliseconds = millsInteger;

  return returnable;
}

const formatNumber = (value: number): string => {
  return (value < 10) ? `0${Math.abs(value)}` : value.toString();
}

@Pipe({
  name: 'hourAmountFormat'
})
export class HourAmountFormatPipe implements PipeTransform {

  transform(hoursAmount: number, style: "text" | "twoDots", showMinutes = true, showSeconds = false, showMilliseconds = false): string {
    const hoursDetailed = getHoursDetailed(hoursAmount, showMinutes, showSeconds);
    
    let returnable = `${hoursAmount < 0 ? "-" : ""}${formatNumber(hoursDetailed.hours)}${style === "text" ? " h" : ""}`;

    if (!showMinutes) {
      return returnable;
    }
    
    returnable += `${style === "text" ? " " : ":"}${formatNumber(hoursDetailed.minutes!)}${style === "text" ? " m" : ""}`;

    if (!showSeconds) {
      return returnable;
    }

    returnable += `${style === "text" ? " " : ":"}${formatNumber(hoursDetailed.seconds!)}${style === "text" ? " s" : ""}`;

    if (!showMilliseconds) {
      return returnable;
    }

    returnable += `${style === "text" ? " " : ":"}${formatNumber(hoursDetailed.milliseconds!)}${style === "text" ? "" : ""}`;

    return returnable;
  }

}
