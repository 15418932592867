import { Component, Input, OnInit } from '@angular/core';
import { Path } from '../../models/path'

@Component({
  selector: 'app-side-nav-item',
  templateUrl: './side-nav-item.component.html',
  styleUrls: ['./side-nav-item.component.scss']
})
export class SideNavItemComponent implements OnInit {
  @Input('path') path!:Path;

  constructor() { }

  ngOnInit(): void {
  }
  ngAfterViewInit():void{
    
  }

}
