import { EmployeeRank } from "../models/employee";

export const badgeClassByRank
    : { [rank in EmployeeRank]: string }
    = {
        admin: "badge-purple",
        employee: "",
        supervisor: "badge-light-blue",
        superadmin: "badge-success",
    };

export const rankAbbs
    : { [rank in EmployeeRank]: string }
    = {
        admin: "AD",
        employee: "EMP",
        supervisor: "SU",
        superadmin: "SA",
    };

export const headerClassByRank
    : { [rank in EmployeeRank]: string }
    = {
        admin: "header-3",
        employee: "header-6",
        supervisor: "header-4",
        superadmin: "header-1",
    };