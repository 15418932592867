//Angular imports
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginFormComponent } from './login-form/login-form.component';
import { RegisterFormComponent } from './register-form/register-form.component';

/**Auth module routes */
const routes: Routes = [
  {
    path: '', //Base route
    component: LoginFormComponent, //Login form
  },
  {
    path: 'register', //Base route
    component: RegisterFormComponent, //Login form
  },
];

//Module declaration
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthenticationRoutingModule {}
