import { Injectable } from '@angular/core';
import { BigNumber } from 'bignumber.js';
import { Currency, currencyTypes } from '../models/currency';
import { currencyData } from '../constants/currency.constant';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MoneySafeOperationsService {
  // private _currentMoney$: Observable<Currency> = of(
  //   currencyData[currencyTypes.Dollar]
  // );

  // currentMoneySubject: Subject<Currency> = new Subject<Currency>();

  // constructor() {
  //   this._currentMoney$ = this.currentMoneySubject.asObservable();
  // }

  // changeCurrency(newValue: Currency) {
  //   this._currentMoney$ = of(newValue);
  //   this.currentMoneySubject.next(newValue);
  // }

  currentCurrency$: BehaviorSubject<Currency> = new BehaviorSubject<Currency>(
    currencyData[currencyTypes.DOLLAR]
  );

  constructor() {}

  changeCurrency(newCurrency: Currency) {
    this.currentCurrency$.next(newCurrency);
  }

  /**
   * Add the N given numbers
   * Filters NaNs and Infinity after the operations
   */
  addNumbers(...numbers: number[]): BigNumber {
    if (numbers.length === 0) {
      //No given numbers
      return new BigNumber(0);
    }

    let x = new BigNumber('0');

    numbers.forEach((num) => {
      if (!isNaN(num) && isFinite(num)) x = x.plus(num.toString());
    });

    return x;
  }

  /**
   * Multiply the N given numbers
   */
  multiplyNumbers(...numbers: number[]): BigNumber {
    if (numbers.length === 0) {
      //No given numbers
      return new BigNumber(0);
    }

    let x = new BigNumber('1');

    numbers.forEach((num) => {
      if (!isNaN(num) && isFinite(num)) x = x.multipliedBy(num.toString());
    });

    return x;
  }

  /**
   * Divde the N given numbers
   */
  divideNumbers(a: number, b: number): BigNumber {
    const x = new BigNumber(a.toString());
    const y = new BigNumber(b.toString());

    return x.dividedBy(y);
  }
}
