import { Component, OnInit } from '@angular/core';

import '@cds/core/icon/register.js';
import {
  ClarityIcons,
  userIcon,
  usersIcon,
  plusIcon,
  addTextIcon,
  administratorIcon,
  viewListIcon,
  pencilIcon,
  buildingIcon,
  angleIcon,
  gridViewIcon,
  truckIcon,
  bundleIcon,
  factoryIcon,
  organizationIcon,
  tasksIcon,
  certificateIcon,
  tagsIcon,
  sunIcon,
  hardDriveDisksIcon,
  twoWayArrowsIcon,
  cursorHandClickIcon,
  clipboardIcon,
  storeIcon,
  eyeIcon,
  briefcaseIcon,
  checkCircleIcon,
  minusIcon,
  playIcon,
  homeIcon,
  pauseIcon,
  checkIcon,
  plusCircleIcon,
  employeeIcon,
  airplaneIcon,
  checkboxListIcon,
  fileIcon,
  paperclipIcon,
  uploadIcon,
  sliderIcon,
  clockIcon,
  detailsIcon,
  calendarIcon,
  dollarIcon,
  employeeGroupIcon,
  dollarBillIcon,
  coinBagIcon,
  refreshIcon,
  historyIcon,
  starIcon,
  hourglassIcon,
  flagIcon,
  ellipsisVerticalIcon,
  bookIcon,
} from '@cds/core/icon';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'ASP';

  ngOnInit() {
    ClarityIcons.addIcons(
      userIcon,
      usersIcon,
      plusIcon,
      addTextIcon,
      administratorIcon,
      viewListIcon,
      pencilIcon,
      buildingIcon,
      angleIcon,
      gridViewIcon,
      truckIcon,
      bundleIcon,
      factoryIcon,
      organizationIcon,
      tasksIcon,
      certificateIcon,
      tagsIcon,
      sunIcon,
      hardDriveDisksIcon,
      twoWayArrowsIcon,
      cursorHandClickIcon,
      clipboardIcon,
      storeIcon,
      eyeIcon,
      briefcaseIcon,
      checkCircleIcon,
      minusIcon,
      playIcon,
      homeIcon,
      pauseIcon,
      checkIcon,
      plusCircleIcon,
      employeeIcon,
      airplaneIcon,
      checkboxListIcon,
      fileIcon,
      paperclipIcon,
      uploadIcon,
      sliderIcon,
      clockIcon,
      detailsIcon,
      calendarIcon,
      dollarIcon,
      employeeGroupIcon,
      dollarBillIcon,
      coinBagIcon,
      refreshIcon,
      historyIcon,
      starIcon,
      hourglassIcon,
      flagIcon,
      ellipsisVerticalIcon,
      bookIcon,
    );
  }
}
