import { ASPNavigationNode } from '../models/general';
import { permissionsMap } from './permissions.constant';
import { ASP_MODULES_NAMES_MAP } from './meta.constant';

export const RANK_GUARD_ENABLED = false;
export const FREE_NAVIGATION = true;
export const FREE_CHECK_WORK_REPORTS = true;
export const FREE_HOLIDAY_READ_WRITE = false;

const { hr: hrPermissions, finance: financePermissions } = permissionsMap;

const {
  employees: HR_employeesP,
  vacationDays: HR_vacationDaysP,
  timeoff: HR_timeoffP,
  shifts: HR_shiftsP,
  departments: HR_departmentsP,
  inOut: HR_inOutP,
  jobTitles: HR_jobTitlesP,
  projects: HR_projectsP,
  organizations: HR_organizationsP,
  suborganizations: HR_suborganizationsP,
  holidays: HR_holidaysP,
  payments: HR_paymentsP,
  attendance: HR_attendanceP,
} = hrPermissions.children!;

export const ASPNavigationTree: ASPNavigationNode[] = [
  {
    text: {
      en: ASP_MODULES_NAMES_MAP.hr.en,
      es: ASP_MODULES_NAMES_MAP.hr.es,
    },
    route: '/hr',
    icon: 'administrator',
    ranks: hrPermissions.ranks,
    freelance: hrPermissions.freelance,
    children: [
      {
        text: {
          en: 'Employees',
          es: 'Empleados',
        },
        route: '/hr/employees',
        icon: 'users',
        ranks: HR_employeesP.ranks,
        freelance: HR_employeesP.freelance,
        id: 'hr',
        children: [
          {
            text: {
              en: 'See all',
              es: 'Ver todos',
            },
            route: '/hr/employees/list',
            icon: 'view-list',
            ranks: HR_employeesP.children!.list.ranks,
            freelance: HR_employeesP.children!.list.freelance,
            id: 'list',
          },
          {
            text: {
              en: 'Add one',
              es: 'Agregar uno',
            },
            route: '/hr/employees/add',
            icon: 'plus',
            ranks: HR_employeesP.children!.add.ranks,
            freelance: HR_employeesP.children!.add.freelance,
            id: 'add',
          },
          // {
          //   text: {
          //     en: 'Add multiple',
          //     es: 'Agregar múltiples',
          //   },
          //   route: '/hr/employees/add-multiple',
          //   icon: 'add-text',
          //   ranks: HR_employeesP.children!.addMultiple.ranks,
          //   freelance: HR_employeesP.children!.addMultiple.freelance,
          //   id: 'addMultiple',
          // },
          {
            text: {
              en: 'Salary Change',
              es: 'Cambiar Sueldo',
            },
            route: '/hr/employees/salary-change',
            icon: 'dollar-bill',
            ranks: HR_employeesP.children!.salaryChange.ranks,
            freelance: HR_employeesP.children!.salaryChange.freelance,
            id: 'salary-change',
          },
          {
            text: {
              en: 'Salary Record',
              es: 'Historial de Sueldos',
            },
            route: '/hr/employees/salary-record',
            icon: 'history',
            ranks: HR_employeesP.children!.salaryRecord.ranks,
            freelance: HR_employeesP.children!.salaryRecord.freelance,
            id: 'salary-record',
          },
        ],
      },
      {
        text: {
          es: 'Títulos de empleo',
          en: 'Job titles',
        },
        route: 'hr/job-titles',
        icon: 'briefcase',
        ranks: HR_jobTitlesP.ranks,
        freelance: HR_jobTitlesP.freelance,
        children: [
          {
            text: {
              en: 'See all',
              es: 'Ver todos',
            },
            route: 'hr/job-titles/all',
            icon: 'view-list',
            ranks: HR_jobTitlesP.children!.list.ranks,
            freelance: HR_jobTitlesP.children!.list.freelance,
          },
          {
            text: {
              en: 'Add',
              es: 'Agregar',
            },
            route: 'hr/job-titles/add',
            icon: 'plus',
            ranks: HR_jobTitlesP.children!.add.ranks,
            freelance: HR_jobTitlesP.children!.add.freelance,
          },
        ],
      },
      {
        text: {
          es: 'Departamentos',
          en: 'Departments',
        },
        route: 'hr/departments',
        icon: 'home',
        ranks: HR_departmentsP.ranks,
        freelance: HR_departmentsP.freelance,
        id: 'departments',
        children: [
          {
            text: {
              en: 'See all',
              es: 'Ver todos',
            },
            route: 'hr/departments',
            icon: 'view-list',
            ranks: HR_departmentsP.children!.list.ranks,
            freelance: HR_departmentsP.children!.list.freelance,
            id: 'list',
          },
          {
            text: {
              en: 'Add',
              es: 'Agregar',
            },
            route: 'hr/departments/add',
            icon: 'plus',
            ranks: HR_departmentsP.children!.add.ranks,
            freelance: HR_departmentsP.children!.add.freelance,
            id: 'add',
          },
          {
            text: {
              en: 'Assing',
              es: 'Asignar',
            },
            route: 'hr/departments/assing-departments',
            icon: 'plus-circle',
            ranks: HR_departmentsP.children!.assign.ranks,
            freelance: HR_departmentsP.children!.assign.freelance,
            id: 'assign',
          },
        ],
      },
      {
        text: {
          en: 'Organizations',
          es: 'Organizaciones',
        },
        route: 'hr/organizations',
        icon: 'building',
        ranks: HR_organizationsP.ranks,
        freelance: HR_organizationsP.freelance,
        children: [
          {
            text: {
              en: 'See all',
              es: 'Ver todas',
            },
            route: 'hr/organizations/all',
            icon: 'view-list',
            ranks: HR_organizationsP.children!.list.ranks,
            freelance: HR_organizationsP.children!.list.freelance,
          },
          {
            text: {
              en: 'Add',
              es: 'Agregar',
            },
            route: 'hr/organizations/add',
            icon: 'plus',
            ranks: HR_organizationsP.children!.add.ranks,
            freelance: HR_organizationsP.children!.add.freelance,
          },
          {
            text: {
              en: 'Owners',
              es: 'Dueños',
            },
            route: 'hr/organizations/organizationsOwners',
            icon: 'user',
            ranks: HR_organizationsP.children!.organizationsOwners.ranks,
            freelance:
              HR_organizationsP.children!.organizationsOwners.freelance,
            children: [
              {
                text: {
                  en: 'Add Owner',
                  es: 'Agregar Dueño de Organización',
                },
                route: 'hr/organizations/organizationsOwners/add',
                icon: 'plus',
                ranks:
                  HR_organizationsP.children!.organizationsOwners.children!.add
                    .ranks,
                freelance:
                  HR_organizationsP.children!.organizationsOwners.children!.add
                    .freelance,
              },
              {
                text: {
                  en: 'See All Owners',
                  es: 'Ver Todos los Dueños de Organizacion',
                },
                route: 'hr/organizations/organizationsOwners/all',
                icon: 'view-list',
                ranks:
                  HR_organizationsP.children!.organizationsOwners.children!.list
                    .ranks,
                freelance:
                  HR_organizationsP.children!.organizationsOwners.children!.list
                    .freelance,
              },
            ],
          },
        ],
      },
      {
        text: {
          en: 'Suborganizations',
          es: 'Suborganizaciones',
        },
        route: 'hr/suborganizations',
        icon: 'store',
        ranks: HR_suborganizationsP.ranks,
        freelance: HR_suborganizationsP.freelance,
        children: [
          {
            text: {
              en: 'See all',
              es: 'Ver todas',
            },
            route: 'hr/suborganizations/all',
            icon: 'view-list',
            ranks: HR_suborganizationsP.children!.list.ranks,
            freelance: HR_suborganizationsP.children!.list.freelance,
          },
          {
            text: {
              en: 'Add',
              es: 'Agregar',
            },
            route: 'hr/suborganizations/add',
            icon: 'plus',
            ranks: HR_suborganizationsP.children!.add.ranks,
            freelance: HR_suborganizationsP.children!.add.freelance,
          },
          {
            text: {
              en: 'Assing',
              es: 'Asignar',
            },
            route: 'hr/suborganizations/assing-suborganizations',
            icon: 'plus-circle',
            ranks: HR_suborganizationsP.children!.assign.ranks,
            freelance: HR_suborganizationsP.children!.assign.freelance,
          },
        ],
      },
    ],
  },
  {
    text: {
      en: ASP_MODULES_NAMES_MAP.inventory.en,
      es: ASP_MODULES_NAMES_MAP.inventory.es,
    },
    route: '/inventory',
    icon: 'grid-view',
    children: [
      {
        text: {
          en: 'Equipment',
          es: 'Equipos físicos',
        },
        route: '/inventory/equipment',
        icon: 'hard-drive-disks',
        children: [
          {
            text: {
              en: 'All',
              es: 'Todos',
            },
            route: '/inventory/equipment',
            icon: 'view-list',
          },
          {
            text: {
              en: 'Create',
              es: 'Crear',
            },
            route: '/inventory/equipment/create',
            icon: 'plus',
          },
          {
            text: {
              en: 'Maintenance Tasks',
              es: 'Tareas de Mantenimiento',
            },
            route: '/inventory/maintenance',
            icon: 'tasks',
          },
          {
            text: {
              en: 'Schedule Maintenance Task',
              es: 'Crear Tarea de Mantenimiento',
            },
            route: '/inventory/maintenance/create',
            icon: 'clipboard',
          },
        ],
      },
    ],
  },
];
