import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(value: any, arg: any): any {
    const resultRoles=[];
    console.log()
    for (const rol of value){
      if(rol.name.indexOf(arg)>-1){
        resultRoles.push(rol)
      };
    };
    return resultRoles;
  }

}
