import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { AuthService } from '../../../services/authentication/auth.service';

@Component({
  selector: 'app-register-form',
  templateUrl: './register-form.component.html',
  styleUrls: ['./register-form.component.scss']
})
export class RegisterFormComponent implements OnInit {

  registerForm: FormGroup | undefined;
  registering = false;
  success = false;
  PASSWORD_MIN_LENGTH = 6;

  constructor(
    private authService: AuthService,
    private fBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    //alert("register")
    this.createRegisterForm();
  }

  checkPasswords: ValidatorFn = (group: AbstractControl):  ValidationErrors | null => { 
    let pass = group.get('password')!.value;
    let confirmPass = group.get('passwordRepeat')!.value
    return pass === confirmPass ? null : { passwordsDontMatch: true }
  }

  /**
 *  Creates an instance for the register form
 */
    createRegisterForm() {
    this.registerForm = this.fBuilder.group({
      email: ['', [Validators.required, Validators.email]], //Email with validations
      password: ['', [Validators.required, Validators.minLength(this.PASSWORD_MIN_LENGTH)]], //Password with validations
      passwordRepeat: ['', [Validators.required, Validators.minLength(this.PASSWORD_MIN_LENGTH)]],
      token: ['', [Validators.required]],
    }, { validators: this.checkPasswords });
  }

  /**
   * Handle a submit of the register form
   */
   async registerHandler() {
    if (this.registerForm) {
      // CALL CF
      this.registering = true;
      try {
        await this.authService.register(this.registerForm.value);
        this.success = true;
      } catch (error: any) {
        alert(error.data.error);
      } finally {
        this.registering = false;
      }
    }
  }
}
