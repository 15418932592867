import { currencyData } from 'src/app/constants/currency.constant';
import { currencyTypes } from 'src/app/models/currency';

export const currencyToNumber = (
  currencyType: currencyTypes,
  currencyNumber: string
) => {
  if (currencyData[currencyType].thousandSeparator === ',') {
    return Number(currencyNumber.replace(/[^0-9.-]+/g, ''));
  } else if (currencyData[currencyType].thousandSeparator === '.') {
    console.log('entreee 2');
    return Number(currencyNumber.replace(/[^0-9,-]+/g, '').replace(',', '.'));
  } else {
    return Number(currencyNumber);
  }
};
