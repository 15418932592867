import { Injectable, Directive } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  CollectionReference,
  DocumentChangeAction,
  DocumentReference,
  Query,
} from '@angular/fire/firestore';
import {
  BehaviorSubject,
  Observable,
  of,
  Subscription,
  combineLatest,
} from 'rxjs';
import {
  Employee,
  EmployeeDebt,
  EmployeeDebtCreate,
  EmployeePaymentInterval,
  SalaryChangeLog,
} from '../models/employee';
import { AngularFireFunctions } from '@angular/fire/functions';
import firestore from 'firebase/app';
import { map, switchMap, take } from 'rxjs/operators';
import { AuthService } from './authentication/auth.service';
import { EmployeeRank } from 'src/app/models/employee';
import {
  EmployeeDayPaymentReport,
  EmployeePeriodPaymentReport,
  EmployeePeriodPaymentReportCreate,
  EmployeePaymentStatus,
  EmployeeSinglePaymentReportCreate,
  EmployeeSinglePaymentReport,
  EmployeeBonusReportCreate,
  EmployeeBonusReport,
  EmployeeProjectHoursReport,
  EmployeeProjectHoursReportCreate,
} from '../models/hr-payment';
import { EmployeeDayPaymentReportCreate } from 'src/app/models/hr-payment';
import { FinanceRequestStatus, FinanceRequestType } from '../models/finance';
import { ASPModule } from '../models/meta';
import {
  EmployeeBonusFinanceRequest,
  EmployeePeriodPaymentFinanceRequest,
  EmployeeProjectHoursPaymentFinanceRequest,
  EmployeeSinglePaymentFinanceRequest,
} from '../models/finance-hr/FinanceHR';
import firebase from 'firebase/app';
import { DatabaseService } from './database.service';
import { flatMap, uniqBy, chunk } from 'lodash-es';
import { DebtUnit } from '../models/debt';

@Injectable({
  providedIn: 'root',
})
export class EmployeesService {
  query: Subscription;
  totalItems: number = 20;
  employees$: Observable<any[]>;
  pageSize: number = 10;
  employeesAlt: BehaviorSubject<Employee[]> = new BehaviorSubject<Employee[]>(
    []
  );

  currentEmployee$: BehaviorSubject<Employee | null> =
    new BehaviorSubject<Employee | null>(null);

  constructor(
    private firestore: AngularFirestore,
    private fns: AngularFireFunctions,
    private authService: AuthService,
    private dbServ: DatabaseService
  ) {
    this.query = this.firestore
      .collection('environments/asp-it-demo/employees', (ref) =>
        ref.limit(15).orderBy('createdAt', 'desc')
      )
      .valueChanges({ idField: 'id' })
      .subscribe((data: any) => {
        this.totalItems = 0;
        const employees = [];
        for (let doc of data) {
          let employee = {
            ...doc,
            createdAt: doc.createdAt ? doc.createdAt.toDate() : 'N/E',
            updatedAt: doc.updatedAt ? doc.updatedAt.toDate() : 'N/E',
            deletedAt: doc.deletedAt ? doc.deletedAt.toDate() : 'N/E',
          };
          employees.push(employee);
        }
        this.totalItems += employees.length;
        this.employeesAlt.next(employees);
      });
    this.employees$ = this.firestore
      .collection('environments/asp-it-demo/employees')
      .valueChanges({ idField: 'id' });

    // current employee subscription
    this.authService._currentUser$
      .pipe(
        switchMap((user) => {
          if (!user) return of(null);

          return this.getEmployeeById(user.employeeId);
        })
      )
      .subscribe((employee) => {
        if (!employee) of(null);
        this.currentEmployee$.next(employee!);
      });
  }

  createEmployeeDebt(debtCreate: EmployeeDebtCreate): Promise<any> {
    const debtDocRef = this.firestore
      .collection('environments/asp-it-demo/employees')
      .doc(debtCreate.employeeId)
      .collection('environments/asp-it-demo/debtReports')
      .doc<EmployeeDebt>().ref;

    return debtDocRef.set({
      ...debtCreate,
      id: debtDocRef.id,
      createdAt: this.dbServ.getServerTimestampSentinel(),
      dealtWithAt: this.dbServ.getServerTimestampSentinel(),
      deleted: false,
    });
  }

  getEmployeeActiveDebtReports(
    empId: string,
    unit?: DebtUnit
  ): Observable<EmployeeDebt[]> {
    return this.firestore
      .collection('environments/asp-it-demo/employees')
      .doc(empId)
      .collection<EmployeeDebt>(
        'environments/asp-it-demo/debtReports',
        (ref) => {
          let query = ref
            .where('dealtWith', '==', false)
            .where('deleted', '==', false);

          if (unit) {
            query = query.where('unit', '==', unit);
          }

          return query;
        }
      )
      .valueChanges({ idField: 'id' });
  }

  getPeriodPaymentDayReport(
    empId: string,
    year: number,
    month: number,
    day: number
  ): Promise<EmployeeDayPaymentReport | null> {
    return this.firestore
      .collection('environments/asp-it-demo/employees')
      .doc(empId)
      .collection<EmployeeDayPaymentReport>(
        'environments/asp-it-demo/dayPaymentReports',
        (ref) => {
          return ref
            .where('year', '==', year)
            .where('month', '==', month)
            .where('day', '==', day)
            .where('status', '==', EmployeePaymentStatus.waiting);
        }
      )
      .valueChanges({ idField: 'id' })
      .pipe(
        map((reports) => reports[0] || null),
        take(1)
      )
      .toPromise();
  }

  listenEmployees(newTotal: number) {
    this.query.unsubscribe();
    console.log('Limit ', newTotal);
    this.query = this.firestore
      .collection('environments/asp-it-demo/employees', (ref) =>
        ref.limit(newTotal).orderBy('createdAt', 'desc')
      )
      .valueChanges({ idField: 'id' })
      .subscribe((data: any) => {
        const employees = [];
        for (let doc of data) {
          let employee = {
            ...doc,
            createdAt: doc.createdAt ? doc.createdAt.toDate() : 'N/E',
            updatedAt: doc.updatedAt ? doc.updatedAt.toDate() : 'N/E',
            deletedAt: doc.deletedAt ? doc.deletedAt.toDate() : 'N/E',
          };
          employees.push(employee);
        }
        console.log('Employees', employees);
        this.totalItems = employees.length;
        this.employeesAlt.next(employees);
      });
  }

  getEmployeeByCode(code: string): Observable<Employee | null> {
    return this.firestore
      .collection<Employee>('environments/asp-it-demo/employees', (ref) =>
        ref.where('code', '==', code)
      )
      .valueChanges({ idField: 'id' })
      .pipe(map((employees) => employees[0] || null));
  }

  getNextPage(total: number) {
    console.log('total: ', total);
    console.log('newTotal: ', this.totalItems);
    if (total >= this.totalItems && total + 10 > 0) {
      console.log('Query', total + 10);
      this.listenEmployees(total + 10);
    }
  }

  generateEmployeeCode(): string {
    return Math.floor(100_000 + Math.random() * 900_000).toString();
  }

  changePageSize(newPageSize: number) {
    return (this.pageSize = newPageSize);
  }

  getEmployeeProperties(): string[] {
    return [
      'id',
      'contextId',
      'publicName',
      'fiscalName',
      'fiscalId',
      'ownerId',
      'planId',
      'contractStart',
      'contractEnd',
      'availableEntities',
      'consumedEntities',
      'deleted',
      'createdAt',
      'updatedAt',
      'deletedAt',
    ];
  }

  getEmployees(): Observable<Employee[]> {
    return this.employees$;
  }

  getEmployeesAlt(): Observable<Employee[]> {
    return this.employeesAlt;
  }

  getEmployee(id: string) {
    return this.firestore
      .collection<Employee>('environments/asp-it-demo/employees')
      .doc(id)
      .valueChanges({ idField: 'id' });
  }

  getEmployeeById(id: string): Observable<Employee | undefined> {
    return this.firestore
      .collection<Employee>('environments/asp-it-demo/employees')
      .doc<Employee>(id)
      .valueChanges({ idField: 'id' });
  }

  getEmployeeOnce(id: string) {
    return this.firestore
      .collection<Employee>('environments/asp-it-demo/employees')
      .doc(id)
      .ref.get()
      .then((employee) => {
        return { id: id, ...employee.data() } as Employee;
      });
  }

  getEmployeeByEmail(email: string): Observable<Employee> {
    return this.firestore
      .collection<Employee>('environments/asp-it-demo/employees', (ref) =>
        ref.where('email', '==', email)
      )
      .valueChanges({ idField: 'id' })
      .pipe(map((employees) => employees[0] || null));
  }

  getEmployeesM() {
    // return this.firestore.collection('roles').snapshotChanges().subscribe(r=>r.map(w=>w.payload.doc.id))
    return this.firestore
      .collection('environments/asp-it-demo/employees')
      .snapshotChanges();
  }

  getEmployeesForPeriodPaymentList(
    organizationId: string,
    currentRank: EmployeeRank
  ): Observable<Employee[]> {
    const getQueryRef = (
      ref: CollectionReference<firebase.firestore.DocumentData>,
      paymentInterval: EmployeePaymentInterval
    ) => {
      let query: firestore.firestore.Query<firestore.firestore.DocumentData> =
        ref
          .where('companyId', '==', organizationId)
          .where('paymentInterval', '==', paymentInterval);

      if (currentRank === EmployeeRank.supervisor) {
        query = query.where('rank', '==', 'employee');
      }

      return query;
    };

    const monthlyEmpsQuery = this.firestore.collection<Employee>(
      'environments/asp-it-demo/employees',
      (ref) => getQueryRef(ref, EmployeePaymentInterval.monthly)
    );
    const bimonthlyEmpsQuery = this.firestore.collection<Employee>(
      'environments/asp-it-demo/employees',
      (ref) => getQueryRef(ref, EmployeePaymentInterval.bimonthly)
    );

    return combineLatest([
      monthlyEmpsQuery.valueChanges({ idField: 'id' }),
      bimonthlyEmpsQuery.valueChanges({ idField: 'id' }),
    ]).pipe(
      switchMap(([monthlyEmps, bimonthlyEmpsQuery]) => {
        return of([...monthlyEmps, ...bimonthlyEmpsQuery]);
      })
    );
  }

  getSinglePaymentEmployees(orgId: string): Observable<Employee[]> {
    return this.firestore
      .collection<Employee>('environments/asp-it-demo/employees', (ref) =>
        ref
          .where('paymentInterval', '==', EmployeePaymentInterval.single)
          .where('companyId', '==', orgId)
      )
      .valueChanges({ idField: 'id' });
  }

  getEmployeesByOrganizationId(organizationId: string): Observable<Employee[]> {
    return this.firestore
      .collection<Employee>('environments/asp-it-demo/employees', (ref) =>
        ref
          .where('companyId', '==', organizationId)
          .where('deleted', '==', false)
      )
      .valueChanges({ idField: 'id' });
  }

  setEmployee(id: string, data: any) {
    this.firestore
      .collection('environments/asp-it-demo/employees')
      .doc(id)
      .set(data);
  }

  updateEmployee(id: string, data: Partial<Employee>): Promise<any> {
    return this.firestore
      .collection<Employee>('environments/asp-it-demo/employees')
      .doc(id)
      .update(data);
  }

  getEmployeesWithShifts(shiftsIds: string[]): Observable<Employee[]> {
    const ARRAY_CONTAINS_QUERY_MAX_LENGTH = 10;
    const shiftsIdsGroups = chunk<string>(
      shiftsIds,
      ARRAY_CONTAINS_QUERY_MAX_LENGTH
    );
    const queries: Observable<Employee[]>[] = [];

    for (let i = 0; i < shiftsIdsGroups.length; i++) {
      const query: Observable<Employee[]> = this.firestore
        .collection<Employee>('environments/asp-it-demo/employees', (ref) => {
          return ref.where(
            'shiftsIds',
            'array-contains-any',
            shiftsIdsGroups[i]
          );
        })
        .valueChanges({ idField: 'id' });

      queries.push(query);
    }

    return combineLatest(queries).pipe(
      map((employeesLists) => uniqBy(flatMap(employeesLists), 'id'))
    );
  }

  payEmployeeProjectHours(
    reportCreate: EmployeeProjectHoursReportCreate
  ): Promise<any> {
    const ts = this.dbServ.getServerTimestampSentinel();

    const { employeeId, organizationId, suborganizationId } = reportCreate;

    const projectHoursReport: EmployeeProjectHoursReport = {
      ...reportCreate,
      id: this.firestore.createId(),
      createdAt: ts,
      status: EmployeePaymentStatus.waiting,
    };

    if (suborganizationId)
      projectHoursReport.suborganizationId = suborganizationId;

    const projectHoursReportRef = this.firestore
      .collection('environments/asp-it-demo/employees')
      .doc(employeeId)
      .collection('environments/asp-it-demo/paymentReports')
      .doc(projectHoursReport.id).ref;

    const projectHoursFinanceReq: EmployeeProjectHoursPaymentFinanceRequest = {
      amount: projectHoursReport.amount,
      createdAt: ts,
      employeeId,
      id: this.firestore.createId(),
      module: ASPModule.hr,
      organizationId,
      reportId: projectHoursReportRef.id,
      status: FinanceRequestStatus.waiting,
      type: FinanceRequestType.employee_project_hours_payment,
    };

    if (suborganizationId)
      projectHoursFinanceReq.suborganizationId = suborganizationId;

    const projectHoursFinanceReqRef = this.firestore
      .collection('environments/asp-it-demo/organizations')
      .doc(organizationId)
      .collection('environments/asp-it-demo/financeRequests')
      .doc(projectHoursFinanceReq.id).ref;

    const batch = this.firestore.firestore.batch();

    batch.set(projectHoursReportRef, projectHoursReport);
    batch.set(projectHoursFinanceReqRef, projectHoursFinanceReq);

    for (const wrId of reportCreate.workReportsIds) {
      const wrRef = this.firestore
        .collection('environments/asp-it-demo/projects')
        .doc(reportCreate.projectId)
        .collection('environments/asp-it-demo/workReports')
        .doc(wrId).ref;

      batch.update(wrRef, { paymentRequested: true });
    }

    return batch.commit();
  }

  payEmployeeBonus(bonusReportCreate: EmployeeBonusReportCreate): Promise<any> {
    const timestampSentinel = this.dbServ.getServerTimestampSentinel();

    const { employeeId, organizationId, suborganizationId } = bonusReportCreate;

    const bonusReport: EmployeeBonusReport = {
      ...bonusReportCreate,
      id: this.firestore.createId(),
      createdAt: timestampSentinel,
      status: EmployeePaymentStatus.waiting,
      amount: bonusReportCreate.amount,
      employeeId: employeeId,
      organizationId,
    };

    if (suborganizationId) bonusReport.suborganizationId = suborganizationId;

    const bonusReportRef = this.firestore
      .collection('environments/asp-it-demo/employees')
      .doc(employeeId)
      .collection('environments/asp-it-demo/paymentReports')
      .doc(bonusReport.id).ref;

    const bonusFinanceRequest: EmployeeBonusFinanceRequest = {
      amount: bonusReportCreate.amount,
      createdAt: timestampSentinel,
      employeeId: bonusReportCreate.employeeId,
      id: this.firestore.createId(),
      module: ASPModule.hr,
      organizationId,
      suborganizationId: bonusReportCreate.suborganizationId || null,
      reportId: bonusReport.id,
      status: FinanceRequestStatus.waiting,
      type: FinanceRequestType.employee_bonus,
      comment: bonusReport.comment || '',
    };

    const bonusFinanceRequestRef = this.firestore
      .collection('environments/asp-it-demo/organizations')
      .doc(organizationId)
      .collection('environments/asp-it-demo/financeRequests')
      .doc(bonusFinanceRequest.id).ref;

    const batch = this.firestore.firestore.batch();

    batch.set(bonusReportRef, bonusReport);
    batch.set(bonusFinanceRequestRef, bonusFinanceRequest);

    return batch.commit();
  }

  payEmployeeSinglePayment(
    singlePaymentReportCreate: EmployeeSinglePaymentReportCreate
  ): Promise<any> {
    const timestampSentinel = firestore.firestore.FieldValue.serverTimestamp();

    const { employeeId, organizationId, suborganizationId } =
      singlePaymentReportCreate;

    const batch = this.firestore.firestore.batch();

    const employeeRef = this.firestore
      .collection('environments/asp-it-demo/employees')
      .doc(employeeId).ref;
    batch.update(employeeRef, {
      singlePaymentRequestedAt: timestampSentinel,
    });

    const singlePaymentReport: EmployeeSinglePaymentReport = {
      ...singlePaymentReportCreate,
      createdAt: timestampSentinel,
      id: this.firestore.createId(),
      status: EmployeePaymentStatus.waiting,
    };

    const singlePaymentReportRef = this.firestore
      .collection('environments/asp-it-demo/employees')
      .doc(employeeId)
      .collection('environments/asp-it-demo/paymentReports')
      .doc(singlePaymentReport.id).ref;

    batch.set(singlePaymentReportRef, singlePaymentReport);

    const financeRequestRef = this.firestore
      .collection('environments/asp-it-demo/organizations')
      .doc(organizationId)
      .collection('environments/asp-it-demo/financeRequests')
      .doc(this.firestore.createId()).ref;

    const financeRequest: EmployeeSinglePaymentFinanceRequest = {
      type: FinanceRequestType.employee_single_payment,
      reportId: singlePaymentReport.id,
      id: financeRequestRef.id,
      createdAt: timestampSentinel,
      organizationId,
      suborganizationId: suborganizationId || null,
      amount: singlePaymentReport.amount,
      status: FinanceRequestStatus.waiting,
      module: ASPModule.hr,
      employeeId,
    };

    batch.set(financeRequestRef, financeRequest);

    return batch.commit();
  }

  payEmployeePeriod(
    paymentReportCreate: EmployeePeriodPaymentReportCreate,
    daysReportsCreate: EmployeeDayPaymentReportCreate[]
  ) {
    const timestampSentinel = firestore.firestore.FieldValue.serverTimestamp();

    const { employeeId, organizationId, suborganizationId, debtsIds } =
      paymentReportCreate;

    const batch = this.firestore.firestore.batch();

    const endDateNext: Date = (function () {
      const date = new Date(paymentReportCreate.endDate as Date);
      date.setDate(date.getDate() + 1);
      return date;
    })();

    const employeeRef = this.firestore
      .collection('environments/asp-it-demo/employees')
      .doc(employeeId).ref;
    batch.update(employeeRef, {
      paymentRequestedUntil:
        firestore.firestore.Timestamp.fromDate(endDateNext),
    });

    const paymentReport: EmployeePeriodPaymentReport = {
      ...paymentReportCreate,
      createdAt: timestampSentinel,
      id: this.firestore.createId(),
      status: EmployeePaymentStatus.waiting,
    };

    const employeePaymentReportRef = this.firestore
      .collection('environments/asp-it-demo/employees')
      .doc(employeeId)
      .collection('environments/asp-it-demo/paymentReports')
      .doc(paymentReport.id).ref;

    batch.set(employeePaymentReportRef, paymentReport);

    for (let dayReportCreate of daysReportsCreate) {
      const docId = this.firestore.createId();

      const dayReport: EmployeeDayPaymentReport = {
        ...dayReportCreate,
        createdAt: timestampSentinel,
        reportId: paymentReport.id,
        status: EmployeePaymentStatus.waiting,
      };

      const employeeDayPaymentReportRef = this.firestore
        .collection('environments/asp-it-demo/employees')
        .doc(employeeId)
        .collection('environments/asp-it-demo/dayPaymentReports')
        .doc(docId).ref;

      batch.set(employeeDayPaymentReportRef, dayReport);
    }

    for (const debtId of debtsIds) {
      const debtDocRef = this.firestore
        .collection('environments/asp-it-demo/employees')
        .doc(employeeId)
        .collection('environments/asp-it-demo/debtReports')
        .doc(debtId).ref;

      batch.update(debtDocRef, {
        dealtWith: true,
        dealtWithAt: timestampSentinel,
      });
    }

    const financeRequestRef = this.firestore
      .collection('environments/asp-it-demo/organizations')
      .doc(organizationId)
      .collection('environments/asp-it-demo/financeRequests')
      .doc(this.firestore.createId()).ref;

    const financeRequest: EmployeePeriodPaymentFinanceRequest = {
      type: FinanceRequestType.employee_period_payment,
      reportId: paymentReport.id,
      id: financeRequestRef.id,
      createdAt: timestampSentinel,
      organizationId,
      suborganizationId: suborganizationId || null,
      amount: paymentReport.payable,
      status: FinanceRequestStatus.waiting,
      module: ASPModule.hr,
      employeeId,
    };

    batch.set(financeRequestRef, financeRequest);

    return batch.commit();
  }

  async addEmployee(payload: Employee): Promise<DocumentReference<unknown>> {
    //return this.firestore.collection('testDocuments').add({...payload, createdAt: new Date(), updatedAt: new Date(), deleted: false})//.valueChanges()
    return this.firestore
      .collection<Employee>('environments/asp-it-demo/employees')
      .add({
        ...payload,
        departmentsIds: [],
        shiftsIds: [],
        lastPayedAt: firestore.firestore.FieldValue.serverTimestamp(),
        paymentRequestedUntil: firestore.firestore.FieldValue.serverTimestamp(),
        createdAt: firestore.firestore.FieldValue.serverTimestamp(),
        updatedAt: firestore.firestore.FieldValue.serverTimestamp(),
      });
    //.valueChanges()
  }

  async addManyEmployees(payloads: any): Promise<any> {
    const batch = this.firestore.firestore.batch();
    for (const payload of payloads) {
      batch.set(
        this.firestore.collection('environments/asp-it-demo/employees').doc()
          .ref,
        {
          ...payload,
          createdAt: new Date(),
          updatedAt: new Date(),
          deleted: false,
        }
      );
    }
    //return this.firestore.collection('testDocuments').add({...payload, createdAt: new Date(), updatedAt: new Date(), deleted: false})//.valueChanges()
    return batch.commit();
  }

  validateEmployeesList(employeesList: any[]) {
    const callable = this.fns.httpsCallable('validateEmployeesList');
    const result = callable({
      organizationId: 'organizationId',
      employees: employeesList,
    });
    return result;
  }

  unassignTask(employeeId: string, taskId: string) {
    this.firestore
      .collection('environments/asp-it-demo/tasks')
      .doc(taskId)
      .update({
        employeeIds: firestore.firestore.FieldValue.arrayRemove(employeeId),
      });
  }

  assignTask(employeeId: string, taskId: string) {
    this.firestore
      .collection('environments/asp-it-demo/tasks')
      .doc(taskId)
      .update({
        employeeIds: firestore.firestore.FieldValue.arrayUnion(employeeId),
      });
  }

  assignManyTasks(tasks: any) {
    const batch = this.firestore.firestore.batch();
    for (let task of tasks) {
      let employeeId = task.employeeId;
      delete task.employeeId;
      batch.update(
        this.firestore.collection('environments/asp-it-demo/tasks').doc(task.id)
          .ref,
        {
          ...task,
          employeeIds: firestore.firestore.FieldValue.arrayUnion(employeeId),
        }
      );
    }
    return batch.commit();
  }

  /**USAGE ON INVENTORY, TODO: INTEGRATE WITH OTHER FUNCTIONS TO AVOID REPEATING CODE */
  getActiveEmployees() {
    return this.firestore
      .collection<Employee>('environments/asp-it-demo/employees', (ref) =>
        ref.where('deleted', '==', false)
      )
      .valueChanges({ idField: 'id' });
  }

  getSalaryChangeLogs(
    empId: string,
    from?: Date,
    to?: Date
  ): Observable<SalaryChangeLog[]> {
    return this.firestore
      .collection('environments/asp-it-demo/employees')
      .doc(empId)
      .collection<SalaryChangeLog>(
        'environments/asp-it-demo/salaryChangesLogs',
        (ref) => {
          let query: CollectionReference | Query = ref;
          if (from) {
            query = query.where('createdAt', '>=', from);
          }
          if (to) {
            query = query.where('createdAt', '<=', to);
          }

          query.orderBy('createdAt', 'asc');
          return query;
        }
      )
      .valueChanges();
  }
}
