<div class="alert-container">
  <clr-modal [(clrModalOpen)]="showAlert" [clrModalClosable]="false">
    <div class="modal-body">
      <div class="alert {{ getAlertType() }}" role="alert">
        <div class="alert-items">
          <div class="alert-item static">
            <div class="alert-icon-wrapper">
              <cds-icon
                class="alert-icon"
                shape="exclamation-circle"
                *ngIf="type == 'danger'"
              ></cds-icon>
              <cds-icon
                class="alert-icon"
                shape="info-circle"
                *ngIf="type == 'info'"
              ></cds-icon>
              <cds-icon
                class="alert-icon"
                shape="check-circle"
                *ngIf="type == 'success'"
              ></cds-icon>
              <cds-icon
                class="alert-icon"
                shape="exclamation-triangle"
                *ngIf="type == 'warning'"
              ></cds-icon>
            </div>
            <span class="alert-text">
              {{text}}
            </span>
          </div>
        </div>
      </div>
      <div class="progress {{type}}">
        <progress max="100" value="{{progress}}" data-displayval="0%"></progress>
      </div>
    </div>
  </clr-modal>
</div>
