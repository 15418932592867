import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import firebase from 'firebase/app';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ASPDocument } from '../models/ASPDocument';
import { ServerTimeDoc } from '../models/general';
import { AuthService } from './authentication/auth.service';

@Injectable({
  providedIn: 'root',
})
export class DatabaseService {
  item$: Observable<any[]>;
  constructor(
    private firestore: AngularFirestore,
    private fns: AngularFireFunctions,
    private authService: AuthService
  ) {
    try {
      this.item$ = this.firestore
        .collection('environments/asp-it-demo/organizations')
        .valueChanges();
    } catch (err) {
      this.item$ = new Observable();
      console.error(err);
    }
  }

  getServerTimestampSentinel(): firebase.firestore.FieldValue {
    return firebase.firestore.FieldValue.serverTimestamp();
  }

  async getServerTime(): Promise<Date | null> {
    const docRef =
      this.firestore.doc<ServerTimeDoc<firebase.firestore.Timestamp>>(
        'aux/serverTime'
      );

    try {
      await docRef.set({
        time: firebase.firestore.FieldValue.serverTimestamp() as any,
      });

      return this.firestore
        .doc<ServerTimeDoc<firebase.firestore.Timestamp>>('aux/serverTime')
        .valueChanges()
        .pipe(
          take(1),
          map((doc) => doc?.time?.toDate() ?? null)
        )
        .toPromise();
    } catch (error) {
      return Promise.resolve(null);
    }
  }

  getItems(): Observable<any[]> {
    return this.item$;
  }

  getDocument(path: string, documentId: string): Observable<any> {
    return this.firestore.collection(path).doc(documentId).get();
  }

  public createWithTransaction(
    path: string,
    payload: any,
    t: firebase.firestore.Transaction
  ): firebase.firestore.Transaction {
    t.set(
      this.firestore.doc(path).ref,
      {
        ownerId: this.authService.getUID(),
        organizationId: this.authService.getCurrentUser()?.organizationId,
        suborganizationId: '', //TODO: What to do
        allowedContexts: [], //TODO: Look at what to do
        context: this.authService.getCurrentUser()?.contextId,
        ...payload,
      },
      {
        merge: true,
      }
    );

    return t;
  }

  public createWithBatch(
    path: string,
    payload: any,
    batch: firebase.firestore.WriteBatch
  ): firebase.firestore.WriteBatch {
    batch.set(
      this.firestore.doc(path).ref,
      {
        ownerId: this.authService.getUID(),
        organizationId: this.authService.getCurrentUser()?.organizationId,
        suborganizationId: '', //TODO: What to do
        allowedContexts: [], //TODO: Look at what to do
        context: this.authService.getCurrentUser()?.contextId,
        ...payload,
      },
      {
        merge: true,
      }
    );

    return batch;
  }

  public create(path: string, payload: any) {
    return this.firestore.collection(path).add({
      ownerId: this.authService.getUID(),
      organizationId: this.authService.getCurrentUser()?.organizationId,
      suborganizationId: '', //TODO: What to do
      allowedContexts: [], //TODO: Look at what to do
      context: this.authService.getCurrentUser()?.contextId,
      ...payload,
    } as ASPDocument);
  }
}
