import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'aspDgFn'
})
export class AspDgFnPipe implements PipeTransform {

  transform(fn: (_: any) => string | number, data: any): string | number {
    return fn(data);
  }

}
