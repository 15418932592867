import {
  nullSafeIsEquivalent,
  ThrowStmt,
} from '@angular/compiler/src/output/output_ast';
import { Component, Input, OnInit, Output, EventEmitter, OnChanges} from '@angular/core';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss'],
})
export class AlertsComponent implements OnInit, OnChanges {

  @Input('id') id: string = '';
  @Input('alertMessage') alertMessage: string = '';
  @Input('alertType') alertType: string = '';
  @Input('showAlert') showAlert: boolean = false;
  @Output() setShow =  new EventEmitter<boolean>();

  alertStyle = "position: absolute; right: 5px; bottom: 5px; z-index: 1000; width: 25%"
  
  constructor() {
    console.log("0. Esta en : ", this.showAlert)
  }

  cerrar() {
    this.showAlert = false;
    this.setShow.emit(false)
    // var elem = document.getElementById(this.id);
    // if (elem !== null) {
    //   elem.style.display = 'none';
    // }
  }
  
  ngOnInit(): void {
    console.log("1. Esta en : ", this.showAlert)
    // setTimeout(() => {
    //   var elem = document.getElementById('alerta');
    //   if (elem !== null) {
    //     elem.style.display = 'none';
    //   }
    // }, 5000);
  }

  ngOnChanges(): void {
    console.log("Changes")
    setTimeout(() => {
      this.showAlert = false;
      this.setShow.emit(false)
    }, 10000);
  }

}
