import { PermissionsMap } from 'src/app/models/general';
import { EmployeeRank } from 'src/app/models/employee';

const superadmin = EmployeeRank.superadmin;
const admin = EmployeeRank.admin;
const supervisor = EmployeeRank.supervisor;
const employee = EmployeeRank.employee;

export const permissionsMap: PermissionsMap = {
  hr: {
    freelance: null,
    ranks: [admin, supervisor, employee],
    children: {
      employees: {
        freelance: false,
        ranks: [admin, supervisor],
        children: {
          list: {
            ranks: [admin, supervisor],
            freelance: false,
          },
          add: {
            ranks: [admin, supervisor],
            freelance: false,
          },
          addMultiple: {
            ranks: [admin, supervisor],
            freelance: false,
          },
          salaryChange: {
            ranks: [admin, supervisor],
            freelance: false,
          },
          salaryRecord: {
            ranks: [admin, supervisor],
            freelance: false,
          },
        },
      },
      vacationDays: {
        ranks: [admin, supervisor, employee],
        freelance: false,
        children: {
          createRequest: {
            ranks: [supervisor, employee],
            freelance: false,
          },
          requests: {
            ranks: [admin, supervisor],
            freelance: false,
          },
          myRequests: {
            ranks: [supervisor, employee],
            freelance: false,
          },
          manage: {
            ranks: [admin, supervisor],
            freelance: false,
          },
        },
      },
      timeoff: {
        ranks: [admin, supervisor, employee],
        freelance: false,
        children: {
          list: {
            ranks: [admin, supervisor],
            freelance: false,
          },
          add: {
            ranks: [supervisor, employee],
            freelance: false,
          },
          myTimeoff: {
            ranks: [supervisor, employee],
            freelance: false,
          },
        },
      },
      shifts: {
        ranks: [admin, supervisor, employee],
        freelance: false,
        children: {
          list: {
            ranks: [admin, supervisor],
            freelance: false,
          },
          myShifts: {
            ranks: [admin, supervisor, employee],
            freelance: false,
          },
          add: {
            ranks: [admin, supervisor],
            freelance: false,
          },
          detail: {
            ranks: [admin, supervisor, employee],
            freelance: false,
          },
          assign: {
            ranks: [admin, supervisor],
            freelance: false,
          },
        },
      },
      departments: {
        ranks: [admin, supervisor],
        freelance: false,
        children: {
          add: {
            ranks: [admin, supervisor],
            freelance: false,
          },
          list: {
            ranks: [admin, supervisor],
            freelance: false,
          },
          assign: {
            ranks: [admin, supervisor],
            freelance: false,
          },
        },
      },
      inOut: {
        ranks: [admin, supervisor, employee],
        freelance: false,
        children: {
          clockInOut: {
            ranks: [supervisor, employee],
            freelance: false,
          },
          inOutReports: {
            ranks: [admin, supervisor],
            freelance: false,
          },
        },
      },
      jobTitles: {
        ranks: [admin, supervisor],
        freelance: false,
        children: {
          list: {
            ranks: [admin, supervisor],
            freelance: false,
          },
          add: {
            ranks: [admin, supervisor],
            freelance: false,
          },
        },
      },
      projects: {
        freelance: null,
        ranks: [admin, supervisor, employee],
        children: {
          list: {
            ranks: [admin, supervisor],
            freelance: false,
          },
          add: {
            ranks: [admin, supervisor],
            freelance: false,
          },
          workReports: {
            ranks: [admin, supervisor, employee],
            freelance: true,
          },
          myProjects: {
            ranks: [admin, supervisor, employee],
            freelance: null,
          },
        },
      },
      holidays: {
        ranks: [admin, supervisor, employee],
        freelance: null,
        children: {
          list: {
            ranks: [admin, supervisor, employee],
            freelance: null,
          },
          add: {
            ranks: [admin],
            freelance: false,
          },
        },
      },
      organizations: {
        ranks: [admin],
        freelance: false,
        children: {
          list: {
            ranks: [admin],
            freelance: false,
          },
          add: {
            ranks: [admin],
            freelance: false,
          },
          organizationsOwners: {
            ranks: [admin],
            freelance: false,
            children: {
              add: {
                ranks: [admin],
                freelance: false,
              },
              list: {
                ranks: [admin],
                freelance: false,
              },
            },
          },
        },
      },
      suborganizations: {
        ranks: [admin],
        freelance: false,
        children: {
          list: {
            ranks: [admin],
            freelance: false,
          },
          add: {
            ranks: [admin],
            freelance: false,
          },
          assign: {
            ranks: [admin],
            freelance: false,
          },
        },
      },
      payments: {
        ranks: [admin, supervisor, employee],
        freelance: null,
        children: {
          employeesList: {
            ranks: [admin, supervisor],
            freelance: false,
          },
          employeePaymentCreate: {
            ranks: [admin, supervisor],
            freelance: false,
          },
          singlePaymentList: {
            ranks: [admin, supervisor],
            freelance: false,
          },
          singlePaymentCreate: {
            ranks: [admin, supervisor],
            freelance: false,
          },
          bonusPayment: {
            ranks: [admin, supervisor],
            freelance: false,
          },
          projectHoursPayment: {
            ranks: [admin, supervisor],
            freelance: false,
          },
        },
      },
      attendance: {
        ranks: [admin, supervisor],
        freelance: null,
        children: {
          manage: {
            ranks: [admin, supervisor],
            freelance: false,
          },
        },
      },
    },
  },
  inventory: {
    freelance: null,
    ranks: [admin, supervisor, employee],
    children: {
      locations: {
        // inventory/locations and inventory/locations/:id
        freelance: false,
        ranks: [admin, supervisor, employee],
        children: {
          create: {
            // inventory/locations/create
            freelance: false,
            ranks: [admin, supervisor],
          },
          edit: {
            // inventory/locations/edit/:id
            freelance: false,
            ranks: [admin, supervisor],
          },
          layout: {
            // inventory/locations/:id/layout
            freelance: false,
            ranks: [admin, supervisor, employee],
          },
          sectors: {
            // inventory/locations/:id/sectors/:id
            freelance: false,
            ranks: [admin, supervisor, employee],
            children: {
              create: {
                // inventory/locations/:id/sectors/create
                freelance: false,
                ranks: [admin, supervisor],
              },
              edit: {
                // inventory/locations/:id/sectors/edit/:id
                freelance: false,
                ranks: [admin, supervisor],
              },
              layout: {
                // inventory/locations/:id/sectors/:id/layout
                freelance: false,
                ranks: [admin, supervisor, employee],
              },
            },
          },
        },
      },
      equipment: {
        // inventory/equipment and inventory/equipment/:id
        freelance: false,
        ranks: [admin, supervisor, employee],
        children: {
          create: {
            // inventory/equipment/create
            freelance: false,
            ranks: [admin, supervisor],
          },
          edit: {
            // inventory/equipment/edit/:id
            freelance: false,
            ranks: [admin, supervisor],
          },
          transfer: {
            // inventory/equipment/transfer/:id
            freelance: false,
            ranks: [admin, supervisor],
          },
          assignment: {
            // inventory/equipment/assignment/:id
            freelance: false,
            ranks: [admin, supervisor],
          },
        },
      },
      providers: {
        // inventory/providers and inventory/providers/:id
        freelance: false,
        ranks: [admin, supervisor, employee],
        children: {
          create: {
            // inventory/providers/create
            freelance: false,
            ranks: [admin, supervisor],
          },
          edit: {
            // inventory/providers/edit/:id
            freelance: false,
            ranks: [admin, supervisor],
          },
        },
      },
      elements: {
        // inventory/elements and inventory/elements/:id
        freelance: false,
        ranks: [admin, supervisor, employee],
        children: {
          create: {
            // inventory/elements/create
            freelance: false,
            ranks: [admin, supervisor],
          },
          edit: {
            // inventory/elements/edit/:id
            freelance: false,
            ranks: [admin, supervisor],
          },
        },
      },
      transactions: {
        // inventory/transactions and inventory/transactions/:id
        freelance: false,
        ranks: [admin, supervisor, employee],
        children: {
          create: {
            // inventory/transactions/create and inventory/transactions/create/:id
            freelance: false,
            ranks: [admin, supervisor],
          },
          edit: {
            // inventory/transactions/edit/:id
            freelance: false,
            ranks: [admin, supervisor],
          },
        },
      },
      batches: {
        // inventory/batches and inventory/batches/:id
        freelance: false,
        ranks: [admin, supervisor, employee],
      },
      boms: {
        // inventory/boms and inventory/boms/:id
        freelance: false,
        ranks: [admin, supervisor, employee],
        children: {
          create: {
            // inventory/boms/create
            freelance: false,
            ranks: [admin, supervisor],
          },
          edit: {
            // inventory/boms/edit/:id
            freelance: false,
            ranks: [admin, supervisor],
          },
        },
      },
      orders: {
        // inventory/orders and inventory/orders/:id
        freelance: false,
        ranks: [admin, supervisor, employee],
        children: {
          create: {
            // inventory/orders/create
            freelance: false,
            ranks: [admin, supervisor],
          },
        },
      },
      maintenance: {
        // inventory/maintenance and inventory/maintenance/:id
        freelance: false,
        ranks: [admin, supervisor, employee],
        children: {
          create: {
            // inventory/maintenance/create
            freelance: false,
            ranks: [admin, supervisor],
          },
          edit: {
            // inventory/maintenance/edit/:id
            freelance: false,
            ranks: [admin, supervisor],
          },
        },
      },
      payments: {
        //inventory/payments
        freelance: false,
        ranks: [],
        children: {
          orders: {
            //inventory/payments/orders
            freelance: false,
            ranks: [admin, supervisor],
          },
          maintenance: {
            //inventory/payments/maintenance
            freelance: false,
            ranks: [admin, supervisor],
          },
          batchStorage: {
            //inventory/payments/batch-storage
            freelance: false,
            ranks: [admin, supervisor],
          },
          equipmentTransfer: {
            //inventory/payments/equipment-transfer
            freelance: false,
            ranks: [admin, supervisor],
          },
        },
      },
    },
  },
  finance: {
    freelance: false,
    ranks: [admin],
    children: {
      requests: {
        freelance: false,
        ranks: [admin],
      },
      invoicing: {
        freelance: false,
        ranks: [admin],
        children: {
          createInvoice: {
            freelance: false,
            ranks: [admin],
          }
        }
      },
    },
  },
};

// export const permissionsMap2: ASPNavigationManifest = {
//     hr: {
//         text: {
//             en: 'Human Resources',
//             es: 'Recursos Humanos',
//         },
//         route: '/hr',
//         icon: 'administrator',
//         freelance: true,
//         ranks: [admin, supervisor, employee],
//         children: {
//             employees: {
//                 text: {
//                     en: 'Employees',
//                     es: 'Empleados',
//                 },
//                 route: '/hr/employees',
//                 icon: 'users',
//                 freelance: true,
//                 ranks: [admin, supervisor],
//                 children: {
//                     list: {
//                         text: {
//                             en: 'See all',
//                             es: 'Ver todos',
//                           },
//                         route: '/hr/employees/list',
//                         icon: 'view-list',
//                         ranks: [admin, supervisor],
//                         freelance: false,
//                     },
//                     add: {
//                         text: {
//                             en: 'Add one',
//                             es: 'Agregar uno',
//                         },
//                         route: '/hr/employees/add',
//                         icon: 'plus',
//                         ranks: [admin, supervisor],
//                         freelance: false,
//                     },
//                     addMultiple: {
//                         text: {
//                             en: 'Add multiple',
//                             es: 'Agregar múltiples',
//                         },
//                         route: '/hr/employees/add-multiple',
//                         icon: 'add-text',
//                         ranks: [admin, supervisor],
//                         freelance: false,
//                     }
//                 }
//             },
//             vacationDays: {
//                 text: {
//                     es: 'Vacaciones',
//                     en: 'Vacation days',
//                 },
//                 route: '/hr/vacation-days',
//                 icon: 'airplane',
//                 ranks: [admin, supervisor, employee],
//                 freelance: false,
//                 children: {
//                     createRequest: {
//                         text: {
//                             es: 'Crear solicitud',
//                             en: 'Create request',
//                         },
//                         route: '/hr/vacation-days/create-request',
//                         icon: 'plus',
//                         ranks: [supervisor, employee],
//                         freelance: false,
//                     },
//                     requests: {
//                         text: {
//                             es: 'Ver solicitudes',
//                             en: 'See requests',
//                         },
//                         route: '/hr/vacation-days/requests',
//                         icon: 'checkbox-list',
//                         ranks: [admin, supervisor],
//                         freelance: false,
//                     },
//                     myRequest: {
//                         text: {
//                             es: 'Mis solicitudes',
//                             en: 'My requests',
//                         },
//                         route: '/hr/vacation-days/my-requests',
//                         icon: 'employee',
//                         ranks: [supervisor, employee],
//                         freelance: false,
//                     },
//                     manage: {
//                         text: {
//                             es: 'Gestionar',
//                             en: 'Manage',
//                         },
//                         route: '/hr/vacation-days/manage',
//                         icon: 'slider',
//                         ranks: [admin, supervisor],
//                         freelance: false,
//                     },
//                 }
//             },
//             timeoff: {
//                 ranks: [admin, supervisor, employee],
//                 freelance: false,
//                 text: {
//                     es: 'Tiempo Libre',
//                     en: 'Timeoff'
//                 },
//                 route: 'hr/timeooff',
//                 icon: 'sun',
//                 children: {
//                     all: {
//                         text: {
//                             en: 'See All',
//                             es: 'Ver Todos'
//                         },
//                         route: 'hr/timeoff/all',
//                         icon: 'view-list',
//                         ranks: [admin, supervisor],
//                         freelance: false,
//                     },
//                     add: {
//                         text: {
//                             en: 'Add',
//                             es: 'Agregar'
//                         },
//                         route: 'hr/timeoff/add',
//                         icon: 'plus',
//                         ranks: [supervisor, employee],
//                         freelance: false
//                     }
//                 }
//             },
//             shifts: {
//                 text: {
//                     es: 'Turnos',
//                     en: 'Shifts',
//                 },
//                 route: 'hr/shifts',
//                 icon: 'two-way-arrows',
//                 ranks: [admin, supervisor],
//                 freelance: false,
//                 children: {
//                     all: {
//                         text: {
//                             en: 'See all',
//                             es: 'Ver todos',
//                         },
//                         route: 'hr/shifts',
//                         icon: 'view-list',
//                         ranks: [admin, supervisor],
//                         freelance: false,
//                     },
//                     add: {
//                         text: {
//                             en: 'Add',
//                             es: 'Agregar',
//                         },
//                         route: 'hr/shifts/add',
//                         icon: 'plus',
//                         ranks: [admin, supervisor],
//                         freelance: false,
//                     },
//                     assign: {
//                         text: {
//                             en: 'Assing',
//                             es: 'Asignar',
//                         },
//                         route: 'hr/supervisor-employees/shifts/assing-shifts',
//                         icon: 'plus-circle',
//                         ranks: [admin, supervisor],
//                         freelance: false,
//                     }
//                 }
//             },
//             departments: {
//                 text: {
//                     es: 'Departamentos',
//                     en: 'Departments',
//                 },
//                 route: 'hr/departments',
//                 icon: 'home',
//                 ranks: [admin, supervisor],
//                 freelance: false,
//                 children: {
//                     list: {
//                         text: {
//                             en: 'See all',
//                             es: 'Ver todos',
//                         },
//                         route: 'hr/departments',
//                         icon: 'view-list',
//                         ranks: [admin, supervisor],
//                         freelance: false,
//                     },
//                     add: {
//                         text: {
//                             en: 'Add',
//                             es: 'Agregar',
//                         },
//                         route: 'hr/departments/add',
//                         icon: 'plus',
//                         ranks: [admin, supervisor],
//                         freelance: false,
//                     },
//                     assign: {
//                         text: {
//                             en: 'Assing',
//                             es: 'Asignar',
//                         },
//                         route: 'hr/departments/assing-departments',
//                         icon: 'plus-circle',
//                         ranks: [admin, supervisor],
//                         freelance: false,
//                     }
//                 }
//             },
//             inOut: {
//                 text: {
//                     es: 'Entradas-Salidas',
//                     en: 'In-Out',
//                 },
//                 route: 'hr/in-out',
//                 icon: 'employee',
//                 ranks: [admin, supervisor, employee],
//                 freelance: false,
//                 children: {
//                     clockInOut: {
//                         text: {
//                             en: 'Clock in/Clock out',
//                             es: 'Agregar',
//                         },
//                         route: 'hr/in-out',
//                         icon: 'clock',
//                         ranks: [supervisor, employee],
//                         freelance: false,
//                     },
//                     inOutReport: {
//                         text: {
//                             en: 'In-Out Reports',
//                             es: 'Reportes de Entrada-Salida',
//                         },
//                         route: 'hr/supervisor-employees/in-out/in-out-report',
//                         icon: 'details',
//                         ranks: [admin, supervisor],
//                         freelance: false,
//                     }
//                 }
//             },
//             jobTitles: {
//                 text: {
//                     es: 'Títulos de empleo',
//                     en: 'Job titles',
//                 },
//                 route: 'hr/job-titles',
//                 icon: 'briefcase',
//                 ranks: [admin, supervisor],
//                 freelance: false,
//                 children: {
//                     all: {
//                         text: {
//                             en: 'See all',
//                             es: 'Ver todos',
//                         },
//                         route: 'hr/job-titles/all',
//                         icon: 'view-list',
//                         ranks: [admin, supervisor],
//                         freelance: false,
//                     },
//                     add: {
//                         text: {
//                             en: 'Add',
//                             es: 'Agregar',
//                         },
//                         route: 'hr/job-titles/add',
//                         icon: 'plus',
//                         ranks: [admin, supervisor],
//                         freelance: false,
//                     }
//                 }
//             },
//             projects: {
//                 text: {
//                     es: 'Proyectos',
//                     en: 'Projects',
//                 },
//                 route: 'hr/projects',
//                 icon: 'check-circle',
//                 freelance: true,
//                 ranks: [admin, supervisor, employee],
//                 children: {
//                     all: {
//                         text: {
//                             en: 'See all',
//                             es: 'Ver todos',
//                         },
//                         route: 'hr/projects/all',
//                         icon: 'view-list',
//                         ranks: [admin, supervisor],
//                         freelance: false,
//                     },
//                     add: {
//                         text: {
//                             en: 'Add',
//                             es: 'Agregar',
//                         },
//                         route: 'hr/projects/add',
//                         icon: 'plus',
//                         ranks: [admin, supervisor],
//                         freelance: false,
//                     }
//                 }
//             },
//             organizations: {
//                 text: {
//                     en: 'Organizations',
//                     es: 'Organizaciones',
//                 },
//                 route: 'hr/organizations',
//                 icon: 'building',
//                 ranks: [admin],
//                 freelance: false,
//                 children: {
//                     all: {
//                         text: {
//                             en: 'See all',
//                             es: 'Ver todas',
//                         },
//                         route: 'hr/organizations/all',
//                         icon: 'view-list',
//                         ranks: [admin],
//                         freelance: false,
//                     },
//                     add: {
//                         text: {
//                             en: 'Add',
//                             es: 'Agregar',
//                         },
//                         route: 'hr/organizations/add',
//                         icon: 'plus',
//                         ranks: [admin],
//                         freelance: false,
//                     }
//                 }
//             },
//             suborganizations: {
//                 text: {
//                     en: 'Suborganizations',
//                     es: 'Suborganizaciones',
//                 },
//                 route: 'hr/suborganizations',
//                 icon: 'store',
//                 ranks: [admin, supervisor],
//                 freelance: false,
//                 children: {
//                     all: {
//                         text: {
//                             en: 'See all',
//                             es: 'Ver todas',
//                         },
//                         route: 'hr/suborganizations/all',
//                         icon: 'view-list',
//                         ranks: [admin],
//                         freelance: false,
//                     },
//                     add: {
//                         text: {
//                             en: 'Add',
//                             es: 'Agregar',
//                         },
//                         route: 'hr/suborganizations/add',
//                         icon: 'plus',
//                         ranks: [admin],
//                         freelance: false,
//                     },
//                     assign: {
//                         text: {
//                             en: 'Assing',
//                             es: 'Asignar',
//                         },
//                         route: 'hr/suborganizations/assing-suborganizations',
//                         icon: 'plus-circle',
//                         ranks: [admin],
//                         freelance: false,
//                     }
//                 }
//             }
//         }
//     }
// }
