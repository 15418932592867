<clr-modal [(clrModalOpen)]="openModal">
  <h3 class="modal-title">Confirmation</h3>
  <div class="modal-body">
    <p>Are you sure you want to perform this operation?</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline" (click)="onCancel()">
      Cancel
    </button>
    <button type="button" class="btn btn-primary" (click)="onConfirm()">
      Confirm
    </button>
  </div>
</clr-modal>
