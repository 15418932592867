import { ASPNavigationNode } from '../../models/general';
import { ASPNavigationTree } from 'src/app/constants/nav.constant';
import { EmployeeRank } from 'src/app/models/employee';

export const generateNavigation = (
    rank: EmployeeRank, 
    freelancersAllowed: boolean, 
    nodeTree: ASPNavigationNode[] = ASPNavigationTree,
    navigation: ASPNavigationNode[] = []
): ASPNavigationNode[] => {

    if (rank === EmployeeRank.superadmin) return ASPNavigationTree;

    for (let node of nodeTree) {

        if (node.children?.length) {
                const allowedChildren = node.children!.filter((child) => {
                    if (!child.ranks) return true;

                    return (
                    (!!child.freelance === freelancersAllowed) &&
                    (child.ranks || []).includes(rank)
                );
            });
            const children = generateNavigation(rank, freelancersAllowed, allowedChildren, []);
            navigation.push({
                ...node,
                children,
            });
        } else {
            navigation.push(node)
        }

    }

    return navigation;
}

// export const generateNavigationObj = (
//     rank: Ranks, 
//     freelancersAllowed: boolean, 
//     nodeTree: ASPNavigationManifest = navManifest,
//     navigation: ASPNavigationManifest = {},
// ): ASPNavigationManifest => {

//     for (let [key, node] of Object.entries(nodeTree)) {

//         if (node.children) {
//                 const allowedChildren: ASPNavigationManifest = {};
//                 (Object.entries(node.children))!
//                 .forEach(([name, child]) => {
//                     if (
//                         (!!child.freelancer === freelancersAllowed) &&
//                         (child.ranks || []).includes(rank)
//                     ) {
//                         allowedChildren[name] = child;
//                     }
//                 });
//             const children = generateNavigationObj(rank, freelancersAllowed, allowedChildren, {});
//             navigation[key] = {
//                 ...node,
//                 children,
//             };
//         } else {
//             navigation[key] = node;
//         }

//     }

//     return navigation;
// }