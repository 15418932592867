//Angular imports
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CurrencyPipe, DatePipe } from '@angular/common';

//Angular fire imports
import { AngularFireModule } from '@angular/fire'; //Firebase general module
import { AngularFireAuthModule } from '@angular/fire/auth'; //Firebase auth module
import { AngularFireFunctionsModule } from '@angular/fire/functions'; //Firebase functions module
//import { AngularFireMessagingModule } from '@angular/fire/messaging'; //Firebase messaging module
import {
  AngularFirestoreModule, //Firestore module
  AngularFirestore, //Firestore provider
} from '@angular/fire/firestore';
import {
  AngularFireStorageModule, //Firebase storage module
  AngularFireStorage, //Firebase storage provider
} from '@angular/fire/storage';
//Clarity imports
import { ClarityModule } from '@clr/angular';

//Components
import { AppComponent } from './app.component';
import { FullNavigationComponent } from './navigation/full-navigation/full-navigation.component';
import { SideNavComponent } from './components/side-nav/side-nav.component';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { SideNavItemComponent } from './components/side-nav-item/side-nav-item.component';
//Modules
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AuthenticationModule } from './modules/authentication/authentication.module';
import { SharedModule } from './modules/shared/shared.module';
//Environment import
import { environment } from '../environments/environment';
import { ErrorsHandler } from './error-handler';
import { ServiceWorkerModule } from '@angular/service-worker';

// FORM
import { ReactiveFormsModule } from '@angular/forms';

// 3RD PARTY
import { ToastrModule } from 'ngx-toastr';

@NgModule({
  declarations: [
    AppComponent,
    FullNavigationComponent,
    SideNavComponent,
    BreadcrumbsComponent,
    SideNavItemComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule, //Browser module
    AppRoutingModule, //Routing module
    ClarityModule, //Clarity
    BrowserAnimationsModule, //Animatinos
    AngularFireModule.initializeApp(environment.firebase), //App initialization
    AngularFirestoreModule, //Firestore module
    AngularFireAuthModule, //Firebase auth module
    AngularFireStorageModule, //Storage module
    AngularFireFunctionsModule,
    ReactiveFormsModule,
    //AngularFireMessagingModule,
    SharedModule, //Shared module
    AuthenticationModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }), //Client auth module
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
    }), // ngx-toastr
  ],
  providers: [
    AngularFirestore, //Firestore provider
    AngularFireStorage, //Storage provider
    {
      provide: ErrorHandler,
      useClass: ErrorsHandler,
    },
    // ,{ provide: USE_EMULATOR, useValue: ['localhost', 5001] }
    DatePipe,
    CurrencyPipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
