

<h2 *ngIf="data.table_options.title">{{ data.table_options.title }}</h2>
<h3 *ngIf="data.table_options.subtitle">{{ data.table_options.subtitle }}</h3>
<clr-datagrid class="fix" [(clrDgSelected)]="data.table_options.selection!.selected_items">
    <clr-dg-placeholder>Parece que no hemos encontrado {{ data.table_options.title }}

    </clr-dg-placeholder>
    
    
    <clr-dg-action-bar *ngIf="data.table_options.actions">
        <div class="btn-group" *ngIf="data.table_options.actions.create">
            <button type="button" class="btn btn-sm btn-secondary" (click)="add()">
                <clr-icon shape="add"></clr-icon> Añadir
            </button>
        </div>
        <div class="btn-group" *ngIf="
        data.table_options.actions.delete &&
        data.table_options.selection.selected_items.length > 0
      ">
            <button type="button" class="btn btn-sm btn-secondary" (click)="delete()">
                <clr-icon shape="close"></clr-icon> Eliminar
            </button>
        </div>

        <div class="btn-group" *ngIf="
        data.table_options.actions.read &&
        data.table_options.selection.selected_items?.length == 1
      ">
            <button type="button" class="btn btn-sm btn-secondary" (click)="detail()">
                <clr-icon shape="details"></clr-icon> Detalle
            </button>
        </div>

        <div class="btn-group" *ngIf="
        data.table_options.actions.update &&
        data.table_options.selection.selected_items?.length == 1
      ">
            <button type="button" class="btn btn-sm btn-secondary" (click)="edit()">
                <clr-icon shape="pencil"></clr-icon> Editar
            </button>
        </div>

        <ng-container *ngIf="data.table_options.actions.custom">
            <ng-container *ngFor="let custom of data.table_options.actions.custom">
                <ng-container *ngIf="
            custom.selection === 'none' &&
            data.table_options.selection.selected_items.length === 0
          ">
                    <div class="btn-group">
                        <button type="button" class="btn btn-sm btn-secondary" (click)="
                custom.action(data.table_options.selection.selected_items)
              ">
                            <clr-icon [attr.shape]="custom.icon"></clr-icon> {{ custom.text }}
                        </button>
                    </div>
                </ng-container>
                <ng-container *ngIf="
            (custom.selection === 'one' || custom.selection === 'some') &&
            data.table_options.selection.selected_items.length === 1
          ">
                    <div class="btn-group">
                        <button type="button" class="btn btn-sm btn-secondary" (click)="
                custom.action(data.table_options.selection.selected_items)
              ">
                            <clr-icon [attr.shape]="custom.icon"></clr-icon> {{ custom.text }}
                        </button>
                    </div>
                </ng-container>
                <ng-container *ngIf="
            (custom.selection === 'many' || custom.selection === 'some') &&
            data.table_options.selection.selected_items.length > 1
          ">
                    <div class="btn-group">
                        <button type="button" class="btn btn-sm btn-secondary" (click)="
                custom.action(data.table_options.selection.selected_items)
              ">
                            <clr-icon [attr.shape]="custom.icon"></clr-icon> {{ custom.text }}
                        </button>
                    </div>
                </ng-container>
                <ng-container *ngIf="custom.selection === 'any'">
                    <div class="btn-group">
                        <button type="button" class="btn btn-sm btn-secondary" (click)="
                custom.action(data.table_options.selection.selected_items)
              ">
                            <clr-icon [attr.shape]="custom.icon"></clr-icon> {{ custom.text }}
                        </button>
                    </div>
                </ng-container>
            </ng-container>
        </ng-container>

        
    </clr-dg-action-bar>
    <!-- class="custom-column" -->
    <ng-container *ngFor="let data_header of data.table_header; let i = index">
        <ng-container *ngIf="data_header?.filter && !data_header?.comparator">
            <ng-container *ngIf="data.table_body_properties[i] !== 'createdAt'; else dateField">
                <clr-dg-column [clrDgField]="
            data.table_body_properties ? data.table_body_properties[i] : ''
          ">
                    <ng-container *clrDgHideableColumn="{ hidden: false }">
                        {{ data_header?.title }}
                    </ng-container>
                </clr-dg-column>
            </ng-container>
            <ng-template #dateField>
                <clr-dg-column [clrDgField]="
            data.table_body_properties ? data.table_body_properties[i] : ''
          " [clrDgSortOrder]="descSort">
                    <ng-container *clrDgHideableColumn="{ hidden: true }">
                        {{ data_header?.title }}
                    </ng-container>
                </clr-dg-column>
            </ng-template>
        </ng-container>
        <ng-container *ngIf="data_header?.filter && data_header?.comparator">
            <clr-dg-column [clrDgField]="
          data.table_body_properties ? data.table_body_properties[i] : ''
        " [clrDgSortBy]="data_header?.comparator">
                <ng-container *clrDgHideableColumn="{ hidden: false }">
                    {{ data_header?.title }}
                </ng-container>
            </clr-dg-column>
        </ng-container>
        <ng-container *ngIf="!data_header?.filter">
            <clr-dg-column [clrDgField]="''">
                <ng-container *clrDgHideableColumn="{ hidden: false }">
                    {{ data_header?.title }}
                </ng-container>
            </clr-dg-column>
        </ng-container>
    </ng-container>
    <clr-dg-column *ngIf="data?.table_options?.actions?.update">
        <ng-container> Editar </ng-container>
    </clr-dg-column>
    <!-- Todo -->
    <clr-dg-row *clrDgItems="let data_body of data.table_body | asyncable" [clrDgItem]="data_body">
        <clr-dg-cell *ngFor="let property of data?.table_body_properties ">
            <ng-container *ngIf="
          property !== 'logUrl' &&
          property !== 'priority' &&
          t(data_body, property).safeObject !== undefined &&
          t(data_body, property).safeObject !== null
        ">      
                {{
                t(data_body, property).safeObject.length > 100
                ? (t(data_body, property).safeObject | slice: 0:100) + "..."
                : t(data_body, property).safeObject
                }}
            </ng-container>

            <ng-container style="text-align: center" *ngIf="
          property === 'priority' &&
          t(data_body, property).safeObject !== undefined &&
          t(data_body, property).safeObject !== null
        ">
                <clr-icon shape="warning-standard" [ngClass]="{
            'is-error': t(data_body, property).safeObject == 0,
            'is-warning': t(data_body, property).safeObject == 1,
            'is-success': t(data_body, property).safeObject == 2
          }"></clr-icon>
            </ng-container>

            <ng-container style="text-align: center" *ngIf="
          property === 'logUrl' &&
          t(data_body, property).safeObject !== undefined &&
          t(data_body, property).safeObject !== null
        ">
                <div [routerLink]="[t(data_body, property).safeObject]" class="logUrl">
                    <clr-icon shape="eye"></clr-icon>
                </div>
            </ng-container>

            <ng-container *ngIf="
          t(data_body, property).safeObject === undefined ||
          t(data_body, property).safeObject === null
        ">
                <div class="unavailable">No disponible</div>
            </ng-container>
        </clr-dg-cell>

        <!-- EDITAR -->
        <clr-dg-cell *ngIf="data?.table_options?.actions?.update">
            <clr-icon (click)="edit(data_body)" shape="pencil" class="icon-edit is-solid"></clr-icon>
        </clr-dg-cell>
    </clr-dg-row>

    <clr-dg-footer>
        <clr-dg-column-toggle>
            <clr-dg-column-toggle-title>Buscar una Columna</clr-dg-column-toggle-title>
            <clr-dg-column-toggle-button>Mostrar Todas</clr-dg-column-toggle-button>
        </clr-dg-column-toggle>

        <clr-dg-pagination #pagination [clrDgPageSize]="data.table_options.paginator.page_size"
            *ngIf="data.table_options.paginator.active">
            <clr-dg-page-size [clrPageSizeOptions]="data.table_options.paginator.page_size_option">
                {{ data.table_options.title }} por pagina
            </clr-dg-page-size>
            {{ pagination.firstItem + 1 }} - {{ pagination.lastItem + 1 }} de
            {{ pagination.totalItems }} {{ data.table_options.title }}
        </clr-dg-pagination>
    </clr-dg-footer>
</clr-datagrid>

