// errors-handler.ts
import { ErrorHandler, Injectable} from '@angular/core';
import { AlertsService } from './services/alerts.service';
@Injectable()
export class ErrorsHandler implements ErrorHandler {
  constructor(public alertService: AlertsService){

  }
  handleError(error: Error) {
     // Do whatever you like with the error (send it to the server?)
     // And log it to the console
    console.error('It happens: ', error);
    this.alertService.setCurrentAlert({alertType: 'errorNavLevel', show: true, alertCase : error.message})
     //alert("OOPS")
  }
}