import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-asp-confirmation-modal',
  templateUrl: './asp-confirmation-modal.component.html',
  styleUrls: ['./asp-confirmation-modal.component.scss'],
})
export class AspConfirmationModalComponent implements OnInit {
  @Input() openModal: boolean = false;
  @Output() confirm = new EventEmitter<boolean>();
  @Output() cancel = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {}

  onConfirm() {
    this.confirm.emit(true);
    this.openModal = false;
  }

  onCancel() {
    this.cancel.emit(true);
    this.openModal = false;
  }
}
