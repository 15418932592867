import { Pipe, PipeTransform } from '@angular/core';
import { RichTimestamp } from '../models/general';
import { timestampToDate } from '../helpers/utils/time-operations';

@Pipe({
  name: 'fireTimestampToDate'
})
export class FireTimestampToDatePipe implements PipeTransform {

  transform(value: RichTimestamp): Date | null {
    return timestampToDate(value);
  }

}
