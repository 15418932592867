<!-- 
    Render warning alert if alertType is equal warning
 -->
<div id="alerta" id="{{ id }}" *ngIf="alertType === 'warning'">
  <div class="alert alert-warning" role="alert">
    <div class="alert-items">
      <div class="alert-item static">
        <div class="alert-icon-wrapper">
          <cds-icon class="alert-icon" shape="exclamation-triangle"></cds-icon>
        </div>
        <span class="alert-text"> {{ this.alertMessage }} </span>
      </div>
    </div>
    <button (click)="cerrar()" type="button" class="close" aria-label="Close">
      <cds-icon aria-hidden="true" shape="close"></cds-icon>
    </button>
  </div>
</div>

<!-- 
    Render danger alert if alertType is equal danger
 -->
<div id="alerta" id="{{ id }}" *ngIf="alertType === 'danger'">
  <div class="alert alert-danger" role="alert">
    <div class="alert-items">
      <div class="alert-item static">
        <div class="alert-icon-wrapper">
          <cds-icon class="alert-icon" shape="exclamation-circle"></cds-icon>
        </div>
        <span class="alert-text"> {{ this.alertMessage }} </span>
      </div>
    </div>
    <button (click)="cerrar()" type="button" class="close" aria-label="Close">
      <cds-icon aria-hidden="true" shape="close"></cds-icon>
    </button>
  </div>
</div>

<!-- 
    Render info alert if alertType is equal info
 -->
<div [style] = "alertStyle" id="alerta" id="{{ id }}" *ngIf="alertType === 'info'">
  <div class="alert alert-info" role="alert">
    <div class="alert-items">
      <div class="alert-item static">
        <div class="alert-icon-wrapper">
          <cds-icon class="alert-icon" shape="info-circle"></cds-icon>
        </div>
        <span class="alert-text"> {{ this.alertMessage }} </span>
      </div>
    </div>
    <button (click)="cerrar()" type="button" class="close" aria-label="Close">
      <cds-icon aria-hidden="true" shape="close"></cds-icon>
    </button>
  </div>
</div>

<!-- 
    Render success alert if alertType is equal success
 -->
<div id="alerta" id="{{ id }}" *ngIf="alertType === 'success'">
  <div class="alert alert-success" role="alert">
    <div class="alert-items">
      <div class="alert-item static">
        <div class="alert-icon-wrapper">
          <cds-icon class="alert-icon" shape="check-circle"></cds-icon>
        </div>
        <span class="alert-text"> {{ this.alertMessage }} </span>
      </div>
    </div>
    <button (click)="cerrar()" type="button" class="close" aria-label="Close">
      <cds-icon aria-hidden="true" shape="close"></cds-icon>
    </button>
  </div>
</div>

<!-- 
    Render info alert-app-level if alertType is equal infoAppLevel
 -->
<div
  id="alerta"
  id="{{ id }}"
  [style] = "alertStyle"
  *ngIf="alertType === 'infoAppLevel'"
  class="alert alert-app-level alert-info"
>
  <div class="alert-items">
    <div class="alert-item static">
      <div class="alert-icon-wrapper">
        <cds-icon class="alert-icon" shape="info-circle"></cds-icon>
      </div>
      <div class="alert-text">
        <strong>{{ this.alertMessage }}</strong>
      </div>
    </div>
  </div>
  <button (click)="cerrar()" type="button" class="close" aria-label="Close">
    <cds-icon aria-hidden="true" shape="close"></cds-icon>
  </button>
</div>

<!-- 
    Render info alert-app-level if alertType is equal navLevel
 -->
 <div
 id="alerta"
 id="{{ id }}"
 [style] = "alertStyle"
 *ngIf="alertType === 'navLevel'"
 class="alert alert-app-level alert-info"
>
 <div class="alert-items">
   <div class="alert-item static">
     <div class="alert-icon-wrapper">
       <cds-icon class="alert-icon" shape="info-circle"></cds-icon>
     </div>
     <div class="alert-text">
       <strong>{{ this.alertMessage }}</strong>
     </div>
   </div>
 </div>
 <button (click)="cerrar()" type="button" class="close" aria-label="Close">
   <cds-icon aria-hidden="true" shape="close"></cds-icon>
 </button>
</div>

<div
id="alerta"
id="{{ id }}"
[style] = "alertStyle"
*ngIf="alertType === 'errorNavLevel'"
class="alert alert-app-level alert-danger"
>
<div class="alert-items">
  <div class="alert-item static">
    <div class="alert-icon-wrapper">
      <cds-icon class="alert-icon" shape="exclamation-circle"></cds-icon>
    </div>
    <div class="alert-text">
      <strong>{{ this.alertMessage }}</strong>
    </div>
  </div>
</div>
<button (click)="cerrar()" type="button" class="close" aria-label="Close">
  <cds-icon aria-hidden="true" shape="close"></cds-icon>
</button>
</div>