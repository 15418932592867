<clr-main-container>
  <!-- NOTIFICATION -->
  <ng-container *ngIf="show">
    <app-alerts
      [id]="'1'"
      [alertMessage]="alertCase"
      [alertType]="alertType"
      [showAlert]="show"
      (setShow)="setShow($event)"
    ></app-alerts>
  </ng-container>

  <header [ngClass]="headerClassByRank[employeeRank]">
    <div class="branding">
      <a [routerLink]="['/']">
        <img
          src="../../../assets/images/asp-logo.svg"
          alt="asp-logo"
          class="logo-img"
        />
        <span class="title">ASP</span>
      </a>
    </div>
    <div class="header-actions" style="align-items: center">
      <span
        class="badge width"
        *ngIf="isLoading"
        [ngStyle]="{ 'background-color': backgroundColor, color: fontColor }"
        >{{ tagText }}</span
      >

      <clr-dropdown [clrCloseMenuOnItemClick]="true">
        <button
          clrDropdownTrigger
          class="nav-text dropdown-toggle"
          aria-label="toggle settings menu"
        >
          {{ currentMoney?.currency }} ({{ currentMoney?.symbol }})

          <cds-icon shape="angle" direction="down"></cds-icon>
        </button>
        <clr-dropdown-menu clrPosition="bottom-right" *clrIfOpen>
          <a
            clrDropdownItem
            class="dropdown-item"
            *ngFor="let item of currencyOptions | keyvalue"
            (click)="changeCurrency(item.value)"
          >
            {{ item.value.currency }} ({{ item.value.symbol }})
          </a>
        </clr-dropdown-menu>
      </clr-dropdown>

      <a href="javascript://" class="nav-link nav-icon-text">
        <cds-icon shape="building"></cds-icon>
        <span class="nav-text">
          {{ currentOrganization?.fiscalName || "Sin organización" }}
        </span>
      </a>

      <!-- <clr-dropdown [clrCloseMenuOnItemClick]="true">
        <button clrDropdownTrigger class="nav-text dropdown-toggle" aria-label="toggle settings menu">
          <cds-icon shape="building"></cds-icon>
          {{ currentOrganization?.fiscalName || "Sin organización" }}
          <cds-icon shape="angle" direction="down"></cds-icon>
        </button>
        <clr-dropdown-menu clrPosition="bottom-right" *clrIfOpen>
          <a clrDropdownItem *ngFor="let org of organizations" (click)="handleOrganizationSelection(org)" href="javascript://" class="dropdown-item">
            {{ org.fiscalName }}
          </a>
        </clr-dropdown-menu>
      </clr-dropdown> -->
      <span
        *ngIf="employeeRank"
        [class]="'badge ' + badgeClassByRank[employeeRank]"
        >{{ rankAbbs[employeeRank] }}</span
      >
      <span
        *ngIf="isFreelancer"
        class="badge"
        style="background: #fff; color: #000"
        >FL</span
      >
      <clr-dropdown *ngIf="userEmail" [clrCloseMenuOnItemClick]="true">
        <button
          clrDropdownTrigger
          class="nav-text dropdown-toggle"
          aria-label="toggle settings menu"
        >
          <cds-icon shape="user"></cds-icon>
          {{ userFullName }}

          <cds-icon shape="angle" direction="down"></cds-icon>
        </button>
        <clr-dropdown-menu clrPosition="bottom-right" *clrIfOpen>
          <a
            clrDropdownItem
            href="javascript://"
            class="clr-control-helper"
            style="
              background-color: #fff;
              cursor: initial;
              font-weight: bold;
              font-size: 0.6rem;
            "
          >
            {{ userEmail }}
          </a>
          <a
            clrDropdownItem
            (click)="logout()"
            href="javascript://"
            class="dropdown-item"
          >
            Logout
          </a>
        </clr-dropdown-menu>
      </clr-dropdown>
    </div>
  </header>

  <div class="content-container">
    <clr-vertical-nav
      *ngIf="navigation"
      [clrVerticalNavCollapsible]="true"
      [(clrVerticalNavCollapsed)]="collapsed"
    >
      <ng-container *ngFor="let node of navigation">
        <ng-container *ngIf="!node.children?.length">
          <a clrVerticalNavLink [routerLink]="node.route">
            <cds-icon [shape]="node.icon" clrVerticalNavIcon></cds-icon>
            {{ node.text.en }}
          </a>
        </ng-container>

        <ng-container
          *ngIf="node.children?.length"
          [ngTemplateOutlet]="nodeWithChildren"
          [ngTemplateOutletContext]="{ node: node, isChild: false }"
        ></ng-container>

        <ng-template #nodeWithChildren let-node="node" let-isChild="isChild">
          <clr-vertical-nav-group
            [ngStyle]="{ 'padding-left': isChild ? '1rem' : '0' }"
          >
            <cds-icon [shape]="node.icon" clrVerticalNavIcon></cds-icon>
            {{ node.text.en }}
            <clr-vertical-nav-group-children>
              <ng-container *ngFor="let child of node.children">
                <ng-container *ngIf="!child.children?.length">
                  <a clrVerticalNavLink [routerLink]="child.route">
                    <cds-icon
                      [shape]="child.icon"
                      clrVerticalNavIcon
                    ></cds-icon>
                    {{ child.text.en }}
                  </a>
                </ng-container>

                <ng-container
                  *ngIf="child.children?.length"
                  [ngTemplateOutlet]="nodeWithChildren"
                  [ngTemplateOutletContext]="{ node: child, isChild: true }"
                >
                </ng-container>
              </ng-container>
            </clr-vertical-nav-group-children>
          </clr-vertical-nav-group>
        </ng-template>
      </ng-container>
    </clr-vertical-nav>

    <div class="content-area">
      <app-breadcrumbs></app-breadcrumbs>
      <router-outlet></router-outlet>
    </div>
  </div>
</clr-main-container>
