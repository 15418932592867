import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { t } from 'typy';
import { ClrDatagridSortOrder } from '@clr/angular';

export interface AspTableCustomAction<T> {
  text: string;
  icon: string;
  selection: 'none' | 'one' | 'some' | 'many' | 'any';
  action: (args: T[]) => void;
}

export interface AspTableActions<T> {
  /**
   * Callback a ejecutar al presionar el botón Add.
   * No muestra el botón si no se suministra valor.
   */
  create?: (objs: T[]) => void;
  /**
   * Callback a ejecutar al presionar el botón Detalles.
   * No muestra el botón si no se suministra valor.
   */
  update?: (obj: T) => void;
  /**
   * Callback a ejecutar al presionar el botón Remover.
   * No muestra el botón si no se suministra valor.
   */
  delete?: (objs: T[]) => void;
  /**
   * Callback a ejecutar al presionar el botón Exportar.
   * No muestra el botón si no se suministra valor.
   */
  export: (objs?: T[]) => void;
  /**
   * Callback a ejecutar al presionar el botón Detalles.
   * No muestra el botón si no se suministra valor.
   */
  read?: (obj: T) => void;

  custom?: AspTableCustomAction<T>[];
}

export interface AspTableSelection<T> {
  selected_items: T[];
}

export interface AspTablePaginatorOptions {
  active: boolean;
  page_size: number;
  page_size_option: number[];
}

export interface AspTableOptions<T> {
  title?: string;
  expandable?: boolean;
  subtitle?: string;
  paginator: AspTablePaginatorOptions;
  selection: AspTableSelection<T>;
  actions: AspTableActions<T>;
}

export interface AspTableInput<T> {
  table_header: {
    title: string;
    filter: boolean;
    comparator?: any;
  }[];
  /**
   * @description una función que retorna un Array con los valores deseados por columna
   * @example table_body_map: (usr) => [usr.id, usr.name, usr.birthDate.toLocaleDateString()]
   */
  table_body_map?: (obj: T) => any[];
  /**
   * @deprecated use table_body_map for improved capabilities;
   */
  table_body_properties: string[];
  table_body?: Array<T> | Observable<Array<T>> | Promise<Array<T>>;
  table_options: AspTableOptions<T>;
}

@Component({
  selector: 'app-asp-table',
  templateUrl: './asp-table.component.html',
  styleUrls: ['./asp-table.component.scss'],
})
export class AspTableComponent<T> implements OnInit {
  // tslint:disable-next-line:no-input-rename
  @Input('dataTable') data: AspTableInput<T> = {
    table_header: [] as any[],
    table_body_properties: [] as any[],
    table_options: {
      selection: {
        selected_items: [] as any[],
      },
      paginator: {
        active: false,
        page_size: 1,
        page_size_option: [] as any[],
      },
      actions: {
        create: () => {},
        update: () => {},
        delete: () => {},
        export: () => {},
        read: () => {},
      },
    },
  };

  rowsSelected = 0;

  selected: Array<T> = [] as any;
  t = t;
  descSort = ClrDatagridSortOrder.DESC;

  users = [
    {
      name: 'carlos',
    },
  ];

  constructor() {}

  ngOnInit() {
    console.log(this.data);
  }

  private tableCanPerformCRUDOperation(
    operation: 'create' | 'read' | 'update' | 'delete'
  ): boolean {
    return (
      !!this.data.table_options.actions[operation] &&
      !!this.data.table_options.selection
    );
  }

  add() {
    if (!this.tableCanPerformCRUDOperation('create')) return;

    // @ts-ignore
    this.data.table_options.actions.create(
      // @ts-ignore
      this.data.table_options.selection.selected_items
    );
  }

  delete() {
    if (!this.tableCanPerformCRUDOperation('delete')) return;

    // @ts-ignore
    this.data.table_options.actions.delete(
      // @ts-ignore
      this.data.table_options.selection.selected_items
    );
  }

  edit(item?: any) {
    if (!this.tableCanPerformCRUDOperation('update')) return;

    // @ts-ignore
    if (item) {
      // @ts-ignore
      this.data.table_options.actions.update(item);
    } else {
      // @ts-ignore
      this.data.table_options.actions.update(
        // @ts-ignore
        this.data.table_options.selection.selected_items[0]
      );
    }
  }

  detail() {
    if (!this.tableCanPerformCRUDOperation('read')) return;

    // @ts-ignore
    this.data.table_options.actions.read(
      // @ts-ignore
      this.data.table_options.selection.selected_items[0]
    );
  }
}
