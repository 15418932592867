import { Currency, currencyTypes } from '../models/currency';

export const currencyData: { [money in currencyTypes]: Currency } = {
  Dollar: {
    name: currencyTypes.DOLLAR,
    symbol: '$',
    codeIntl: 'US',
    currency: 'USD',
    thousandSeparator: ',',
    decimalSeparator: '.',
  },
  Euro: {
    name: currencyTypes.EURO,
    symbol: '€',
    currency: 'EUR',
    codeIntl: 'de-DE',
    thousandSeparator: '.',
    decimalSeparator: ',',
  },
  Pound: {
    name: currencyTypes.POUND,
    symbol: '£',
    currency: 'GBP',
    codeIntl: 'UK',
    thousandSeparator: ',',
    decimalSeparator: '.',
  },
  Yen: {
    name: currencyTypes.YEN,
    symbol: '¥',
    currency: 'JPY',
    codeIntl: 'ja-JP',
    thousandSeparator: '',
    decimalSeparator: '',
  },
};
