<div class="clr-row clr-align-items-start">
    <div class="clr-col-lg-12 ">
        <div class="breadcrumb">
            <li>
                <a clrVerticalNavLink [routerLink]="'/'" routerLinkActive="active">ASP</a> 
            </li>
            <li *ngFor="let breadcrumb of breadcrumbs">
                <a clrVerticalNavLink [routerLink]="breadcrumb.url" routerLinkActive="active">>{{ breadcrumb.label }}</a>
            </li>
        </div>
    </div>
</div>


