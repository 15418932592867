import { EmployeePaymentInterval } from './employee';
import { RichTimestamp } from './general';

export enum EmployeePaymentStatus {
  approved = 'approved',
  rejected = 'rejected',
  waiting = 'waiting',
}

export interface EmployeeDayPaymentReportCreate {
  organizationId: string;
  suborganizationId?: string;
  isWorkday: boolean;
  hoursRequired: number;
  hoursWorked: number;
  isHoliday: boolean;
  timeoffsDurationHours: number;
  isVacationDay: boolean;
  holidayBonus: number;
  netHours: number;
  hoursBalance: number;
  day: number;
  month: number; // zero based
  year: number;
  weekDay: number; // zero based
  shiftsIds: string[];
  employeeId: string;
  dayBonus: number;
  holidaysIds: string[];
  monthSalary: number;
  attendanceLogged: boolean;
  absent: boolean;
  fullDate: RichTimestamp;
}

export interface EmployeeDayPaymentReport
  extends EmployeeDayPaymentReportCreate {
  status: EmployeePaymentStatus;
  createdAt: RichTimestamp;
  reportId: string;
}

export interface EmployeePeriodPaymentReportCreate {
  organizationId: string;
  suborganizationId?: string;
  employeeId: string;
  shiftsIds: string[];
  monthSalary: number;
  hoursRequired: number;
  hoursWorked: number;
  totalBonus: number;
  extraHours: number;
  hoursMissed: number;
  hoursBalance: number;
  payable: number;
  efficacy: number;
  netHours: number;
  paymentInterval: EmployeePaymentInterval;
  startDay: number;
  startMonth: number;
  startYear: number;
  endDay: number;
  endMonth: number;
  endYear: number;
  debtsIds: string[];
  debtsHoursNet: number;
  startDate: RichTimestamp;
  endDate: RichTimestamp;
  date: RichTimestamp;
}

export interface EmployeePeriodPaymentReport
  extends EmployeePeriodPaymentReportCreate {
  id: string;
  status: EmployeePaymentStatus;
  createdAt: RichTimestamp;
}

export interface EmployeeSinglePaymentReportCreate {
  organizationId: string;
  suborganizationId?: string;
  employeeId: string;
  amount: number;
}

export interface EmployeeSinglePaymentReport
  extends EmployeeSinglePaymentReportCreate {
  id: string;
  status: EmployeePaymentStatus;
  createdAt: RichTimestamp;
}

export interface EmployeeBonusReportCreate {
  amount: number;
  organizationId: string;
  suborganizationId?: string;
  employeeId: string;
  comment?: string;
}

export interface EmployeeBonusReport extends EmployeeBonusReportCreate {
  id: string;
  status: EmployeePaymentStatus;
  createdAt: RichTimestamp;
}

export interface EmployeeProjectHoursReportCreate {
  workReportsIds: string[];
  projectId: string;
  totalMinutes: number;
  hourlyRate: number;
  amount: number;
  employeeId: string;
  organizationId: string;
  suborganizationId?: string;
}

export interface EmployeeProjectHoursReport
  extends EmployeeProjectHoursReportCreate {
  id: string;
  createdAt: RichTimestamp;
  status: EmployeePaymentStatus;
}
