import { FirestoreTimestamp } from 'src/app/models/general';
import { Employee } from 'src/app/models/employee';

export const MINUTES_OF_DAY = 24 * 60;

/**
 * Functio to Obtain the Difference in Minutes Between two Hours in Minutes
 * @function getMinutesOfDayDifference
 * @param minutesStart Start Hour in Minutes
 * @param minutesEnd End Hour in Minutes
 * @returns Minutes Difference
 */
export const getMinutesOfDayDifference = (
  minutesStart: number,
  minutesEnd: number
) => {
  if (minutesStart > 1440 || minutesEnd > 1440) return null;
  if (minutesStart < 0 || minutesEnd < 0) return null;

  if (minutesStart < minutesEnd) return minutesEnd - minutesStart;

  return MINUTES_OF_DAY - minutesStart + minutesEnd;
};

/**
 * Function to Get the Hour from the Minutes
 * @function getHoursFromMinutes
 * @param minutes Hour in Minutes
 * @returns Hour (HH:MM)
 */
export const getHoursFromMinutes = (minutes: number): string | null => {
  if (minutes == null) {
    return null;
  }
  const hours = Math.floor(minutes / 60);
  return hours + ':' + (minutes - hours * 60);
};

/**
 * Function to Get Minutes from the Hour
 * @function getMinutesFromDayHour
 * @param dayHour Hour (HH:MM)
 * @returns Hour in Minutes
 */
export const getMinutesFromDayHour = (dayHour: string): number => {
  const timeArray = dayHour.split(':');
  return getMinutesFromHours(timeArray[0], timeArray[1]);
};

/**
 * Function to Get Minutes from the Hour
 * @function getMinutesFromHours
 * @param hours Hour
 * @param minutes Minutes
 * @returns Time in Minutes
 */
export const getMinutesFromHours = (hours: string, minutes: string): number => {
  return parseInt(hours) * 60 + parseInt(minutes);
};

/**
 * Function to Determine if Two Schedules Collide
 * @function compareHourIntervals
 * @param firstBeginHour First Begin Hour (HH:MM || MM)
 * @param firstEndHour First End Hour (HH:MM || MM)
 * @param secondBeginHour Second Begin Hour (HH:MM || MM)
 * @param secondEndHour Second End Hour (HH:MM || MM)
 * @returns boolean (True if Two Hours Collide)
 */
export const compareHourIntervals = (
  firstBeginHour: string | number,
  firstEndHour: string | number,
  secondBeginHour: string | number,
  secondEndHour: string | number
): boolean => {
  //If Fisrt Begin Hour is String (HH:MM), Convert it to Minutes
  const firstBeginMinute =
    typeof firstBeginHour === 'string'
      ? getMinutesFromDayHour(firstBeginHour)
      : firstBeginHour;
  //If Fisrt End Hour is String (HH:MM), Convert it to Minutes
  let firstEndMinute =
    typeof firstEndHour === 'string'
      ? getMinutesFromDayHour(firstEndHour)
      : firstEndHour;
  //If Second Begin Hour is String (HH:MM), Convert it to Minutes
  const secondBeginMinute =
    typeof secondBeginHour === 'string'
      ? getMinutesFromDayHour(secondBeginHour)
      : secondBeginHour;
  //If Second End Hour is String (HH:MM), Convert it to Minutes
  let secondEndMinute =
    typeof secondEndHour === 'string'
      ? getMinutesFromDayHour(secondEndHour)
      : secondEndHour;

  //Manage for All Night Hours in First Schedule
  if (firstBeginMinute > firstEndMinute) {
    firstEndMinute += 24 * 60;
  }
  //Manage for All Night Hours in Second Schedule
  if (secondBeginMinute > secondEndMinute) {
    secondEndMinute += 24 * 60;
  }

  return (
    (firstBeginMinute <= secondBeginMinute &&
      firstEndMinute <= secondBeginMinute) ||
    (firstBeginMinute >= secondEndMinute && firstEndMinute >= secondEndMinute)
  );
};

/**
 * Function to Maximize Date
 * @function maximizeDate
 * @param date Date Object
 * @returns Maximized Date
 */
export const maximizeDate = (date: Date): Date => {
  const returnDate = new Date(date);
  returnDate.setHours(23);
  returnDate.setMinutes(59);
  returnDate.setSeconds(59);
  returnDate.setMilliseconds(999);

  return returnDate;
};

/**
 * Function to Minimize Date
 * @function minimizeDate
 * @param date Date Object
 * @returns Minimized Date
 */
export const minimizeDate = (date: Date): Date => {
  const returnDate = new Date(date);
  returnDate.setHours(0);
  returnDate.setMinutes(0);
  returnDate.setSeconds(0);
  returnDate.setMilliseconds(0);

  return returnDate;
};

/**
 * Function to Normalize Hours and Minutes (8:50 -> 08:50)
 * @function normalizeHoursAndMinutes
 * @param completeHour Hour (HH:MM)
 * @returns
 */
export const normalizeHoursAndMinutes = (completeHour: string) => {
  //Normalize Hours
  const hours: string =
    completeHour.split(':')[0].length == 1
      ? '0' + completeHour.split(':')[0]
      : completeHour.split(':')[0];
  //Normalize Minutes
  const minutes: string =
    completeHour.split(':')[1].length == 1
      ? '0' + completeHour.split(':')[1]
      : completeHour.split(':')[1];

  return hours + ':' + minutes;
};

/**
 * Function to Calculate the Minute Differnece Between two Dates
 * @function minuteDifferenceBetweenTwoDates
 * @param firstDate Fisrt Date
 * @param secondDate Second Date
 * @returns Number of Minutes
 */
export const minuteDifferenceBetweenTwoDates = (
  firstDate: Date,
  secondDate: Date
) => {
  //Normalize Dates
  firstDate.setSeconds(0);
  firstDate.setMilliseconds(0);
  secondDate.setSeconds(0);
  secondDate.setMilliseconds(0);

  return Math.abs((firstDate.getTime() - secondDate.getTime()) / 60000);
};

export const datesHaveSameDay = (d1: Date, d2: Date): boolean => {
  return (
    d1.getDate() === d2.getDate() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getFullYear() === d2.getFullYear()
  );
};

export const calculateDaysSincePayment = (
  emp: Employee,
  todayDate: Date
): number => {
  const referenceTimestamp = emp.lastPayedAt || emp.firstDayDate;
  const lastPayedAtDate = (referenceTimestamp as FirestoreTimestamp).toDate();
  return Math.floor(
    (todayDate!.getTime() - lastPayedAtDate.getTime()) / 1000 / 60 / 60 / 24
  );
};

export const isLeapYear = (year: number) => {
  const date = new Date(year, 1, 29);

  return date.getMonth() === 1;
};

export const isLastDayOfMonth = (paramDate: Date): boolean => {
  const date = new Date(paramDate);

  date.setDate(date.getDate() + 1);
  return date.getMonth() !== paramDate.getMonth();
};

export const timestampToDate = (value: any) => {
  if (!value) return null;

  if ((value as FirestoreTimestamp).toDate) {
    return (value as FirestoreTimestamp).toDate();
  }

  if (value instanceof Date) {
    return value;
  }

  return null;
};
