

import { ChangeDetectorRef, EventEmitter, OnDestroy, Pipe, PipeTransform, WrappedValue, ɵisObservable, ɵisPromise } from '@angular/core';
import { Observable, SubscriptionLike } from 'rxjs';

interface SubscriptionStrategy {
    createSubscription(async: Observable<any> | Promise<any>, updateLatestValue: any): SubscriptionLike
        | Promise<any>;
    dispose(subscription: SubscriptionLike | Promise<any>): void;
    onDestroy(subscription: SubscriptionLike | Promise<any>): void;
}

class ObservableStrategy implements SubscriptionStrategy {
    createSubscription(async: Observable<any>, updateLatestValue: any): SubscriptionLike {
        return async.subscribe({ next: updateLatestValue, error: (e: any) => { throw e; } });
    }

    dispose(subscription: SubscriptionLike): void { subscription.unsubscribe(); }

    onDestroy(subscription: SubscriptionLike): void { subscription.unsubscribe(); }
}

class PromiseStrategy implements SubscriptionStrategy {
    createSubscription(async: Promise<any>, updateLatestValue: (v: any) => any): Promise<any> {
        return async.then(updateLatestValue, e => { throw e; });
    }

    dispose(subscription: Promise<any>): void { }

    onDestroy(subscription: Promise<any>): void { }
}

class StaticStrategy implements SubscriptionStrategy {
    createSubscription(async: any, updateLatestValue: (v: any) => any): Promise<any> {
        return updateLatestValue(async);
    }

    dispose(subscription: Promise<any>): void { }

    onDestroy(subscription: Promise<any>): void { }
}


const promiseStrategy = new PromiseStrategy();
const observableStrategy = new ObservableStrategy();
const staticStrategy = new StaticStrategy();

/**
 * @ngModule CommonModule
 * @description
 *
 * Unwraps a value from an asynchronous primitive, if necessary.
 *
 * The `asyncable` pipe subscribes to an `Observable` or `Promise` and returns the latest value it has
 * emitted. When a new value is emitted, the `asyncable` pipe marks the component to be checked for
 * changes. When the component gets destroyed, the `asyncable` pipe unsubscribes automatically to avoid
 * potential memory leaks. If the value itself is not an `Observable` or `Promise`, it does nothing.
 *
 */
@Pipe({ name: 'asyncable', pure: false })
export class AsyncablePipe implements OnDestroy, PipeTransform {
    private latestValue: any = null;
    private latestReturnedValue: any = null;

    private subscription: SubscriptionLike | Promise<any> | null = null;
    private obj: Observable<any> | Promise<any> | EventEmitter<any> | null = null;
    // @ts-ignore
    private strategy: SubscriptionStrategy;

    constructor(private ref: ChangeDetectorRef) { }

    ngOnDestroy(): void {
        if (this.subscription) {
            this._dispose();
        }
    }

    transform(obj: any | Observable<any> | Promise<any> | null | undefined): any {
        if (!this.obj) {
            if (obj) {
                this._subscribe(obj);
            }
            this.latestReturnedValue = this.latestValue;
            return this.latestValue;
        }

        if (obj !== this.obj) {
            this._dispose();
            return this.transform(obj as any);
        }

        if (this.latestValue === this.latestReturnedValue) {
            return this.latestReturnedValue;
        }

        this.latestReturnedValue = this.latestValue;
        return WrappedValue.wrap(this.latestValue);
    }

    private _subscribe(obj: Observable<any> | Promise<any> | EventEmitter<any>): void {
        this.obj = obj;
        this.strategy = this._selectStrategy(obj);
        this.subscription = this.strategy.createSubscription(
            obj, (value: any) => this._updateLatestValue(obj, value));
    }

    private _selectStrategy(obj: Observable<any> | Promise<any> | EventEmitter<any>): any {
        if (ɵisPromise(obj)) {
            return promiseStrategy;
        }

        if (ɵisObservable(obj)) {
            return observableStrategy;
        }

        return staticStrategy;
    }

    private _dispose(): void {
        // @ts-ignore
        this.strategy.dispose(this.subscription);
        this.latestValue = null;
        this.latestReturnedValue = null;
        this.subscription = null;
        this.obj = null;
    }

    private _updateLatestValue(async: any, value: any): void {
        if (async === this.obj) {
            this.latestValue = value;
            this.ref.markForCheck();
        }
    }
}
