<div class="clr-row">
  <div class="clr-col-4"></div>
  <div class="clr-col-4">
    <div class="card">
      <div class="card-header">
        <h2 style="margin: 0; text-align: center;">Welcome to <span>ASP</span></h2>
      </div>
      <div class="card-block">
        <ng-container *ngIf="isLoading"> Loading... </ng-container>

        <ng-container *ngIf="!passwordRecovery; else passwordRecoveryForm">
          <form
            *ngIf="loginForm"
            [formGroup]="loginForm"
            (ngSubmit)="loginHandler()"
            class="clr-form clr-form-vertical"
          >
            <div class="clr-form-control">
              <label for="email" class="clr-control-label">Emial</label>
              <div class="clr-control-container">
                <div class="clr-input-wrapper">
                  <input
                    id="email"
                    name="email"
                    formControlName="email"
                    type="text"
                    class="clr-input"
                    placeholder="user@example.com"
                  />
                </div>
              </div>
            </div>
            <div class="clr-form-control">
              <label for="password" class="clr-control-label">Password</label>
              <div class="clr-control-container">
                <div class="clr-input-wrapper">
                  <input
                    id="password"
                    name="password"
                    formControlName="password"
                    type="password"
                    class="clr-input"
                    placeholder="******"
                  />
                </div>
              </div>
            </div>
            <div class="clr-form-control" *ngIf="loginForm.errors">
              <p>Invalid email or password</p>
            </div>

            <br>

            <div class="buttons-group">
              <button
                class="btn btn-primary"
                type="submit"
                [disabled]="isLoading || !loginForm.valid"
              >
                Enter
              </button>

              <br>

              <button
                [disabled]="isLoading"
                type="button"
                class="btn btn-link btn-sm"
                [routerLink]="['register']"
              >
                Register
              </button>
              <button
                [disabled]="isLoading"
                type="button"
                class="btn btn-link btn-sm"
                (click)="togglePasswordRecovery()"
              >
                Recover password
              </button>
            </div>
          </form>
        </ng-container>

        <ng-template #passwordRecoveryForm>
          <form
            *ngIf="recoveryForm"
            [formGroup]="recoveryForm"
            (ngSubmit)="recoveryHandler()"
          >
            <div class="clr-form-control">
              <label for="email" class="clr-control-label">Correo</label>
              <div class="clr-control-container">
                <div class="clr-input-wrapper">
                  <input
                    id="email"
                    name="email"
                    formControlName="email"
                    type="text"
                    class="clr-input"
                    placeholder="usuario@ejemplo.com"
                  />
                </div>
              </div>
            </div>
            <div class="btn-group">
              <button
                class="btn btn-primary"
                type="submit"
                [disabled]="isLoading || !recoveryForm.valid"
              >
                Enviar
              </button>
              <button
                [disabled]="isLoading"
                type="button"
                class="btn btn-link"
                (click)="togglePasswordRecovery()"
              >
                Volver
              </button>
            </div>
          </form>
        </ng-template>
      </div>
    </div>
  </div>
</div>



