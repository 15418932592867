<clr-datagrid
  [ngClass]="{ 'datagrid-compact': config.compact }"
  [(clrDgSelected)]="selectedRows"
  (clrDgSelectedChange)="handleValueUpdate($event, 'selectedRows')"
  (clrDgRefresh)="onRefresh($event)"
  *ngIf="visible"
>
  <!-- ACTIONS -->
  <clr-dg-action-bar *ngIf="config.actions?.length">
    <div class="btn-group">
      <ng-container *ngFor="let action of config.actions">
        <button
          type="button"
          *ngIf="actionsAvailabilityConditionsBySelection[action.selection]"
          class="btn btn-sm btn-secondary"
          (click)="action.callback(selectedRows)"
        >
          <clr-icon *ngIf="action.icon" [attr.shape]="action.icon"></clr-icon>
          {{ action.text }}
        </button>
      </ng-container>
    </div>
  </clr-dg-action-bar>
  <!-- TABLE HEAD -->
  <ng-container
    [ngSwitch]="element.filterType"
    *ngFor="let element of config.dataElements; let i = index"
  >
    <clr-dg-column
      [ngStyle]="element.maxWidth ? { width: element.maxWidth + 'px' } : {}"
      *ngSwitchCase="'simple'"
      [clrDgField]="element.dataKey"
      [clrDgColType]="element.type"
    >
      <ng-container *clrDgHideableColumn="{ hidden: element.initialyHidden }">
        {{ element.headerText || defaultValues.headerText }}
      </ng-container>
    </clr-dg-column>

    <clr-dg-column
      [ngStyle]="element.maxWidth ? { width: element.maxWidth + 'px' } : {}"
      *ngSwitchCase="'custom'"
      [clrDgField]="element.dataKey"
      [clrDgColType]="element.type"
      [clrDgSortBy]="element.customSorter || ''"
    >
      <ng-container *clrDgHideableColumn>
        {{ element.headerText || defaultValues.headerText }}
      </ng-container>
    </clr-dg-column>

    <clr-dg-column
      [ngStyle]="element.maxWidth ? { width: element.maxWidth + 'px' } : {}"
      *ngSwitchDefault
      [clrDgColType]="element.type"
    >
      <ng-container *clrDgHideableColumn>
        {{ element.headerText || defaultValues.headerText }}
      </ng-container>
    </clr-dg-column>
  </ng-container>

  <clr-dg-placeholder>
    <ng-container *ngIf="config.placeholder; else defaultPlaceholder">
      {{ config.placeholder }}
    </ng-container>
    <ng-template #defaultPlaceholder>
      No
      {{
        config.dataNameForDisplay || defaultValues.dataNameForDisplay
          | lowercase
      }}
      to show
    </ng-template>
  </clr-dg-placeholder>

  <!-- TABLE BODY -->
  <clr-dg-row
    *clrDgItems="let row of rows; let rowIndex = index"
    [clrDgItem]="row"
    [clrDgSelectable]="
      config.selectableRowConditionFn
        ? config.selectableRowConditionFn(row)
        : true
    "
    (clrDgSelectedChange)="handleRowSelected($event, row)"
  >
    <clr-dg-action-overflow *ngIf="config.rowActions?.length">
      <button
        *ngFor="let action of config.rowActions"
        class="action-item"
        (click)="action.callback(row, rowIndex)"
      >
        {{ action.text }}
      </button>
    </clr-dg-action-overflow>
    <clr-dg-cell
      class="with-ellipsis"
      [ngClass]="{ 'no-line-breaks': element.avoidLineBreaks }"
      *ngFor="let element of config.dataElements; let keyIndex = index"
    >
      <ng-container *ngIf="element.fn; else nonFuctionalCell">
        <ng-container *ngIf="!element.isHtml; else functionalHtmlContent">
          {{
            (element.fnExecutionMode || defaultValues.fnExecutionMode) ===
            "pipe"
              ? (element.fn | aspDgFn: row)
              : element.fn(row)
          }}
        </ng-container>
        <ng-template #functionalHtmlContent>
          <div [innerHtml]="element.fn | aspDgFn: row"></div>
        </ng-template>
      </ng-container>
      <ng-template #nonFuctionalCell>
        <ng-container
          *ngIf="!element.isHtml; else htmlCellContent"
          [ngSwitch]="element.type"
        >
          <!-- string -->
          <ng-container *ngSwitchCase="'string'">
            {{
              row[element.dataKey] ||
                element.fallback ||
                defaultValues.cellValue
            }}
          </ng-container>
          <!-- number -->
          <ng-container *ngSwitchCase="'number'">
            {{
              row[element.dataKey] ||
                element.fallback ||
                defaultValues.cellValue
            }}
          </ng-container>
          <ng-container *ngSwitchCase="'currency'">
            {{ formatter.format(row[element.dataKey])  || element.fallback || defaultValues.cellValue }}
          </ng-container>

          <ng-container *ngSwitchCase="'link'">
            <ng-container *ngIf="element.linkConfig">
              <ng-container
                *ngIf="
                  element.linkConfig.hideConditionFn
                    ? !(element.hideCondExeMode === 'direct'
                        ? element.linkConfig.hideConditionFn(row, rowIndex)
                        : (element.linkConfig.hideConditionFn
                          | aspDgFn: row:rowIndex))
                    : true
                "
              >
                <a
                  *ngIf="element.linkConfig.type === 'internal'"
                  [routerLink]="element.linkConfig.route"
                  >{{ element.linkConfig.text }}</a
                >
                <a
                  *ngIf="element.linkConfig.type === 'external'"
                  [href]="element.linkConfig.route"
                  >{{ element.linkConfig.text }}</a
                >
              </ng-container>
            </ng-container>
          </ng-container>

          <ng-container *ngSwitchCase="'button'">
            <ng-container *ngIf="element.buttonConfig">
              <ng-container
                *ngIf="
                  element.buttonConfig.hideConditionFn
                    ? !(element.hideCondExeMode === 'direct'
                        ? element.buttonConfig.hideConditionFn(row, rowIndex)
                        : (element.buttonConfig.hideConditionFn
                          | aspDgFn: row:rowIndex))
                    : true
                "
              >
                <button
                  style="margin: 0"
                  (click)="element.buttonConfig.fn(row, rowIndex)"
                  [class]="'btn ' + (element.buttonConfig.class || '')"
                >
                  {{ element.buttonConfig.text }}
                </button>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
        <!-- html -->
        <ng-template #htmlCellContent>
          <div [innerHtml]="row[element.dataKey]"></div>
        </ng-template>
      </ng-template>
    </clr-dg-cell>

    <!-- EXPANDABLE ROW -->
    <ng-container
      ngProjectAs="clr-dg-row-detail"
      *ngIf="config.expandableRowConfig"
    >
      <clr-dg-row-detail *clrIfExpanded>
        <ng-container
          *ngIf="config.expandableRowConfig.isHtml; else plainExpandableContent"
        >
          <div
            [innerHTML]="
              config.expandableRowConfig.expandableRowContentFn | aspDgFn: row
            "
          ></div>
        </ng-container>
        <ng-template #plainExpandableContent>
          {{ config.expandableRowConfig.expandableRowContentFn | aspDgFn: row }}
        </ng-template>
      </clr-dg-row-detail>
    </ng-container>
  </clr-dg-row>

  <!-- DETAIL PANE -->
  <ng-container ngProjectAs="clr-dg-detail" *ngIf="config.detailPaneConfig">
    <ng-template
      clrIfDetail
      let-detail
      (clrIfDetailChange)="onDetailChange($event)"
    >
      <clr-dg-detail>
        <clr-dg-detail-header>
          {{
            detail[config.detailPaneConfig.detailPaneTitleKey] ||
              config.detailPaneConfig.detailPaneFallback ||
              defaultValues.detailPaneTitle
          }}
        </clr-dg-detail-header>
        <clr-dg-detail-body>
          <strong>Detalles</strong>
          <p *ngIf="!loadingDetails; else loadingDetailsTemplate">
            {{ config.detailPaneConfig.detailPaneContentFn | aspDgFn: detail }}
          </p>
          <ng-template #loadingDetailsTemplate>
            <p>Cargando detalles...</p>
          </ng-template>
        </clr-dg-detail-body>
      </clr-dg-detail>
    </ng-template>
  </ng-container>

  <!-- FOOTER -->
  <clr-dg-footer>
    <clr-dg-pagination *ngIf="rows.length" #pagination>
      <clr-dg-page-size
        [clrPageSizeOptions]="
          config.footerConfig?.pageSizeOptions || defaultValues.pageSizeOptions
        "
      >
        <ng-container *ngIf="!config.footerConfig?.hideItemsPerPageText">
          {{
            config.dataNameForDisplay || defaultValues.dataNameForDisplay
          }}
          por página
        </ng-container>
      </clr-dg-page-size>
      <ng-container *ngIf="!config.footerConfig?.hideQuantityOutOfTotalText">
        {{ pagination.firstItem + 1 }} - {{ pagination.lastItem + 1 }} of
        {{ pagination.totalItems }}
        {{
          config.dataNameForDisplay || defaultValues.dataNameForDisplay
            | lowercase
        }}
      </ng-container>
    </clr-dg-pagination>
  </clr-dg-footer>
</clr-datagrid>

<ng-template #tableRow> </ng-template>
