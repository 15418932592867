<!-- [(clrDgSelected)]="selected" Allow selection -->
<!-- (clrDgSelectedChange)="selectionChanged($event)" Listen to selection changes -->
<!-- [(clrDgSingleSelected)]="selectedUser" Single selection -->
<div
  *ngIf="this.realConfig.data.isUpdateDetailWithSelect === ''; then detailOff"
></div>
<ng-template #detailOff>
  <ng-container>
    <clr-datagrid
      [(clrDgSelected)]="realConfig.selected"
      [clrDgPreserveSelection]="realConfig.preserveSelection"
      (clrDgRefresh)="refresh($event)"
      [clrDgLoading]="realConfig.isLoading"
    >
      <!-- PLACEHOLDER -->
      <clr-dg-placeholder>{{
        realConfig.placeholderMessage
          ? realConfig.placeholderMessage
          : defaultPlaceholderMessage
      }}</clr-dg-placeholder>
  
      <!-- ACTIONS -->
      <clr-dg-action-bar *ngIf="realConfig.generalActions.length > 0">
        <div
          class="btn-group"
          *ngFor="let generalAction of realConfig.generalActions"
        >
          <ng-container
            *ngIf="
              !generalAction.subActions || generalAction.subActions.length == 0;
              else actionsDropdown
            "
          >
            <button
              [disabled]="
                (generalAction.minRows !== 'any' &&
                  generalAction.minRows > realConfig.selected.length) ||
                (generalAction.maxRows !== 'any' &&
                  generalAction.maxRows < realConfig.selected.length)
              "
              type="button"
              class="btn btn-sm btn-secondary"
              (click)="generalAction.action(realConfig.selected)"
            >
              <cds-icon [shape]="generalAction.icon || 'plus'"></cds-icon> {{ generalAction.displayName }}
            </button>
          </ng-container>
          <ng-template #actionsDropdown>
            <clr-dropdown>
              <button
                type="button"
                class="btn btn-sm btn-secondary"
                clrDropdownTrigger
              >
                {{ generalAction.displayName }}
                <cds-icon shape="caret down"></cds-icon>
              </button>
              <clr-dropdown-menu clrPosition="bottom-left" *clrIfOpen>
                <button
                  [disabled]="
                    (generalSubAction.minRows !== 'any' &&
                      generalSubAction.minRows > realConfig.selected.length) ||
                    (generalSubAction.maxRows !== 'any' &&
                      generalSubAction.maxRows < realConfig.selected.length)
                  "
                  *ngFor="let generalSubAction of generalAction.subActions"
                  type="button"
                  (click)="generalSubAction.action(realConfig.selected)"
                  clrDropdownItem
                >
                  {{ generalSubAction.displayName }}
                </button>
              </clr-dropdown-menu>
            </clr-dropdown>
          </ng-template>
        </div>
      </clr-dg-action-bar>
  
      <!-- COLUMNS -->
      <!-- [clrDgSortBy]="pokemonComparator" custom comparator-->
      <!-- [clrDgSortOrder]="descSort" Preselected sort order-->
      <!-- [(clrFilterValue)]="myFilterValue" -->
      <!-- getColumDataType -->
      <clr-dg-column
        *ngFor="let column of realConfig.columns"
        [clrDgField]="column.allowFilter ? column.name : ''"
        [clrDgColType]="column.dataType == 'string' ? 'string' : 'number'"
      >
        <ng-container *clrDgHideableColumn="{ hidden: column.initialyHidden }">
          {{ column.displayName }}
        </ng-container>
      </clr-dg-column>
  
      <!-- ROWS -->
      <!-- [clrDgItem]="item"  item to select-->
      <!-- [clrDgSelectable]="!item.locked" prevent user interaction edit selection-->
      <clr-dg-row
        *clrDgItems="let item of data"
        [clrDgItem]="item"
        [clrDgSelectable]="item.locked === false"
      >
        <clr-dg-action-overflow *ngIf="realConfig.rowActions.length > 0">
          <button
            *ngFor="let rowAction of realConfig.rowActions"
            class="action-item"
            (click)="rowAction.action(item)"
          >
            {{ rowAction.displayName }}
          </button>
        </clr-dg-action-overflow>
        <clr-dg-cell *ngFor="let rowConfig of realConfig.rows;">
          <ng-template #defaultCell>
            {{ item[rowConfig.attrb] }}
          </ng-template>
          <ng-template #customCell>
            <div
              [ngStyle]="{
                color: item[rowConfig.attrb] === 'AZUL' ? 'blue' : 'green'
              }"
            >
              {{ item[rowConfig.attrb] }}
            </div>
          </ng-template>
          <ng-template #selectCell>
            <div>
              {{ item[rowConfig.attrb] }} 
              <select value="item[rowConfig.attrb]" [ngModel]="rowConfig.ngModelValue.id" (ngModelChange)="onSelectCellChange($event, rowConfig, item)" clrSelect>
                <option selected = "{item[rowConfig.attrb] === option.id? true: false}" value={{option.id}} *ngFor="let option of rowConfig.selectOptions"> {{option.value}} </option>
              </select>
            </div>
          </ng-template>
          <ng-container
            *ngTemplateOutlet="
              rowConfig.cellType == 'custom' ? customCell : rowConfig.cellType == 'selectCell' ? selectCell: defaultCell
            "
          >
          </ng-container>
        </clr-dg-cell>
        <!-- EXPANDED DETAIL -->
        <!-- The extension replace the row [clrDgReplace]="true" -->
        <ng-container
          ngProjectAs="clr-dg-row-detail"
          *ngIf="realConfig.rowExtendConfig.isActive"
        >
          <clr-dg-row-detail
            [clrDgReplace]="realConfig.rowExtendConfig.replaceRow"
            *clrIfExpanded
          >
            Lorem ipsum...
          </clr-dg-row-detail>
        </ng-container>
      </clr-dg-row>
  
      <!-- Detail inside select update user's rol -->
      <clr-dg-action-bar
        style="margin-left: 20%"
        *ngIf="this.realConfig.data.isUpdateDetailWithSelect === 'userRolTable'"
      >
        <clr-dg-detail *clrIfDetail="let detail">
          <clr-dg-detail-header>{{ detail.email }}</clr-dg-detail-header>
          <clr-dg-detail-body>
            <form
              [formGroup]="this.formDetail"
              (ngSubmit)="propagare(formDetail.value, detail.id)"
              class="clr-form clr-form-vertical"
            >
              <h5>Full name: {{ detail.fullName }}</h5>
              <h5>Roles:</h5>
              <clr-select-container>
                <select formControlName="dataDetail" name="dataDetail" clrSelect>
                  <option
                    value="{{ item.id }}"
                    *ngFor="
                      let item of this.realConfig.data.dataDetailUpdateWithSelect
                    "
                  >
                    {{ item.name }}
                  </option>
                </select>
                <clr-control-error> Debe seleccionar un rol</clr-control-error>
              </clr-select-container>
              <br />
              <button
                class="btn btn-primary"
                type="submit"
                [disabled]="!formDetail.valid"
              >
                Cambiar Rol
              </button>
            </form>
          </clr-dg-detail-body>
        </clr-dg-detail>
      </clr-dg-action-bar>
  
      <!-- DETAIL INSIDE TABLE -->
      <!-- <clr-dg-detail *clrIfDetail="let detail">
        <clr-dg-detail-header>{{ detail.name }}</clr-dg-detail-header>
        <clr-dg-detail-body> Soy el BODY </clr-dg-detail-body>
      </clr-dg-detail> -->
  
      <!-- FOOTER -->
      <!-- <clr-dg-footer>{{data.length}} users</clr-dg-footer> -->
      <!-- Inside the full datagrid declaration -->
      <!-- *ngIf = "realConfig.pagination.isActive" -->
      <clr-dg-footer>
        <clr-dg-pagination
          #pagination
          [clrDgPageSize]="realConfig.pagination.pageSize"
        >
          <clr-dg-page-size [clrPageSizeOptions]="realConfig.pagination.options"
            >Items per page</clr-dg-page-size
          >
          {{ pagination.firstItem + 1 }} - {{ pagination.lastItem + 1 }} of
          {{ pagination.totalItems }} items
        </clr-dg-pagination>
      </clr-dg-footer>
    </clr-datagrid>
  </ng-container>
</ng-template>
<!-- Renderizar el aspDatagrid del userRolTable Component con el feature clrDetail On  -->

<div
  *ngIf="
    this.realConfig.data.isUpdateDetailWithSelect === 'userRolTable';
    then userRolTableDetail
  "
></div>
<ng-template #userRolTableDetail>
  <ng-container #detailOn>
    <clr-datagrid
      [(clrDgSelected)]="realConfig.selected"
      [clrDgPreserveSelection]="realConfig.preserveSelection"
      (clrDgRefresh)="refresh($event)"
      [clrDgLoading]="realConfig.isLoading"
    >
      <!-- PLACEHOLDER -->
      <clr-dg-placeholder>{{
        realConfig.placeholderMessage
          ? realConfig.placeholderMessage
          : defaultPlaceholderMessage
      }}</clr-dg-placeholder>

      <!-- ACTIONS -->
      <clr-dg-action-bar *ngIf="realConfig.generalActions.length > 0">
        <div
          class="btn-group"
          *ngFor="let generalAction of realConfig.generalActions"
        >
          <ng-container
            *ngIf="
              !generalAction.subActions || generalAction.subActions.length == 0;
              else actionsDropdown
            "
          >
            <button
              [disabled]="
                (generalAction.minRows !== 'any' &&
                  generalAction.minRows > realConfig.selected.length) ||
                (generalAction.maxRows !== 'any' &&
                  generalAction.maxRows < realConfig.selected.length)
              "
              type="button"
              class="btn btn-sm btn-secondary"
              (click)="generalAction.action(realConfig.selected)"
            >
              <cds-icon [shape]="generalAction.icon || 'plus'"></cds-icon>
              {{ generalAction.displayName }}
            </button>
          </ng-container>
          <ng-template #actionsDropdown>
            <clr-dropdown>
              <button
                type="button"
                class="btn btn-sm btn-secondary"
                clrDropdownTrigger
              >
                {{ generalAction.displayName }}
                <cds-icon shape="caret down"></cds-icon>
              </button>
              <clr-dropdown-menu clrPosition="bottom-left" *clrIfOpen>
                <button
                  [disabled]="
                    (generalSubAction.minRows !== 'any' &&
                      generalSubAction.minRows > realConfig.selected.length) ||
                    (generalSubAction.maxRows !== 'any' &&
                      generalSubAction.maxRows < realConfig.selected.length)
                  "
                  *ngFor="let generalSubAction of generalAction.subActions"
                  type="button"
                  (click)="generalSubAction.action(realConfig.selected)"
                  clrDropdownItem
                >
                  {{ generalSubAction.displayName }}
                </button>
              </clr-dropdown-menu>
            </clr-dropdown>
          </ng-template>
        </div>
      </clr-dg-action-bar>

      <!-- COLUMNS -->
      <!-- [clrDgSortBy]="pokemonComparator" custom comparator-->
      <!-- [clrDgSortOrder]="descSort" Preselected sort order-->
      <!-- [(clrFilterValue)]="myFilterValue" -->
      <!-- getColumDataType -->
      <clr-dg-column
        *ngFor="let column of realConfig.columns"
        [clrDgField]="column.allowFilter ? column.name : ''"
        [clrDgColType]="column.dataType == 'string' ? 'string' : 'number'"
      >
        <ng-container *clrDgHideableColumn="{ hidden: column.initialyHidden }">
          {{ column.displayName }}
        </ng-container>
      </clr-dg-column>

      <!-- ROWS -->
      <!-- [clrDgItem]="item"  item to select-->
      <!-- [clrDgSelectable]="!item.locked" prevent user interaction edit selection-->
      <clr-dg-row
        *clrDgItems="let item of data"
        [clrDgItem]="item"
        [clrDgSelectable]="item.locked === false"
      >
        <clr-dg-action-overflow *ngIf="realConfig.rowActions.length > 0">
          <button
            *ngFor="let rowAction of realConfig.rowActions"
            class="action-item"
            (click)="rowAction.action(item)"
          >
            {{ rowAction.displayName }}
          </button>
        </clr-dg-action-overflow>
        <clr-dg-cell *ngFor="let rowConfig of realConfig.rows">
          <ng-template #defaultCell>
            {{ item[rowConfig.attrb] }}
          </ng-template>
          <ng-template #customCell>
            <div
              [ngStyle]="{
                color: item[rowConfig.attrb] === 'AZUL' ? 'blue' : 'green'
              }"
            >
              {{ item[rowConfig.attrb] }}
            </div>
          </ng-template>
          <ng-container
            *ngTemplateOutlet="
              rowConfig.cellType == 'custom' ? customCell : defaultCell
            "
          >
          </ng-container>
        </clr-dg-cell>
        <!-- EXPANDED DETAIL -->
        <!-- The extension replace the row [clrDgReplace]="true" -->
        <ng-container
          ngProjectAs="clr-dg-row-detail"
          *ngIf="realConfig.rowExtendConfig.isActive"
        >
          <clr-dg-row-detail
            [clrDgReplace]="realConfig.rowExtendConfig.replaceRow"
            *clrIfExpanded
          >
            Lorem ipsum...
          </clr-dg-row-detail>
        </ng-container>
      </clr-dg-row>

      <!-- Detail inside select update user's rol -->

      <clr-dg-detail *clrIfDetail="let detail">
        <clr-dg-detail-header>{{ detail.email }}</clr-dg-detail-header>
        <clr-dg-detail-body>
          <form
            [formGroup]="this.formDetail"
            (ngSubmit)="propagare(formDetail.value, detail.id)"
            class="clr-form clr-form-vertical"
          >
            <h5>Full name: {{ detail.fullName }}</h5>
            <h5>Roles:</h5>
            <clr-select-container>
              <select formControlName="dataDetail" name="dataDetail" clrSelect>
                <option
                  value="{{ item.id }}"
                  *ngFor="
                    let item of this.realConfig.data.dataDetailUpdateWithSelect
                  "
                >
                  {{ item.name }}
                </option>
              </select>
              <clr-control-error> Debe seleccionar un rol</clr-control-error>
            </clr-select-container>
            <br />
            <button
              class="btn btn-primary"
              type="submit"
              [disabled]="!formDetail.valid"
            >
              Cambiar Rol
            </button>
          </form>
        </clr-dg-detail-body>
      </clr-dg-detail>

      <!-- DETAIL INSIDE TABLE -->
      <!-- <clr-dg-detail *clrIfDetail="let detail">
      <clr-dg-detail-header>{{ detail.name }}</clr-dg-detail-header>
      <clr-dg-detail-body> Soy el BODY </clr-dg-detail-body>
    </clr-dg-detail> -->

      <!-- FOOTER -->
      <!-- <clr-dg-footer>{{data.length}} users</clr-dg-footer> -->
      <!-- Inside the full datagrid declaration -->
      <!-- *ngIf = "realConfig.pagination.isActive" -->
      <clr-dg-footer>
        <clr-dg-pagination
          #pagination
          [clrDgPageSize]="realConfig.pagination.pageSize"
        >
          <clr-dg-page-size [clrPageSizeOptions]="realConfig.pagination.options"
            >Items per page</clr-dg-page-size
          >
          {{ pagination.firstItem + 1 }} - {{ pagination.lastItem + 1 }} of
          {{ pagination.totalItems }} items
        </clr-dg-pagination>
      </clr-dg-footer>
    </clr-datagrid>
  </ng-container>
</ng-template>
