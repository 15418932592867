import { RichTimestamp } from './general';
import { ASPModule } from './meta';

export enum FinanceRequestType {
  employee_period_payment = 'employee_period_payment',
  employee_bonus = 'employee_bonus',
  employee_single_payment = 'employee_single_payment',
  employee_project_hours_payment = 'employee_project_hours_payment',
  restock_order_payment = 'restock_order_payment',
  maintenance_payment = 'maintenance_payment',
  batch_storage_payment = 'batch_storage_payment',
  equipment_transfer_payment = 'equipment_transfer_payment',
}

export enum FinanceRequestStatus {
  waiting = 'waiting',
  approved = 'approved',
  rejected = 'rejected',
}

export enum FinanceRequestAction {
  approve = 'approve',
  reject = 'reject',
}

export interface FinanceRequestCreate {
  type: FinanceRequestType;
  organizationId: string;
  suborganizationId?: string | null;
  amount: number; // save with sign
  status: FinanceRequestStatus;
  module: ASPModule;
}

export interface FinanceRequest extends FinanceRequestCreate {
  id: string;
  createdAt: RichTimestamp;
  approvedAt?: RichTimestamp;
  rejectedAt?: RichTimestamp;
  comment?: string | null;
}
