import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AlertsService {

  constructor() { }
  currentAlert: BehaviorSubject<any> = new BehaviorSubject<any>({ 
    alertType: 'navLevel',
    show: false,
    alertCase: ''
  });

  public getAlertSubscribe() {
    return this.currentAlert
  }

  public setCurrentAlert(alert: any){
    this.currentAlert.next(alert)
  }
}
