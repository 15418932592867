<div class="confirmation-modal">
  <clr-modal [(clrModalOpen)]="showModal">
    <div class="modal-title">
      <h3 class="title">{{ title }}</h3>
      <h6 class="subtitle">{{ subtitle }}</h6>
    </div>
    <div class="modal-body">
      <ul>
        <li *ngFor="let rowName of rowsNames">{{ rowName }}</li>
      </ul>
    </div>
    <div class="modal-footer">
      <button
        *ngIf="buttonText != 'Close'"
        type="button"
        class="btn btn-outline"
        (click)="toggleConfirmationModal()"
      >
        Cancel
      </button>
      <button
        type="button"
        class="btn btn-primary"
        (click)="doButtonFunction()"
        [disabled]="rows.length === 0"
      >
        {{ buttonText }}
      </button>
    </div>
  </clr-modal>
</div>
