<div *ngIf="routes">
    <app-side-nav-item *ngFor="let path of routes" [path]="path"></app-side-nav-item>
</div>







