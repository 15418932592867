import { ProyectName } from '../models/email-tracking';
import { Translated } from '../models/general';
import { ASPModule } from '../models/meta';

export const ASP_MODULES_NAMES_MAP: { [key in ASPModule]: Translated } = {
  [ASPModule.hr]: {
    en: 'Human resources',
    es: 'Recursos Humanos',
  },
  [ASPModule.inventory]: {
    en: 'Inventory',
    es: 'Inventario',
  },
  [ASPModule.process]: {
    en: 'Process',
    es: 'Procesos',
  },
  [ASPModule.finance]: {
    en: 'Finance',
    es: 'Finanzas',
  },
};

export const PROJECT_NAME: ProyectName = 'asp';
