import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { AspTableComponent } from '../../components/asp-table/asp-table.component';
import { ClarityModule } from '@clr/angular';
import { AsyncablePipe } from '../../pipes/asyncable.pipe';
import { RouterModule } from '@angular/router';
import { AspTableRolesComponent } from 'src/app/components/asp-table-roles/asp-table-roles.component';
import { FilterPipe } from '../../pipes/filter.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AspDatagridComponent } from 'src/app/components/asp-datagrid/asp-datagrid.component';
import { AlertsComponent } from '../../components/alerts/alerts.component';
import { CthDataGridComponent } from '../../components/cth-data-grid/cth-data-grid.component';
import { AspDgFnPipe } from '../../pipes/asp-dg-fn.pipe';
import { AspConfirmationModalComponent } from 'src/app/components/asp-confirmation-modal/asp-confirmation-modal.component';
import { ConfirmationModalComponent } from '../hr/pop-ups/confirmation-modal/confirmation-modal.component';
import { PopUpAlertComponent } from '../hr/pop-ups/pop-up-alert/pop-up-alert.component';
import { SimpleConfirmationModalComponent } from '../hr/pop-ups/simple-confirmation-modal/simple-confirmation-modal.component';
import { HourAmountFormatPipe } from 'src/app/pipes/hour-amount-format.pipe';
import { FireTimestampToDatePipe } from '../../pipes/fire-timestamp-to-date.pipe';
import { CurrencyMaskPipe } from 'src/app/pipes/currency-mask.pipe';
import { CurrencyFormatterDirective } from 'src/app/directives/currency-formatter.directive';
@NgModule({
  declarations: [
    AspTableComponent,
    AspTableRolesComponent,
    AsyncablePipe,
    FilterPipe,
    AspDatagridComponent,
    AlertsComponent,
    CthDataGridComponent,
    AspDgFnPipe,
    AspConfirmationModalComponent,
    ConfirmationModalComponent,
    PopUpAlertComponent,
    SimpleConfirmationModalComponent,
    HourAmountFormatPipe,
    FireTimestampToDatePipe,
    CurrencyMaskPipe,
    CurrencyFormatterDirective,
  ],
  imports: [
    CommonModule,
    ClarityModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  exports: [
    AspTableComponent,
    AspTableRolesComponent,
    ClarityModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    AsyncablePipe,
    AspDatagridComponent,
    AlertsComponent,
    FormsModule,
    CommonModule,
    CthDataGridComponent,
    AspConfirmationModalComponent,
    ConfirmationModalComponent,
    PopUpAlertComponent,
    SimpleConfirmationModalComponent,
    HourAmountFormatPipe,
    FireTimestampToDatePipe,
    CurrencyMaskPipe,
    CurrencyFormatterDirective,
  ],
  providers: [CurrencyMaskPipe],
})
export class SharedModule {}
