import { DebtCreate, DebtFirestore, DebtType, DebtUnit } from './debt';
import { FirestoreFieldValue, RichTimestamp } from './general';
import { EmployeePeriodPaymentReportCreate } from './hr-payment';

export enum ShiftType {
  fullTime = 'fullTime',
  partTime = 'partTime',
  freelance = 'freelance',
}

export enum EmployeeRank {
  employee = 'employee',
  supervisor = 'supervisor',
  admin = 'admin',
  superadmin = 'superadmin',
}

export enum EmployeePaymentInterval {
  bimonthly = 'bimonthly',
  monthly = 'monthly',
  single = 'single',
}

export interface Employee {
  id: string; //UID
  contextId?: string;
  firstName: string;
  secondName?: string;
  lastName: string;
  secondLastName?: string;
  fullName: string;
  shiftType: ShiftType;
  rank: EmployeeRank;
  paymentInterval: EmployeePaymentInterval;
  email: string;
  companyId?: string;
  entityId?: string;
  dob?: RichTimestamp
  phoneNumbers?: string[];
  remuneration?: number;
  salary: number;
  code: string;
  firstDayDate?: RichTimestamp;
  jobTitleId: string;
  departmentsIds?: string[];
  shiftsIds?: string[];
  deleted: boolean;
  lastPayedAt: RichTimestamp;
  paymentRequestedUntil: RichTimestamp;
  singlePaymentRequestedAt: RichTimestamp;
  lastSinglePaymentAt: RichTimestamp;
  createdAt?: RichTimestamp;
  updatedAt?: RichTimestamp;
  deletedAt?: RichTimestamp;
  hasAuth: boolean;
  vacationDays: number;
  suborganizationId?: string;
}
//properties = ['id','email','contextId', 'firstName', 'secondName', 'lastName', 'secondLlastName', 'fullName', 'employeeId', 'phoneNumbers','deleted', 'createdAt', 'updatedAt', 'deletedAt']

//Declaration of EmployeeDepartmentsLogs Model
export interface EmployeeDepartmentsLogs {
  assigned: boolean;
  assignmentAt: FirestoreFieldValue
  departmentId: string;
  employeeId: string;
  id?: string;
  organizationId: string | null;
  unassignAt?: RichTimestamp | null;
}

//Declaration of EmployeeShiftsLog Model
export interface EmployeeShiftsLog {
  assigned: boolean;
  assignmentAt: RichTimestamp;
  employeeId: string;
  id?: string;
  organizationId: string | null;
  shiftId: string;
  unassignAt: RichTimestamp | null;
}

//Declaration of Employee InOut
export interface InOut {
  startTime: any;
  offTime: any;
  description?: string; //Description added by the supervisor
  nId?: string;
  employeeId: string;
  minutesWorked?: number | null;
  organizationId: string;
  shiftsIds?: Array<string>;
  isWorking: boolean;
  deleted: boolean;
  createdAt: Date;
  updatedAt: any;
  deletedAt: any;
}

export interface EmployeePaymentLogCreate {
  amount: number;
  paymentReportId: string;
}

export interface EmployeePaymentLog extends EmployeePeriodPaymentReportCreate {
  id: string;
  createdAt: RichTimestamp;
}

export interface SalaryChangesProgrammed {
  active: boolean;
  createdAt: RichTimestamp;
  deleted: boolean;
  employeeId: string;
  id?: string;
  newSalary: number;
  oldSalary: number;
  organizationId: string;
  salaryUpdateDate:
    any;
  deletedAt: RichTimestamp | null;
}

export interface SalaryChangeLogCreate {
  employeeId: string;
  newSalary: number;
  oldSalary: number;
  organizationId: string;
  suborganizationId?: string;
}

export interface SalaryChangeLog extends SalaryChangeLogCreate {
  createdAt: RichTimestamp;
}

export interface EmployeeDebtCreate extends DebtCreate {
  type: DebtType.debt;
}

export interface EmployeeDebt extends EmployeeDebtCreate, DebtFirestore {}